import React, { useState, useMemo, useEffect, useLayoutEffect, useRef } from 'react';
import { ChevronDownIcon, ChevronRightIcon } from '@radix-ui/react-icons';
import './Timeline.css';
import moment from 'moment';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import Modal from "../Modal/Modal";
import { ModalViewDetails } from "../Dashboard/ModalView";
import Dialog from "../Dialog/Dialog";
import { DeleteOutlined } from "../../assets/svg/DeleteOutlined";
import { CreateModal } from "../Modal/Modal";
import { CreateProjectForm } from "../CreateProject/CreateProject";
import { CreateTaskForm } from "../CreateTask/CreateTask";
import { TimelineBubbleTooltip } from './TimelineTooltip';
import { useMutationPatch } from "../../hooks/crud/mutation.patch";
import { API_END_POINTS } from "../../utils/api-endpoint";

export const viewOptions = [
  { label: 'Days', value: 'days', cellWidth: 20 },
  { label: 'Months', value: 'months', cellWidth: 5 }
];



const validateProjectData = (projects, orphanTasks = []) => {
  if (!projects) return [];
  
  let projectsWithTasks = projects.map(project => {
    // First, determine the project's end date
    const projectEndDate = project.endDate ? new Date(project.endDate) : null;
    const hasValidProjectEndDate = projectEndDate && !isNaN(projectEndDate.getTime());

    return {
      ...project,
      tasksList: project.tasksList
        .map(task => {
          // Try to use task's end date first
          const taskEndDate = task.endDate && !isNaN(new Date(task.endDate).getTime()) 
            ? task.endDate 
            : (hasValidProjectEndDate ? project.endDate : null);
          
          return {
            ...task,
            endDate: taskEndDate
          };
        })
        .filter(task => task.endDate) // Filter out tasks that still don't have a valid end date
        .sort((a, b) => {
          const endDateDiff = new Date(a.endDate) - new Date(b.endDate);
          if (endDateDiff !== 0) return endDateDiff;
          return a.title.localeCompare(b.title);
        })
    };
  })
  .filter(project => project.tasksList.length > 0 && !project.tasksList.every(task => task.completed));

  // Sort projects by start date, then end date, then title
  projectsWithTasks.sort((a, b) => {
    // Get start dates for projects
    const getProjectStartDate = (project) => {
      if (project.startDate) return new Date(project.startDate);
      const taskStartDates = project.tasksList
        .map(task => new Date(task.endDate))
        .filter(date => !isNaN(date.getTime()));
      return taskStartDates.length > 0 ? new Date(Math.min(...taskStartDates)) : new Date();
    };

    // Get end dates for projects
    const getProjectEndDate = (project) => {
      if (project.endDate) return new Date(project.endDate);
      const taskEndDates = project.tasksList
        .map(task => new Date(task.endDate))
        .filter(date => !isNaN(date.getTime()));
      return taskEndDates.length > 0 ? new Date(Math.max(...taskEndDates)) : new Date(9999, 11, 31);
    };

    const aStartDate = getProjectStartDate(a);
    const bStartDate = getProjectStartDate(b);
    const aEndDate = getProjectEndDate(a);
    const bEndDate = getProjectEndDate(b);
    
    // Sort by start date first
    const startDateDiff = aStartDate - bStartDate;
    if (startDateDiff !== 0) return startDateDiff;

    // If start dates are equal, sort by end date
    const endDateDiff = aEndDate - bEndDate;
    if (endDateDiff !== 0) return endDateDiff;
    
    // If end dates are also equal, sort by title
    return a.title.localeCompare(b.title);
  });

  // Handle orphan tasks
  const validOrphanTasks = orphanTasks.filter(task => {
    const hasValidDate = task.endDate && !isNaN(new Date(task.endDate).getTime());
    return hasValidDate && !task.completed;
  }).sort((a, b) => {
    const endDateDiff = new Date(a.endDate) - new Date(b.endDate);
    if (endDateDiff !== 0) return endDateDiff;
    return a.title.localeCompare(b.title);
  });

  // If we have valid orphan tasks, create a "No Project" container
  if (validOrphanTasks.length > 0) {
    const noProjectContainer = {
      _id: 'no-project',
      title: 'No Project',
      tasksList: validOrphanTasks
    };
    
    // Add the "No Project" container at the end of the projects array
    projectsWithTasks.push(noProjectContainer);
  }

  return projectsWithTasks;
};

// Add this constant for the drag type
const ItemTypes = {
  TASK: 'task'
};

// Helper function to generate task tooltip content
export const getTaskTooltipContent = (task) => (
  <div>
    <div className="font-semibold">{task.title}</div>
    <div>Due: {moment(task.endDate).format('MMM D, YYYY')}</div>
    {task.description && (
      <div className="text-sm mt-1">{task.description}</div>
    )}
  </div>
);

// Helper function to generate project tooltip content
export const getProjectTooltipContent = (project) => (
<div>
  <div className="font-semibold">{project.title}</div>
  <div>
    {project.startDate 
      ? `${moment(project.startDate).format('MMM D')} - ${moment(project.endDate).format('MMM D, YYYY')}`
      : `Due: ${moment(project.endDate).format('MMM D, YYYY')}`}
  </div>
  <div className="text-sm mt-1">
    {project.tasksList.length} task{project.tasksList.length !== 1 ? 's' : ''}
  </div>
</div>
); 

// Update getTaskPosition to only use endDate
const getTaskPosition = (taskEndDate, timelineStartDate, cellWidth) => {
  const end = new Date(taskEndDate);
  
  // Calculate days from timeline start
  const daysFromStart = Math.floor((end - timelineStartDate) / (1000 * 60 * 60 * 24));
  
  return {
    left: `${daysFromStart * cellWidth}px`,
    width: `${cellWidth}px` // Fixed width for point-in-time tasks
  };
};

// Update DraggableTask component
const DraggableTask = ({ task, timelineStartDate, cellWidth }) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: ItemTypes.TASK,
    item: { ...task },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  }));

  const position = getTaskPosition(task.endDate, timelineStartDate, cellWidth);

  return (
    <div 
      className="timeline-task-container" 
      style={{ ...position, position: 'absolute' }}
    >
      <TimelineBubbleTooltip content={getTaskTooltipContent(task)}>
        <div
          ref={drag}
          className={`timeline-task-bubble ${isDragging ? 'opacity-50' : ''}`}
          style={{ 
            width: position.width, 
            cursor: isDragging ? 'grabbing' : 'grab' 
          }}
        />
      </TimelineBubbleTooltip>
    </div>
  );
};

// Function to calculate project position
const getProjectPosition = (projectStartDate, projectEndDate, timelineStartDate, cellWidth, tasksList) => {
  let start;

  if (projectStartDate) {
    start = new Date(projectStartDate);
  } else {
    // Get all valid task end dates
    const taskEndDates = tasksList
      .filter(task => task.endDate && !isNaN(new Date(task.endDate).getTime()))
      .map(task => new Date(task.endDate));
    
    // If there are valid task end dates, use the earliest one
    // Otherwise use today's date
    start = taskEndDates.length > 0 
      ? new Date(Math.min(...taskEndDates))
      : new Date();
  }

  let end;
  if (projectEndDate) {
    end = new Date(projectEndDate);
  } else {
    // Get all valid task end dates
    const taskEndDates = tasksList
      .filter(task => task.endDate && !isNaN(new Date(task.endDate).getTime()))
      .map(task => new Date(task.endDate));
    
    // If there are valid task end dates, use the latest one
    // Otherwise use far future date (12/31/9999)
    end = taskEndDates.length > 0 
      ? new Date(Math.max(...taskEndDates))
      : new Date(9999, 11, 31); // Month is 0-based, so 11 is December

    // Extend the end date by one day to span the width of the last task's bubble
    end.setDate(end.getDate() + 1);
  }

  // Calculate days from timeline start
  const daysFromStart = Math.floor((start - timelineStartDate) / (1000 * 60 * 60 * 24));
  const duration = Math.max(1, Math.ceil((end - start) / (1000 * 60 * 60 * 24))); // add 1 to ensure spanning end of task bubble
  
  return {
    left: `${daysFromStart * cellWidth}px`,
    width: `${duration * cellWidth}px`
  };
};

// Update the getWeekNumber function to handle week 53
const getWeekNumber = (date) => {
  const d = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
  const dayNum = d.getUTCDay() || 7;
  d.setUTCDate(d.getUTCDate() + 4 - dayNum);
  const yearStart = new Date(Date.UTC(d.getUTCFullYear(),0,1));
  const weekNo = Math.ceil((((d - yearStart) / 86400000) + 1)/7);
  
  // Handle week 53 for the last week of the year
  if (weekNo === 1 && date.getMonth() === 11) {
    return 53;
  }
  return weekNo;
};

const TimelineView = ({ 
  projects, 
  orphanTasks, 
  selectedView,
  selectedTab,
  tasksData,
  onTaskUpdate,
  patchTask,
  notify,
  deleteProject,
  handleDeleteConfirmation,
  handleMarkAsComplete,
  handleDeleteProject,
  handleDeleteTask,
  selectedData,
  setSelectedData,
  setModalTitle,
  isModalOpen,
  setIsModalOpen,
  refetchProjectData,
  modalContentType,
  setModalContentType,
  setJustUpdated,
  data, // for edit task form
  setData, // for edit task form
  refetchTaskData,
}) => {
  const [collapsedProjects, setCollapsedProjects] = useState(new Set());
  const [confirmationDialog, setConfirmationDialog] = useState(false);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [refreshKey, setRefreshKey] = useState(0);
  const [isRefetching, setIsRefetching] = useState(false);
  const [pendingSort, setPendingSort] = useState(false);
  const sortTimeoutRef = useRef(null);

  const { mutate: patchProjectList } = useMutationPatch(API_END_POINTS.project);

  const cellWidth = selectedView.cellWidth;

  // Add a key to force remount when tab changes
  const timelineKey = useMemo(() => `${selectedTab}-timeline`, [selectedTab]);

  // Reset local state when tab changes
  useEffect(() => {
    setLocalTimelineData(null);  // Clear local timeline data
    setCollapsedProjects(new Set());  // Reset collapsed projects
    setRefreshKey(0);  // Reset refresh key
    setIsRefetching(false);  // Reset refetch state
    setPendingSort(false);  // Reset pending sort
    
    // Clear any pending timeouts
    if (sortTimeoutRef.current) {
      clearTimeout(sortTimeoutRef.current);
      sortTimeoutRef.current = null;
    }
  }, [selectedTab]);

  // Add cleanup for the timeout
  useEffect(() => {
    return () => {
      if (sortTimeoutRef.current) {
        clearTimeout(sortTimeoutRef.current);
      }
    };
  }, []);

  // Replace useEffect with useLayoutEffect for data refresh
  useLayoutEffect(() => {
    if (!isRefetching && !pendingSort) {
      setRefreshKey(prev => prev + 1);
    }
  }, [projects, orphanTasks, tasksData, isRefetching, pendingSort]);

  useLayoutEffect(() => {
    if (!isModalOpen && !isCreateModalOpen) {
      handleDataRefresh();
    }
  }, [isModalOpen, isCreateModalOpen]);

  const handleDataRefresh = async () => {
    if (pendingSort) return; // Don't refresh if sort is pending
    
    setIsRefetching(true);
    try {
      const [projectsResult, tasksResult] = await Promise.all([
        refetchProjectData(),
        refetchTaskData()
      ]);
      
      if (projectsResult.data || tasksResult.data) {
        setRefreshKey(prev => prev + 1);
      }
    } catch (error) {
      console.error('Error refetching data:', error);
    } finally {
      setIsRefetching(false);
    }
  };

  const validatedData = useMemo(() => {
    if (selectedTab === "Tasks" && tasksData) {
      // For Tasks view, create a single flat list
      return [{
        _id: 'all-tasks',
        title: 'Tasks',
        tasksList: tasksData
          .filter(task => {
            const endDate = task.endDate && !isNaN(new Date(task.endDate).getTime());
            return endDate && !task.completed;
          })
          .sort((a, b) => {
            const endDateDiff = new Date(a.endDate) - new Date(b.endDate);
            if (endDateDiff !== 0) return endDateDiff;
            return a.title.localeCompare(b.title);
          })
      }];
    }
    
    // For Projects view, use existing validation
    return validateProjectData(projects, orphanTasks);
  }, [selectedTab, tasksData, projects, orphanTasks, refreshKey, isRefetching]);

  // Add new state for local timeline data
  const [localTimelineData, setLocalTimelineData] = useState(null);

  // Initialize local state when validated data changes
  useEffect(() => {
    if (!localTimelineData) {
      setLocalTimelineData(validatedData);
    }
  }, [validatedData]);

  // Update the useMemo for timelineItems to use localTimelineData
  const timelineItems = useMemo(() => {
    const sourceData = localTimelineData || validatedData;
    
    if (selectedTab === "Tasks") {
      if (!sourceData?.[0]?.tasksList) {
        return [];
      }
      return sourceData[0].tasksList.map(task => ({
        type: 'task',
        ...task
      }));
    }

    return sourceData.flatMap(project => {
      const projectItem = { type: 'project', ...project };
      const taskItems = project.tasksList.map(task => ({
        type: 'task',
        ...task,
        projectTitle: project.title,
        hidden: collapsedProjects.has(project._id)
      }));
      return [projectItem, ...taskItems];
    }).filter(item => !item.hidden);
  }, [selectedTab, localTimelineData, validatedData, collapsedProjects]);

  const allTasks = useMemo(() => validatedData.flatMap(project => project.tasksList), 
    [validatedData, refreshKey]);

  const dates = allTasks
    .map(task => {
      try {
        const date = new Date(task.endDate);
        return isNaN(date.getTime()) ? null : date;
      } catch (e) {
        return null;
      }
    })
    .filter(date => date !== null);

  if (dates.length === 0) {
    return <div className="white">No valid timeline dates available</div>;
  }

  const minDate = new Date(Math.min(...dates));
  const maxDate = new Date(Math.max(...dates));

  // Round to first/last day of month for cleaner display
  const timelineStartDate = new Date(minDate.getFullYear(), minDate.getMonth(), 1);
  const endDate = new Date(maxDate.getFullYear(), maxDate.getMonth() + 1, 0);
  
  // Add 1 to include the last day in the count
  const totalDays = Math.ceil((endDate - timelineStartDate) / (1000 * 60 * 60 * 24)) + 1;
  
  // Generate array of months between start and end date
  const monthWidths = [];
  let currentDate = new Date(timelineStartDate);
  
  while (currentDate <= endDate) {
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth();
    const lastDay = new Date(year, month + 1, 0).getDate();
    
    monthWidths.push({
      month: currentDate.toLocaleString('default', { month: 'long' }),
      days: lastDay
    });
    
    currentDate = new Date(year, month + 1, 1);
  }

  // Calculate cumulative days for month separators
  let cumulativeDays = 0;
  const monthPositions = monthWidths.map(monthData => {
    const position = cumulativeDays * cellWidth;
    cumulativeDays += monthData.days;
    return {
      ...monthData,
      position,
      width: monthData.days * cellWidth
    };
  });

  // Separate handlers for collapse and project details
  const handleCollapseClick = (e, projectId) => {
    e.stopPropagation(); // Prevent click from bubbling to project header
    setCollapsedProjects(prev => {
      const newSet = new Set(prev);
      if (newSet.has(projectId)) {
        newSet.delete(projectId);
      } else {
        newSet.add(projectId);
      }
      return newSet;
    });
  };

  const handleProjectClick = (project) => {
    if (project._id === 'no-project') return;
    setSelectedData(project);
    setModalTitle("Project Details");
    setIsModalOpen(true);
    setModalContentType("Project");
  };

  const handleTaskClick = (task) => {
    setSelectedData(task);
    setModalTitle("Task Details");
    setIsModalOpen(true);
    setModalContentType("Task");
  };

  // Calculate total width of timeline
  const totalTimelineWidth = cumulativeDays * cellWidth; // Total width in pixels

  // Rename the local function to handleLocalTaskUpdate
  const handleLocalTaskUpdate = (draggedItem, newDates) => {
    // Immediately update local state
    setLocalTimelineData(prevData => {
      const updatedData = prevData.map(project => {
        if (project._id === 'all-tasks') {
          // Update task in Tasks view
          return {
            ...project,
            tasksList: project.tasksList.map(task => 
              task._id === draggedItem._id 
                ? { ...task, endDate: newDates.endDate }
                : task
            )
          };
        } else {
          // Update task in Projects view
          return {
            ...project,
            tasksList: project.tasksList.map(task =>
              task._id === draggedItem._id 
                ? { ...task, endDate: newDates.endDate }
                : task
            )
          };
        }
      });

      // Sort the data if not delaying sort
      if (!newDates.delayRefetch) {
        return validateProjectData(updatedData);
      }
      return updatedData;
    });

    // Call the original update handler for backend sync
    onTaskUpdate(draggedItem, newDates);
  };

  // Update TimelineRow to use handleLocalTaskUpdate
  const TimelineRow = ({ item, totalWidth }) => {
    const [{ isOver }, drop] = useDrop(() => ({
      accept: ItemTypes.TASK,
      drop: (draggedItem, monitor) => {
        const dropPos = monitor.getClientOffset().x;
        const gridElement = document.querySelector('.timeline-grid');
        const gridRect = gridElement.getBoundingClientRect();
        
        const scrollLeft = gridElement.scrollLeft;
        const relativeX = dropPos - gridRect.left + scrollLeft;
        
        const daysOffset = Math.floor(relativeX / cellWidth);
        const newEndDate = new Date(timelineStartDate);
        newEndDate.setDate(newEndDate.getDate() + daysOffset);

        // Update with new date and delay flag
        handleLocalTaskUpdate(draggedItem, {
          endDate: newEndDate.toISOString(),
          delayRefetch: true
        });
      }
    }), []);

    return (
      <div
        ref={drop}
        className={`timeline-row ${item.type === 'project' ? 'timeline-row-project' : 'timeline-row-task'}`}
        style={{ width: totalWidth }}
      >
        {item.type === 'task' && (
          <DraggableTask
            task={item}
            timelineStartDate={timelineStartDate}
            cellWidth={cellWidth}
          />
        )}
        {item.type === 'project' && item._id !== 'no-project' && (
          <div 
            className="timeline-task-container"
            style={{ 
              ...getProjectPosition(item.startDate, item.endDate, timelineStartDate, cellWidth, item.tasksList),
              position: 'absolute'
            }}
          >
            <TimelineBubbleTooltip content={getProjectTooltipContent(item)}>
              <div className="timeline-task-bubble" style={{ width: '100%' }} />
            </TimelineBubbleTooltip>
          </div>
        )}
      </div>
    );
  };

  if (!validatedData.length) {
    return <div className="white">No projects with valid timeline data available</div>;
  }

  // Update handleEdit to match Dashboard behavior
  const handleEdit = () => {
    setIsModalOpen(false);
    setIsCreateModalOpen(true);
    setModalTitle(`Edit ${modalContentType}`);
    setIsEdit(true);
  };

  const onDragEnd = (result) => {
    const { destination, source, draggableId } = result;

    // Return if dropped outside droppable area or at same position
    if (!destination || 
        (destination.droppableId === source.droppableId && 
         destination.index === source.index)) {
      return;
    }

    // Handle project tasks reordering
    if (source.droppableId === 'projectTasks') {
      const projectId = selectedData?._id;
      if (!projectId) return;

      // Find the project and its tasks
      const project = validatedData.find(p => p._id === projectId);
      if (!project) return;

      // Create new sorted array of tasks
      const newTasksList = Array.from(project.tasksList)
        .sort((a, b) => (a.projectDisplayIndex || 0) - (b.projectDisplayIndex || 0));
      
      // Reorder the tasks
      const [removed] = newTasksList.splice(source.index, 1);
      newTasksList.splice(destination.index, 0, removed);

      // Create update payload with new task order
      const projectUpdate = {
        projects: validatedData.map(p => {
          if (p._id === selectedData._id) {
            return {
              ...p,
              tasksList: newTasksList.map((task, index) => ({
                ...task,
                projectDisplayIndex: index
              }))
            };
          }
          return p;
        })
      };

      // Update backend
      patchProjectList(projectUpdate, {
        onSuccess: () => {
          notify("Task order updated successfully", "success");
          refetchProjectData();
        },
        onError: (err) => {
          notify(err?.error?.message || "Failed to update task order", "error");
        }
      });
    }
  };

  // Update the modal close handler to wait for both refetches
  const handleModalClose = () => {
    setIsModalOpen(false);
    handleDataRefresh();
  };

  // Update CreateModal close handler similarly
  const handleCreateModalClose = () => {
    setIsCreateModalOpen(false);
    handleDataRefresh();
  };

  // Add function to generate year positions
  const getYearPositions = (monthPositions) => {
    const yearPositions = [];
    let currentYear = null;
    let yearStartPosition = 0;
    let yearWidth = 0;

    monthPositions.forEach((monthData, index) => {
      const date = new Date(timelineStartDate);
      date.setMonth(date.getMonth() + index);
      const year = date.getFullYear();

      if (currentYear !== year) {
        if (currentYear !== null) {
          yearPositions.push({
            year: currentYear,
            position: yearStartPosition,
            width: yearWidth
          });
        }
        currentYear = year;
        yearStartPosition = monthData.position;
        yearWidth = monthData.width;
      } else {
        yearWidth += monthData.width;
      }
    });

    // Add the last year
    if (currentYear !== null) {
      yearPositions.push({
        year: currentYear,
        position: yearStartPosition,
        width: yearWidth
      });
    }

    return yearPositions;
  };

  return (
    <DndProvider backend={HTML5Backend}>
      {/* Add key to force remount */}
      <div className="" key={timelineKey}>
        <div className="flex justify-between items-center mb-4">
          <h2 className="white ml-4">{selectedTab} Timeline</h2>
        </div>
        <div className="timeline-grid-container">
          {/* Row Headers */}
          <div className="timeline-row-headers">
            <div className="timeline-header-spacer" />
            {timelineItems.map((item) => (
              <div 
                key={`${item.type}-${item._id}`} 
                className={`timeline-row-header ${item.type === 'project' ? 'timeline-row-header-project' : 'timeline-row-header-task'}`}
              >
                <div className="timeline-row-header-content">
                  {item.type === 'project' ? (
                    <div className="timeline-project-header">
                      <span 
                        className="timeline-collapse-icon"
                        onClick={(e) => handleCollapseClick(e, item._id)}
                      >
                        {collapsedProjects.has(item._id) ? 
                          <ChevronRightIcon className="white" /> : 
                          <ChevronDownIcon className="white" />
                        }
                      </span>
                      <div 
                        className="cursor-pointer"
                        onClick={() => handleProjectClick(item)}
                      >
                        <h4 className="white">{item.title}</h4>
                      </div>
                    </div>
                  ) : (
                    <p 
                      className="white timeline-task-title cursor-pointer" 
                      onClick={() => handleTaskClick(item)}
                    >
                      {item.title}
                    </p>
                  )}
                </div>
              </div>
            ))}
          </div>

          {/* Timeline Grid */}
          <div className="timeline-grid">
            {/* Headers section */}
            <div className={`timeline-headers ${cellWidth >= 20 ? 'day-view' : ''}`} style={{ width: totalTimelineWidth }}>
              {/* Year Headers - only show in months view */}
              {cellWidth < 20 && (
                <div className="timeline-year-headers">
                  {getYearPositions(monthPositions).map((yearData) => (
                    <div
                      key={`year-${yearData.year}`}
                      className="timeline-year-header"
                      style={{
                        left: `${yearData.position}px`,
                        width: `${yearData.width}px`
                      }}
                    >
                      {yearData.year}
                    </div>
                  ))}
                </div>
              )}

              {/* Month Headers */}
              <div className="timeline-month-headers">
                {monthPositions.map((monthData, index) => (
                  <div
                    key={monthData.month}
                    className="timeline-month-header"
                    style={{
                      width: `${monthData.width}px`,
                      position: 'absolute',
                      left: `${monthData.position}px`,
                      borderLeft: cellWidth >= 20 && index > 0 ? 'none' : undefined
                    }}
                  >
                    {monthData.month}
                    {index > 0 && <div className="timeline-month-separator" />}
                  </div>
                ))}
              </div>

              {/* Week Headers - only show in day view */}
              {cellWidth >= 20 && (
                <div className="timeline-week-headers" style={{ width: totalTimelineWidth }}>
                  {Array.from({ length: totalDays }).map((_, index) => {
                    const currentDate = new Date(timelineStartDate);
                    currentDate.setDate(currentDate.getDate() + index);
                    
                    const weekNum = getWeekNumber(currentDate);
                    const dayOfWeek = currentDate.getDay();
                    const isStartOfWeek = dayOfWeek === 1;
                    const isFirstDayOfYear = currentDate.getMonth() === 0 && currentDate.getDate() === 1;
                    const isLastMondayOfYear = (() => {
                      if (currentDate.getMonth() === 11 && dayOfWeek === 1) {
                        const nextMonday = new Date(currentDate);
                        nextMonday.setDate(currentDate.getDate() + 7);
                        return nextMonday.getFullYear() > currentDate.getFullYear();
                      }
                      return false;
                    })();
                    
                    if (isStartOfWeek || isFirstDayOfYear || isLastMondayOfYear) {
                      let daysUntilEndOfWeek;
                      
                      if (isLastMondayOfYear) {
                        const lastDayOfYear = new Date(currentDate.getFullYear(), 11, 31);
                        daysUntilEndOfWeek = Math.floor((lastDayOfYear - currentDate) / (1000 * 60 * 60 * 24)) + 1;
                      } else if (isFirstDayOfYear) {
                        daysUntilEndOfWeek = 7 - dayOfWeek + 1;
                      } else {
                        const daysLeft = totalDays - index;
                        daysUntilEndOfWeek = Math.min(7, daysLeft);
                      }
                      
                      // Only render if the week span is at least 40px wide
                      const weekWidth = daysUntilEndOfWeek * cellWidth;
                      if (weekWidth < 40) return null;
                      
                      return (
                        <div
                          key={`week-${index}`}
                          className={`timeline-week-header ${isFirstDayOfYear ? 'timeline-week-header-month-start' : ''}`}
                          style={{
                            position: 'absolute',
                            left: `${index * cellWidth}px`,
                            width: `${weekWidth}px`,
                            textAlign: 'center',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                          }}
                        >
                          {`W${weekNum}`}
                        </div>
                      );
                    }
                    return null;
                  })}
                </div>
              )}

              {/* Day Headers - only show in day view */}
              {cellWidth >= 20 && (
                <div className="timeline-day-headers" style={{ width: totalTimelineWidth }}>
                  {Array.from({ length: totalDays }).map((_, index) => {
                    const currentDate = new Date(timelineStartDate);
                    currentDate.setDate(currentDate.getDate() + index);
                    const dateNumber = currentDate.getDate();
                    const isFirstOfMonth = dateNumber === 1;
                    
                    return (
                      <div
                        key={`day-${index}`}
                        className={`timeline-day-header ${isFirstOfMonth ? 'timeline-day-header-month-start' : ''}`}
                        style={{
                          position: 'absolute',
                          left: `${index * cellWidth}px`,
                          width: `${cellWidth}px`
                        }}
                      >
                        {dateNumber}
                      </div>
                    );
                  })}
                </div>
              )}
            </div>

            {/* Month Columns */}
            {monthPositions.map((monthData, index) => (
              <div
                key={`column-${monthData.month}`}
                className="timeline-month-column"
                style={{
                  left: `${monthData.position}px`,
                  width: `${monthData.width}px`
                }}
              />
            ))}

            {/* Grid Content */}
            <div className="timeline-rows" style={{ width: totalTimelineWidth }}>
              {/* Vertical day lines */}
              {Array.from({ length: totalDays }).map((_, index) => {
                const currentDate = new Date(timelineStartDate);
                currentDate.setDate(currentDate.getDate() + index);
                const isFirstOfMonth = currentDate.getDate() === 1;

                // Only render if it's a month separator or if cellWidth > 5
                if (isFirstOfMonth || cellWidth > 5) {
                  return (
                    <div
                      key={index}
                      className={`timeline-grid-line ${isFirstOfMonth ? 'timeline-grid-line-month' : ''}`}
                      style={{
                        left: `${index * cellWidth}px`,
                        height: `${Math.max(timelineItems.length * 40, 800)}px` // Ensure minimum height
                      }}
                    />
                  );
                }
                return null;
              })}

              {/* Update timeline rows to use new TimelineRow component */}
              {timelineItems.map((item) => (
                <TimelineRow
                  key={`${item.type}-${item._id}`}
                  item={item}
                  totalWidth={totalTimelineWidth}
                />
              ))}
            </div>
          </div>
        </div>

        {/* Update Modal component to use new handleEdit */}
        <Modal
          isOpen={isModalOpen}
          onClose={handleModalClose}
          title={modalContentType + " Details"}
          handleMark={handleMarkAsComplete}
          completed={selectedData?.completed}
          handleDeleteTask={modalContentType === "Project" ? handleDeleteProject : handleDeleteTask}
          isEditButton={true}
          onEditClick={handleEdit}
          completionStatus={true}
        >
          <ModalViewDetails
            selectedEvent={selectedData}
            setSelectedEvent={setSelectedData}
            contentType={modalContentType}
            completed={validatedData?.filter((project) =>
              project.tasksList?.every((task) => task.completed)
            )}
            patchTask={patchTask}
            // handleTaskClick={handleViewTask}
            data={data}
            setData={setData}
            onDragEnd={onDragEnd}
          />
        </Modal>

        {/* Add CreateModal for editing */}
        <CreateModal
          isOpen={isCreateModalOpen}
          // className={"card-app-background align-center"}
          onClose={handleCreateModalClose}
          title={`Edit ${modalContentType}`}
          className={"w-2/5"}
        >
          {modalContentType === "Project" ? (
            <CreateProjectForm
              setModal={setIsCreateModalOpen}
              refetch={refetchProjectData}
              isEdit={isEdit}
              selectedData={selectedData}
            />
          ) : (
            <CreateTaskForm
              setModal={setIsCreateModalOpen}
              selectedData={selectedData}
              isEdit={isEdit}
              refetch={refetchProjectData}
              setJustUpdated={setJustUpdated}
              projectData={projects}
              setData={setData}
              dataList={data}
            />
          )}
        </CreateModal>

        {/* Add Dialog component for delete confirmation */}
        <Dialog
          show={confirmationDialog}
          action1ButtonLabel={"Cancel"}
          action2ButtonLabel={"Delete"}
          handelAction1={() => setConfirmationDialog(false)}
          handelAction2={handleDeleteConfirmation}
          isLoading={isButtonLoading}
        >
          <div className="flex-col justify-center align-middle">
            <div
              style={{
                background: "rgba(37, 31, 52, 1)",
                width: 100,
                height: 100,
                borderRadius: 100,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                position: "absolute",
                top: 20,
                left: "40%",
              }}
            >
              <DeleteOutlined size={72} />
            </div>
            <div className="pt-28">
              <h3 className="white text-align-center">
                You are about to delete a {modalContentType?.toLowerCase()}
              </h3>
              <h5 className="white text-align-center">
                This will delete your {modalContentType?.toLowerCase()} permanently.
              </h5>
            </div>
          </div>
        </Dialog>
      </div>
    </DndProvider>
  );
};

export default TimelineView;