import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { CalendarIcon } from "@radix-ui/react-icons";
import { TimePicker } from "antd";
import React, { useState, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import Button from "../Button";
import { DatePickerInputComponent } from "../Input/CustomInputComponent";
import FormSelect from "../Select/FormSelect";
import moment from "moment";

const recurringOptions = [
  { label: "Does not repeat", value: "none" },
  { label: "Daily", value: "daily" },
  { label: "Weekly", value: "weekly" },
  { label: "Every weekday (Monday to Friday)", value: "weekday" },
  { label: "Weekly on Monday", value: "monday" },
  { label: "Weekly on Tuesday", value: "tuesday" },
  { label: "Weekly on Wednesday", value: "wednesday" },
  { label: "Weekly on Thursday", value: "thursday" },
  { label: "Weekly on Friday", value: "friday" },
  { label: "Weekly on Saturday", value: "saturday" },
  { label: "Weekly on Sunday", value: "sunday" },
  { label: "Monthly", value: "monthly" },
  { label: "Annually", value: "yearly" },
  { label: "Every two weeks", value: "biweekly" },
];

export const CreateEventForm = ({
  onSubmit,
  control,
  errors,
  register,
  setIsCreateModalOpen,
  defaultValues,
  isEdit,
  isLoading,
  setIsButtonLoading,
}) => {
  const methods = useForm({
    defaultValues: isEdit
      ? {
          title: defaultValues?.title || "",
          eventDetails: defaultValues?.eventDetails || "",
          location: defaultValues?.location || "",
          startDate: defaultValues?.startDateTime || null,
          endDate: defaultValues?.endDateTime || null,
          time: defaultValues?.time || [],
          eventType: defaultValues?.eventType || "Meeting",
          collaboration: defaultValues?.collaboration || "Public",
          tags: defaultValues?.tags || [],
        }
      : {},
  });

  useEffect(() => {
    if (isEdit && defaultValues) {
      methods.reset({
        title: defaultValues?.title || "",
        eventDetails: defaultValues?.eventDetails || "",
        location: defaultValues?.location || "",
        startDate: defaultValues?.startDateTime || null,
        endDate: defaultValues?.endDateTime || null,
        time: [
          defaultValues?.startDateTime
            ? new Date(`2000-01-01T${defaultValues.startTime}`)
            : null,
          defaultValues?.endDateTime
            ? new Date(`2000-01-01T${defaultValues.endTime}`)
            : null,
        ].filter(Boolean), // Remove null values
        eventType: defaultValues?.eventType || "Meeting",
        collaboration: defaultValues?.collaboration || "Public",
        tags: defaultValues?.tags || [],
      });
    }
  }, [isEdit, defaultValues, methods]);

  const handleSubmit = methods.handleSubmit((data) => {
    onSubmit(data);
  });

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div className="max-h-[500px] overflow-y-auto">
        <form className="p-24 w-full" onSubmit={handleSubmit}>
          <div className="mb-4">
            <span className="text-gray-300 text-sm mb-2 block">Title</span>
            <Controller
              name="title"
              control={methods.control}
              defaultValue=""
              render={({ field }) => (
                <input
                  className="w-full p-3 quick-add-task-container create-project-input"
                  style={{ background: "var(--App-Background)" }}
                  placeholder="Event Title"
                  required
                  {...field}
                />
              )}
            />
          </div>

          <div>
            <span className="text-gray-300 text-sm mb-2 block">
              Description
            </span>
            <Controller
              name="eventDetails"
              control={methods.control}
              defaultValue=""
              render={({ field }) => (
                <textarea
                  className="w-full quick-add-task-container create-project-input"
                  style={{
                    background: "var(--App-Background)",
                    minHeight: "100px",
                    maxHeight: "200px",
                    padding: "12px",
                  }}
                  placeholder="Event Description"
                  {...field}
                />
              )}
            />
          </div>

          <div className="flex justify-between gap-4 mb-4">
            <div className="w-1/2">
              <Controller
                name="startDate"
                control={methods.control}
                render={({ field }) => (
                  <div>
                    <DatePickerInputComponent
                      selected={field.value}
                      onChange={(date) => field.onChange(date)}
                      icon={<CalendarIcon color="#fff" />}
                      placeholderText="Start Date"
                    />
                  </div>
                )}
              />
            </div>
            <div className="w-1/2">
              <Controller
                name="endDate"
                control={methods.control}
                render={({ field }) => (
                  <div>
                    <DatePickerInputComponent
                      selected={field.value}
                      onChange={(date) => field.onChange(date)}
                      icon={<CalendarIcon color="#fff" />}
                      placeholderText="End Date"
                    />
                  </div>
                )}
              />
            </div>
          </div>

          <div className="mb-4">
            <Controller
              name="time"
              control={methods.control}
              defaultValue={
                isEdit
                  ? [
                      moment(defaultValues?.startTime, "HH:mm"),
                      moment(defaultValues?.endTime, "HH:mm"),
                    ]
                  : []
              }
              render={({ field }) => (
                <TimePicker.RangePicker
                  className="w-full quick-add-task-container create-project-input"
                  onChange={(times) => field.onChange(times)}
                  value={[
                    field.value?.[0] ? moment(field.value[0], "HH:mm") : null,
                    field.value?.[1] ? moment(field.value[1], "HH:mm") : null,
                  ]}
                  format="HH:mm"
                  style={{
                    background: "var(--App-Background)",
                  }}
                />
              )}
            />
          </div>

          <div className="flex items-center gap-4 mb-4">
            <div className="flex items-center">
              <Controller
                name="isRecurring"
                control={methods.control}
                defaultValue={false}
                render={({ field }) => (
                  <label className="flex items-center cursor-pointer">
                    <input
                      type="checkbox"
                      className="mr-2"
                      checked={field.value}
                      onChange={(e) => {
                        field.onChange(e.target.checked);
                        if (!e.target.checked) {
                          methods.setValue("recurringFrequency", null);
                        }
                      }}
                    />
                    <span className="text-white">Recurring Event</span>
                  </label>
                )}
              />
            </div>

            <FormSelect
              className="flex-1"
              control={methods.control}
              label="recurringFrequency"
              id="recurringFrequency"
              options={recurringOptions}
              onChange={(e) => {
                methods.setValue("recurringFrequency", e?.value);
              }}
              value={methods.getValues("recurringFrequency")}
              name="recurringFrequency"
              placeholder="Select frequency"
              defaultValue={recurringOptions[0]}
            />
          </div>

          <div className="mb-4">
            <span className="text-gray-300 text-sm mb-2 block">Location</span>
            <Controller
              name="location"
              control={methods.control}
              defaultValue=""
              render={({ field }) => (
                <input
                  className="w-full p-3 quick-add-task-container create-project-input"
                  style={{ background: "var(--App-Background)" }}
                  placeholder="Location"
                  {...field}
                />
              )}
            />
          </div>

          <div className="flex justify-end">
            <Button
              type="button"
              variety="outlined"
              onClick={() => setIsCreateModalOpen(false)}
              className="mr-2"
            >
              Cancel
            </Button>
            <Button
              isLoading={isLoading}
              type="submit"
              variety="filled"
              className="w-full mt-4"
            >
              {isEdit ? "Update Event" : "Create Event"}
            </Button>
          </div>
        </form>
      </div>
    </LocalizationProvider>
  );
};
