import { useMutation } from "react-query";
import { baseRepository } from "../../repositories/base";

export const useMutationPatch = (url) => {
  const mutationRes = useMutation((variables) =>
    baseRepository.patch(url, variables).then((res) => res.data)
  );

  const mutate = (variables, options) => {
    mutationRes.mutate(variables, { ...options });
  };

  const invalidateQueries = () => {};

  const returnItem = {
    data: mutationRes.data,
    isLoading: mutationRes.isLoading,
    invalidateQueries,
    mutate,
  };

  return returnItem;
};

export const useMutationPatchEvent = (baseUrl) => {
  const mutationRes = useMutation((data) => {
    const { variables, id } = data;
    // Construct the full URL by combining baseUrl and id if id is provided
    const fullUrl = id ? `${baseUrl}/${id}` : baseUrl;
    return baseRepository.patch(fullUrl, variables).then((res) => res.data);
  });

  const mutate = (variables, id, options) => {
    mutationRes.mutate({ variables, id }, { ...options });
  };

  const invalidateQueries = () => {};

  const returnItem = {
    data: mutationRes.data,
    isLoading: mutationRes.isLoading,
    invalidateQueries,
    mutate,
  };

  return returnItem;
};
