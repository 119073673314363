import React, { useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import "react-select-search/style.css";
import { useDataQuery } from "../../hooks/crud/data.query";
import { useMutationCreate } from "../../hooks/crud/mutation.create";
import { API_END_POINTS } from "../../utils/api-endpoint";
import Button from "../Button";
import FormSelect from "../Select/FormSelect";
import { notify } from "../Toast/ToastComponent";
import { TrashIcon } from "../../assets/svg/TrashIcon";
import { useMutationPatch } from "../../hooks/crud/mutation.patch";
import { EditOutlined } from "src/assets/svg/EditOutlined";
import { set } from "lodash";
import {
  ChevronDownIcon,
  ChevronUpIcon,
  ChevronRightIcon,
} from "@radix-ui/react-icons";
import {
  CustomProjectIcon,
  SuggestedProjectIcon,
} from "src/assets/svg/CreateProjectIconGroup";
import { FormProject } from "./CreateProjectForm";

export const CreateProjectForm = ({
  setModal,
  refetch,
  isEdit,
  selectedData,
}) => {
  const [projectType, setProjectType] = useState(isEdit ? "custom" : null);
  const [visibleCategoryId, setVisibleCategoryId] = useState(null);
  const [showTaskList, setShowTaskList] = useState(false);
  const [suggestedProjectData, setSuggestedProjectData] = useState(null);
  const [selectSuggestProject, setSelectSuggestProject] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedTasks, setSelectedTasks] = useState([]);

  const {
    data: categoriesData,
    isLoading: categoriesLoading,
    error: categoriesError,
  } = useDataQuery({
    url: API_END_POINTS.projectCategory,
  });

  const { mutate: createProject, isLoading: createLoading } = useMutationCreate(
    API_END_POINTS.project
  );
  const { mutate: updateProject, isLoading: updateLoading } = useMutationPatch(
    API_END_POINTS.project + "/" + selectedData?._id
  );

  const {
    handleSubmit,
    control,
    formState: { errors },
    register,
    reset,
    setValue,
  } = useForm({
    defaultValues: {
      title: selectedData?.title,
      description: selectedData?.description,
      // category: isEdit ? selectedData?.category._id : "",
      tasksList: selectedData?.tasksList,
    },
    // resolver: yupResolver(),
  });

  const { fields, remove, append } = useFieldArray({
    control,
    name: "tasksList",
  });

  const onSubmit = (data) => {
    const updatedTasks = data?.tasksList.map(({ date, ...task }) => ({
      ...task,
      startDate: task.endDate,
      endDate: task.endDate,
      estimatedTimeHours: data?.estimatedTime?.value,
    }));

    const formData = {
      ...data,
      category: selectedCategory?._id,
      tags: data.tags?.map((tag) => tag.value) || [],
      tasksList: updatedTasks,
    };

    if (isEdit) {
      updateProject(formData, {
        onSuccess(res) {
          setModal(false);
          notify("Project updated successfully", "success");
          refetch();
        },
      });
    } else {
      createProject(formData, {
        onSuccess(res) {
          setModal(false);
          notify("Project created successfully", "success");
          refetch();
        },
      });
    }
  };

  useEffect(() => {
    if (selectedCategory && !isEdit) {
      setValue(
        "tasksList",
        selectedCategory.tasks.map((task) => ({
          title: task.title,
          description: task.description,
          startDate: task.startDate ? new Date(task.startDate) : null,
          endDate: task.endDate ? new Date(task.endDate) : null,
        }))
      );
    }
  }, [selectedCategory, isEdit]);

  useEffect(() => {
    if (isEdit && selectedData?.tasksList) {
      setValue(
        "tasksList",
        selectedData.tasksList.map((task) => ({
          title: task.title,
          description: task.description,
          startDate: task.startDate ? new Date(task.startDate) : null,
          endDate: task.endDate ? new Date(task.endDate) : null,
        }))
      );
    }
  }, [isEdit, selectedData, setValue]);

  const isLastTaskComplete =
    fields.length === 0 ||
    (fields[fields.length - 1] &&
      fields[fields.length - 1].title &&
      fields[fields.length - 1].description);

  const toggleTasksVisibility = (category) => {
    setSuggestedProjectData(category);
    if (category?._id !== visibleCategoryId) {
      setSelectedTasks(category.tasks || []);
    }

    if (category?._id === visibleCategoryId) {
      setShowTaskList(!showTaskList);
    } else {
      setShowTaskList(true);
      setVisibleCategoryId(category?._id);
    }
  };

  const removeTask = (taskIndex) => {
    setSelectedTasks((prev) => prev.filter((_, index) => index !== taskIndex));
  };

  const handleSuggestedContinue = () => {
    if (!suggestedProjectData) return;

    const today = new Date();
    const endDate = new Date();

    const minRelativeDate = Math.min(
      ...selectedTasks.map((task) => task.relativeDate || 0)
    );

    endDate.setDate(today.getDate() + Math.abs(minRelativeDate));

    const prepopulatedData = {
      title: suggestedProjectData.name,
      description: suggestedProjectData.description,
      startDate: today,
      endDate: endDate,
      tasks: selectedTasks.map((task) => ({
        ...task,
        endDate: new Date(
          endDate.getTime() + (task.relativeDate || 0) * 24 * 60 * 60 * 1000
        ),
      })),
    };

    setSuggestedProjectData(prepopulatedData);
    setSelectSuggestProject(true);
    setProjectType(null);
    setShowTaskList(false);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  const renderContent = () => {
    if (isEdit) {
      return (
        <FormProject
          mode="edit"
          selectedData={selectedData}
          isLastTaskComplete={isLastTaskComplete}
          updateLoading={updateLoading}
          createLoading={createLoading}
          setModal={setModal}
          onSubmit={onSubmit}
          selectedCategory={selectedCategory}
        />
      );
    }

    if (selectSuggestProject) {
      return (
        <FormProject
          mode="template"
          selectedData={suggestedProjectData}
          isLastTaskComplete={isLastTaskComplete}
          updateLoading={updateLoading}
          createLoading={createLoading}
          setModal={setModal}
          onSubmit={onSubmit}
          selectedCategory={selectedCategory}
        />
      );
    }

    if (projectType === "custom") {
      return (
        <FormProject
          mode="create"
          selectedData={selectedData}
          isLastTaskComplete={isLastTaskComplete}
          updateLoading={updateLoading}
          createLoading={createLoading}
          setModal={setModal}
          onSubmit={onSubmit}
          selectedCategory={selectedCategory}
        />
      );
    }

    if (projectType === "suggested") {
      if (categoriesLoading) {
        return (
          <div className="pb-24 flex justify-center items-center">
            <div className="loading-spinner" />
          </div>
        );
      }

      return (
        <div className="flex flex-col h-full">
          <div className="flex-grow overflow-auto pb-16">
            {categoriesData?.data?.map((category) => (
              <div
                key={category._id}
                className={`flex flex-col justify-between p-12 border my-4 mx-4 rounded-lg border-[#9e9cae4d] cursor-pointer transition-all duration-300 ${
                  visibleCategoryId === category._id
                    ? "bg-[#4473DA]"
                    : "hover:bg-[var(--App-Background-Hover)]"
                }`}
                onClick={() => toggleTasksVisibility(category)}
              >
                <div className="flex justify-between items-center">
                  <span className="text-white font-medium text-lg capitalize">
                    {category.name}
                  </span>
                  {visibleCategoryId === category._id && showTaskList ? (
                    <ChevronUpIcon className="w-5 h-5 text-white" />
                  ) : (
                    <ChevronDownIcon className="w-5 h-5 text-white" />
                  )}
                </div>

                {visibleCategoryId === category._id && showTaskList && (
                  <div className="mt-4 space-y-2">
                    {selectedTasks.map((task, index) => (
                      <div
                        key={index}
                        className="text-white text-sm pl-4 flex justify-between items-center"
                      >
                        <span className="text-white font-medium text-[15px] capitalize">
                          • {task?.title}
                        </span>
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            removeTask(index);
                          }}
                          className="p-1 hover:bg-[#ffffff1a] rounded-full"
                        >
                          <TrashIcon className="w-4 h-4 text-white" />
                        </button>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>

          <div className="bg-[rgb(30,26,42)] p-4 border-t border-[#9e9cae1f] flex justify-end gap-3">
            <Button
              variety="outlined"
              onClick={() => {
                setVisibleCategoryId(null);
                setShowTaskList(false);
                setProjectType(null);
              }}
            >
              Back
            </Button>
            <Button
              variety="filled"
              disabled={!visibleCategoryId}
              onClick={handleSuggestedContinue}
            >
              Continue
            </Button>
          </div>
        </div>
      );
    }

    // Default: show project type selection
    return (
      <div className="flex justify-center items-center py-8">
        <div className="text-white rounded-lg p-6 flex space-x-8">
          <div
            className="flex-1 text-center border p-12 rounded-lg border-[#9e9cae4d] cursor-pointer transition-all duration-300 ease-in-out hover:bg-[var(--App-Background-Hover)]"
            onClick={() => setProjectType("custom")}
          >
            <div className="p-4 rounded-full inline-block mb-4">
              <CustomProjectIcon />
            </div>
            <h2 className="text-xl font-semibold">Create a Custom Project</h2>
            <p className="text-gray-400">You can create as you want</p>
          </div>
          <div
            className="flex-1 text-center border p-12 rounded-lg border-[#9e9cae4d] cursor-pointer transition-all duration-300 ease-in-out hover:bg-[var(--App-Background-Hover)]"
            onClick={() => setProjectType("suggested")}
          >
            <div className="p-4 rounded-full inline-block mb-4">
              <SuggestedProjectIcon />
            </div>
            <h2 className="text-xl font-semibold">
              Select a suggested project
            </h2>
            <p className="text-gray-400">Select a template</p>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div
      className="w-full relative flex flex-col"
      style={{
        height:
          !projectType && !selectSuggestProject && !isEdit
            ? "auto" // For project type selection screen
            : "500px", // For all other screens (forms, editing, etc.)
        maxHeight: "90vh", // Ensure it doesn't overflow viewport
      }}
      onKeyDown={handleKeyDown}
    >
      {renderContent()}
    </div>
  );
};
