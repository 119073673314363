import React from "react";
import Dropdown from "../Dropdown/Dropdown";
import "./ProjectDropdown.css";

const ProjectOption = ({ label, isSelected }) => (
  <div className={`project-option ${isSelected ? "selected" : ""}`}>
    <span>{label}</span>
  </div>
);

const ProjectDropdown = ({ projects, selectedProject, onProjectChange }) => {
  const projectOptions = projects?.map((project) => ({
    label: (
      <ProjectOption
        label={project.title}
        isSelected={selectedProject === project._id}
      />
    ),
    value: project._id,
  }));

  const getCurrentProjectLabel = (projectId) => {
    if (!projectId) {
      return <ProjectOption label="Select Project" isSelected={false} />;
    }
    const project = projects?.find((proj) => proj._id === projectId);
    return project ? (
      <ProjectOption label={project.title} isSelected={true} />
    ) : (
      <ProjectOption label="Select Project" isSelected={false} />
    );
  };

  return (
    <Dropdown
      options={projectOptions}
      onOptionChange={(option) => onProjectChange(option.value)}
      initialOption={{
        label: getCurrentProjectLabel(selectedProject),
        value: selectedProject || "",
      }}
      className="project-dropdown"
    />
  );
};

export default ProjectDropdown;
