import { yupResolver } from "@hookform/resolvers/yup";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useMutationCreate } from "src/hooks/crud/mutation.create";
import { API_END_POINTS } from "../../../utils/api-endpoint";
import * as yup from "yup";
import loginImage from "../../../assets/png/login.png";
import { Logo } from "../../../assets/svg/Logo";
import Button from "../../../components/Button";
import "./ForgotPassword.css";
import { APP_ROUTES } from "../../../utils/app-routes";

const ForgotPassword = () => {
  return (
    <>
      <div className="w-full flex flex-col md:flex-row">
        {/* Image section - hide on mobile */}
        <div className="hidden md:block md:w-1/2 login-banner">
          <img
            className="w-full h-screen object-cover"
            src={loginImage}
            alt="Login"
          />
        </div>

        {/* Form section */}
        <div className="w-full md:w-1/2 box p-4 md:p-8">
          <div className="form-side max-w-md mx-auto">
            <div className="mb-8 md:mb-12 h-[3.125rem] w-[11.53rem]">
              <Logo />
            </div>

            <h2 className="text-2xl md:text-3xl mb-2 md:mb-4 white">
              Forgot your password?
            </h2>

            <span className="text-sm md:text-base block mb-6 white">
              Enter the email that you used when register to recover your
              password. You will receive a password reset link.
            </span>

            <LoginForm />
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;

const schema = yup.object().shape({
  email: yup.string().required("Email is required"),
});

const LoginForm = () => {
  const navigate = useNavigate();
  const { mutate: forgotPassword } = useMutationCreate(
    API_END_POINTS.forgotPassword
  );

  const {
    handleSubmit,
    control,
    register,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    forgotPassword(data, {
      onSuccess: () => {
        navigate(APP_ROUTES.otpVerification, {
          state: { email: data.email, isForgot: true },
        });
      },
      onError: () => {
        console.log("error");
      },
    });
    // navigate("/verify");
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div>
        <Controller
          name="email"
          control={control}
          render={({ field }) => (
            <input
              className="w-full mt-4"
              placeholder="Email"
              type="email"
              // required
              {...register("email")}
            />
          )}
        />
        <span
          style={{
            color: "var(--App-Red)",
          }}
        >
          {errors.email?.message}
        </span>
      </div>

      <div>
        <Button
          // onClick={() => navigate("/verify")}
          type="submit"
          variety="filled"
          className="primary-button w-100 black m-t-24"
          height={"56px"}
          // isLoading={isLoading}
        >
          Send Verification Code
        </Button>
      </div>
    </form>
  );
};
