import { CalendarIcon } from "@radix-ui/react-icons";
import React, { useState, useEffect } from "react";
import ReactDatePicker from "react-datepicker";
import { Controller, useForm } from "react-hook-form";
import "react-select-search/style.css";
import { useDataQuery } from "../../hooks/crud/data.query";
import { useMutationCreate } from "../../hooks/crud/mutation.create";
import { useMutationPatch } from "../../hooks/crud/mutation.patch";
import { API_END_POINTS } from "../../utils/api-endpoint";
import Button from "../Button";
import {
  DatePickerInputComponent,
  CustomInputComponent,
} from "../Input/CustomInputComponent";
import FormSelect from "../Select/FormSelect";
import { notify } from "../Toast/ToastComponent";
import { priorityData } from "./constant";
import Dropdown from "../Dropdown";
import { estimatedTimeData } from "src/constants/constant";

export const CreateTaskForm = ({
  setModal,
  isEdit,
  selectedData,
  refetch,
  setJustUpdated,
  projectData,
  data,
  setData,
  refetchDashboard,
}) => {
  const { data: categoriesData } = useDataQuery({
    url: API_END_POINTS.projectCategory,
  });
  const { mutate: createTask, isLoading: createLoading } = useMutationCreate(
    API_END_POINTS.tasks
  );
  const { mutate: updateTask, isLoading: updateLoading } = useMutationPatch(
    `${API_END_POINTS.tasks}/${selectedData?._id}`
  );
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [projectId, setProjectId] = useState(selectedData?.project || null);
  const {
    handleSubmit,
    control,
    formState: { errors },
    register,
    reset,
    setValue,
    getValues,
  } = useForm({
    defaultValues: {
      title: selectedData?.title,
      date: selectedData?.date,
      description: selectedData?.description,
      priority: selectedData?.priority
        ? {
            label:
              selectedData?.priority?.toLowerCase()?.charAt(0)?.toUpperCase() +
              selectedData?.priority?.toLowerCase().slice(1),

            value: selectedData?.priority?.toLowerCase(),
          }
        : null,
      project: selectedData?.project
        ? {
            label: projectData?.find(
              (data) => data?._id === selectedData?.project
            )?.title,
            value: selectedData?.project,
          }
        : null,
    },

    // resolver: yupResolver(),
  });

  const onSubmit = (data) => {
    isEdit
      ? updateTask(
          {
            ...data,
            priority: data?.priority?.value?.toUpperCase(),
            projectId: projectId,
          },
          {
            onSuccess(response) {
              const updatedTask = response?.data;
              setModal(false);

              // Update local state based on the view type
              setData((prevData) => {
                // If the data is an array of tasks (Timeline View)
                if (Array.isArray(prevData) && !prevData[0]?.tasksList) {
                  return prevData.map((task) =>
                    task?._id === updatedTask?._id ? updatedTask : task
                  );
                }

                // If the data is an array of projects (List View)
                return prevData.map((project) => {
                  if (!project.tasksList) return project;

                  return {
                    ...project,
                    tasksList: project.tasksList.map((task) =>
                      task._id === updatedTask._id ? updatedTask : task
                    ),
                  };
                });
              });

              // Trigger refetch and other updates
              // refetch();
              setJustUpdated(true);
              refetchDashboard();
              refetch();
              notify("Task updated successfully", "success");
            },
            onError(error) {
              notify(error?.message || "Failed to update task", "error");
            },
          }
        )
      : createTask(
          {
            ...data,
            priority: data?.priority
              ? data?.priority?.value?.toUpperCase()
              : "LOW",
            projectId: projectId,
            startDate: data?.dueDate,
            endDate: data?.dueDate,
            estimatedTimeHours: data?.estimatedTime?.value,
          },
          {
            onSuccess(response) {
              const newTask = response?.data;
              setModal(false);
              refetch();
              refetchDashboard();
              notify("Task created successfully", "success");
            },
            onError(error) {
              notify(error?.message || "Failed to create task", "error");
            },
          }
        );
  };

  return (
    <div className="max-h-[500px] overflow-y-auto">
      <form className="p-24 w-full" onSubmit={handleSubmit(onSubmit)}>
        <div className="flex justify-between gap-4 mb-6">
          <div className="w-1/2">
            <span className="text-gray-300 text-sm mb-2 block">
              Project (Optional)
            </span>
            <FormSelect
              className="w-full"
              control={control}
              label="project"
              id="project"
              options={projectData?.map((item) => ({
                label: item.title,
                value: item._id,
              }))}
              onChange={(e) => {
                setProjectId(e?.value || null);
                setValue("project", e || null);
              }}
              value={getValues("project")}
              name="project"
              placeholder="Select Project"
              isClearable
            />
          </div>
          <div className="w-1/2">
            <span className="text-gray-300 text-sm mb-2 block">Priority</span>
            <FormSelect
              className="w-full"
              control={control}
              label="priority"
              id="priority"
              options={priorityData}
              name="priority"
              placeholder="Select Priority"
            />
          </div>
        </div>

        <div className="mb-6">
          <span className="text-gray-300 text-sm mb-2 block">Title</span>
          <Controller
            name="title"
            control={control}
            render={({ field }) => (
              <input
                className="w-full p-3 quick-add-task-container create-project-input"
                style={{ background: "var(--App-Background)" }}
                placeholder="Task Title"
                required
                {...field}
              />
            )}
          />
        </div>

        <div className="mb-6">
          <span className="text-gray-300 text-sm mb-2 block">Description</span>
          <Controller
            name="description"
            control={control}
            render={({ field }) => (
              <textarea
                className="w-full quick-add-task-container create-project-input"
                style={{
                  background: "var(--App-Background)",
                  minHeight: "100px",
                  maxHeight: "200px",
                  padding: "12px",
                }}
                placeholder="Task Description"
                {...field}
              />
            )}
          />
        </div>

        <div className="mb-6">
          <span className="text-gray-300 text-sm mb-2 block">Due Date</span>
          <Controller
            name="dueDate"
            control={control}
            render={({ field }) => (
              <ReactDatePicker
                selected={field.value}
                fixedHeight
                onChange={(date) => field.onChange(date)}
                className="w-full p-3 quick-add-task-container create-project-input"
                placeholderText="Due Date"
                dateFormat="MM/dd/yyyy"
              />
            )}
          />
        </div>

        <div className="mb-6">
          <span className="text-gray-300 text-sm mb-2 block">
            Estimated Time
          </span>
          <Controller
            name="estimatedTime"
            control={control}
            render={({ field }) => (
              <Dropdown
                options={estimatedTimeData}
                onOptionChange={(e) => field.onChange(e)}
                value={field.value}
                placeholder="Select Estimated Time"
              />
            )}
          />
        </div>

        <div className="flex justify-end">
          <Button
            variety="filled"
            type="submit"
            isLoading={createLoading || updateLoading}
          >
            Save
          </Button>
        </div>
      </form>
    </div>
  );
};
