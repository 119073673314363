export const SidebarHideIcon = (props) => (
    <svg  {...props} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_2001_147)">
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M19.62 0.379998C20.13 0.889998 20.13 1.72 19.62 2.22L11.85 9.99L19.62 17.76C20.13 18.27 20.13 19.1 19.62 19.6C19.11 20.11 18.28 20.11 17.78 19.6L9.07999 10.9C8.56999 10.39 8.56999 9.56 9.07999 9.06L17.77 0.379998C18.28 -0.130002 19.11 -0.130002 19.61 0.379998H19.62Z"
                  fill="#DCDCDC"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M10.92 0.379998C11.43 0.889998 11.43 1.72 10.92 2.22L3.15 9.99L10.92 17.76C11.43 18.27 11.43 19.1 10.92 19.6C10.41 20.11 9.58 20.11 9.08 19.6L0.379998 10.92C-0.130002 10.41 -0.130002 9.58 0.379998 9.08L9.08 0.379998C9.59 -0.130002 10.42 -0.130002 10.92 0.379998Z"
                  fill="#DCDCDC"/>
        </g>
        <defs>
            <clipPath id="clip0_2001_147">
                <rect width="20" height="20" fill="white"/>
            </clipPath>
        </defs>
    </svg>);