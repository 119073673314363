import React from "react";
import Dropdown from "../Dropdown/Dropdown";
import {
  DoubleArrowUpIcon,
  ArrowUpIcon,
  ArrowDownIcon,
} from "@radix-ui/react-icons";
import "./PriorityDropdown.css";

const PriorityOption = ({ icon: Icon, label, color, showPrefix = false }) => (
  <div className="priority-option">
    {showPrefix && <span className="gray-300 regularS mr-1">Priority:</span>}
    <Icon className="priority-icon" style={{ color }} />
    <span>{label}</span>
  </div>
);

const PriorityDropdown = ({ priority, onPriorityChange, isDashboard }) => {
  const priorityOptions = [
    {
      label: (
        <PriorityOption icon={DoubleArrowUpIcon} label="High" color="#EF4870" />
      ),
      value: "HIGH",
      displayLabel: (
        <PriorityOption
          icon={DoubleArrowUpIcon}
          label="High"
          color="#EF4870"
          showPrefix={true}
        />
      ),
    },
    {
      label: (
        <PriorityOption icon={ArrowUpIcon} label="Medium" color="#FF7700" />
      ),
      value: "MEDIUM",
      displayLabel: (
        <PriorityOption
          icon={ArrowUpIcon}
          label="Medium"
          color="#FF7700"
          showPrefix={true}
        />
      ),
    },
    {
      label: (
        <PriorityOption icon={ArrowDownIcon} label="Low" color="#4CAF50" />
      ),
      value: "LOW",
      displayLabel: (
        <PriorityOption
          icon={ArrowDownIcon}
          label="Low"
          color="#4CAF50"
          showPrefix={true}
        />
      ),
    },
  ];

  const getCurrentPriorityLabel = (priority) => {
    const option = priorityOptions.find((opt) => opt.value === priority);
    return option?.displayLabel || priorityOptions[1].displayLabel; // Default to Medium
  };

  return (
    <Dropdown
      options={priorityOptions}
      onOptionChange={(option) => onPriorityChange(option.value)}
      initialOption={{
        label: getCurrentPriorityLabel(priority),
        value: priority,
      }}
      className="priority-dropdown"
      isDashboard={isDashboard}
    />
  );
};

export default PriorityDropdown;
