import { Navigate, createBrowserRouter } from "react-router-dom";
import PrivateLayout from "./components/Layout/PrivateLayout";
import ForgotPassword from "./pages/Auth/ForgotPassword/ForgotPassword";
import Login from "./pages/Auth/LoginPage";
import OnboardingQuestion from "./pages/Auth/OnboardingQuestion/OnboardingQuestion";
import OtpVerification from "./pages/Auth/OtpVerification/OtpVerification";
import Signup from "./pages/Auth/Signup/Signup";
import UploadProfileImage from "./pages/Auth/UploadProfileImage/UploadProfileImage";
import BadgePage from "./pages/Badges/BadgePage";
import BookingPage from "./pages/Booking/BookingPage";
import PaymentSuccessPage from "./pages/Booking/PaymentSuccess";
import Callback from "./pages/Callback/Callback";
import InstagramCallback from "./pages/Callback/InstagramCallback";
import Dashboard from "./pages/Dashboard/Dashboard";
import EventAndTaskPage from "./pages/Event/EventAndTasks";
import Social from "./pages/SocialPage/Social";
import SocialOverviewPage from "./pages/SocialPage/SocialOverviewPage";
import { getUserProfile } from "./services/commonService";
import { APP_ROUTES } from "./utils/app-routes";
import { USER_DETAILS } from "./utils/constants";
import PasswordReset from "./pages/Auth/PasswordReset";
import CalendarPage from "./pages/Calendar/CalendarPage";
import { Suspense } from "react";
import { useIsSidebarOpen } from "./hooks/ui/IsSidebarOpen";
import PublicLayout from "./components/Layout/PublicLayout";

// Loading fallback component
const LoadingFallback = () => (
  <div className="flex items-center justify-center h-screen w-screen bg-background-primary">
    <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-white"></div>
  </div>
);

async function isAuthenticated() {
  const isAuth = localStorage.getItem(USER_DETAILS);
  if (!isAuth) return false;

  try {
    const data = await getUserProfile();
    return !!data?.data?._id;
  } catch (error) {
    return false;
  }
}

function AuthenticatedRoute({ children }) {
  const currentPath = window.location.pathname;
  return isAuthenticated() ? (
    children
  ) : (
    <Navigate to="/login" state={{ from: currentPath }} />
  );
}

// Create a wrapper component to access the context
const PrivateLayoutWrapper = () => {
  const { isSidebarOpen, toggleSidebar } = useIsSidebarOpen();
  return (
    <AuthenticatedRoute>
      <PrivateLayout isSidebarOpen={isSidebarOpen} />
    </AuthenticatedRoute>
  );
};

const router = createBrowserRouter([
  {
    path: APP_ROUTES.root,
    element: <PrivateLayoutWrapper />,
    children: [
      {
        path: APP_ROUTES.dashboard,
        element: (
          <Suspense fallback={<LoadingFallback />}>
            <Dashboard />
          </Suspense>
        ),
        index: true,
      },
      {
        path: APP_ROUTES.eventAndTasks,
        element: <EventAndTaskPage />,
      },
      {
        path: APP_ROUTES.calendar,
        element: <CalendarPage />,
      },
      {
        path: APP_ROUTES.booking,
        element: <BookingPage />,
      },
      {
        path: APP_ROUTES.social,
        element: <Social />,
        // children: [
        //   {
        //     path: APP_ROUTES.socialOverViewPage + "/:platform",
        //     element: <SocialOverviewPage />,
        //   },
        // ],
      },
      {
        path: APP_ROUTES.bookingSuccess,
        element: <PaymentSuccessPage />,
      },
      {
        path: APP_ROUTES.badge,
        element: <BadgePage />,
      },
      {
        path: APP_ROUTES.callback,
        element: <Callback />,
      },
      {
        path: APP_ROUTES.instacallback,
        element: <InstagramCallback />,
      },
      {
        path: APP_ROUTES.socialOverViewPage + "/:platform",
        element: <SocialOverviewPage />,
      },
    ],
  },
  {
    element: <PublicLayout />,
    children: [
      {
        element: <Login />,
        path: APP_ROUTES.login,
      },
      {
        element: <Signup />,
        path: APP_ROUTES.signup,
      },
      {
        element: <OtpVerification />,
        path: APP_ROUTES.otpVerification,
      },
      {
        element: <ForgotPassword />,
        path: APP_ROUTES.forgotPassword,
      },
      {
        element: <PasswordReset />,
        path: APP_ROUTES.resetPassword,
      },
      {
        element: <UploadProfileImage />,
        path: APP_ROUTES.uploadProfileImage,
      },
      {
        element: <OnboardingQuestion />,
        path: APP_ROUTES.onboardingQuestion,
      },
    ],
  },
  {
    element: <Navigate to={APP_ROUTES.login} replace={true} />,
    path: "/undefined",
  },
]);

// Make isAuthenticated available for PublicLayout
export { isAuthenticated };
export default router;
