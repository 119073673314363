import React, { useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import "react-select-search/style.css";
import { TrashIcon } from "../../assets/svg/TrashIcon";
import Button from "../Button";
import FormSelect from "../Select/FormSelect";
import {
  ChevronDownIcon,
  ChevronRightIcon,
  CalendarIcon,
  Cross1Icon,
} from "@radix-ui/react-icons";
import { useDataQuery } from "../../hooks/crud/data.query";
import { TagSelector } from "../Select/TagSelector";
import { API_END_POINTS } from "../../utils/api-endpoint.js";
import Dropdown from "../Dropdown";
import { estimatedTimeData } from "../../constants/constant";
import Dialog from "../Dialog";

const calculateEndDate = (relativeDate) => {
  if (relativeDate === undefined) return null;
  const today = new Date();
  return new Date(today.getTime() + relativeDate * 24 * 60 * 60 * 1000);
};

export const FormProject = ({
  mode,
  isLastTaskComplete,
  updateLoading,
  createLoading,
  setModal,
  selectedData,
  selectedCategory,
  onSubmit,
}) => {
  const { data: tagsData } = useDataQuery({
    url: API_END_POINTS.tags,
  });

  const [manuallyEditedTasks, setManuallyEditedTasks] = useState(new Set());
  const [showConfirmation, setShowConfirmation] = useState(false);

  const defaultValues = {
    title: selectedData?.title || "",
    description: selectedData?.description || "",
    startDate: selectedData?.startDate || new Date(),
    endDate: selectedData?.endDate || new Date(),
    tasksList: (() => {
      switch (mode) {
        case "edit":
          return selectedData.tasksList.map(({ date, ...task }) => task);
        case "template":
          return selectedData.tasks.map((task) => ({
            ...task,
            endDate: calculateEndDate(task.relativeDate),
            estimatedTime: task.estimatedTimeHours
              ? estimatedTimeData.find(
                  (option) => option.value === task.estimatedTimeHours
                )
              : null,
          }));
        default:
          return [];
      }
    })(),
    tags:
      selectedData?.tags?.map((tag) => ({
        value: tag._id,
        label: tag.name,
        color: tag.color,
      })) || [],
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
    register,
    reset,
    setValue,
    watch,
  } = useForm({ defaultValues });

  const projectEndDate = watch("endDate");

  useEffect(() => {
    if (!selectedData?.tasks || !projectEndDate) return;

    selectedData.tasks.forEach((task, index) => {
      if (manuallyEditedTasks.has(index)) return;

      if (task.relativeDate !== undefined) {
        const newTaskDate = new Date(
          projectEndDate.getTime() + task.relativeDate * 24 * 60 * 60 * 1000
        );
        setValue(`tasksList.${index}.endDate`, newTaskDate);
      }
    });
  }, [projectEndDate, selectedData?.tasks, manuallyEditedTasks]);

  const handleTaskDateChange = (index, date) => {
    setManuallyEditedTasks((prev) => new Set(prev).add(index));
    setValue(`tasksList.${index}.endDate`, date);
    setValue(`tasksList.${index}.startDate`, date);
  };

  const [visibleTasks, setVisibleTasks] = useState({});
  const [taskTitles, setTaskTitles] = useState({});

  const { fields, remove, append } = useFieldArray({
    control,
    name: "tasksList",
  });

  const toggleTaskVisibility = (index) => {
    setVisibleTasks((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  useEffect(() => {
    if (fields?.length > 0 && Object.keys(visibleTasks).length === 0) {
      setVisibleTasks({ 0: true });
    }
  }, [fields]);

  const handleAddTask = () => {
    const newIndex = fields.length;
    append({
      title: "",
      description: "",
      startDate: null,
      endDate: null,
    });
    setVisibleTasks({
      [newIndex]: true,
    });
  };

  const handleTaskTitleBlur = (index, value) => {
    setTaskTitles((prev) => ({
      ...prev,
      [index]: value,
    }));
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  const handleClose = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setShowConfirmation(true);
  };

  const handleConfirmClose = () => {
    setShowConfirmation(false);
    setModal(false);
  };

  useEffect(() => {
    if (fields?.length > 0) {
      const initialTitles = {};
      fields.forEach((field, index) => {
        initialTitles[index] = field.title;
      });
      setTaskTitles(initialTitles);
    }
  }, [fields]);

  return (
    <form
      className="flex flex-col h-full"
      onSubmit={handleSubmit(onSubmit)}
      onKeyDown={handleKeyDown}
    >
      <div className="flex-1 px-12 py-8 overflow-y-auto">
        <div className="max-w-[95%] mx-auto">
          <div>
            <label className="create-label regularM">Title</label>
            <Controller
              name="title"
              control={control}
              render={({ field }) => (
                <input
                  className="m-t-8 quick-add-task-container create-project-input w-full"
                  style={{ background: "var(--App-Background)" }}
                  placeholder="Project title"
                  required
                  {...register("title")}
                />
              )}
            />
          </div>
          <div className="m-t-16">
            <label className="create-label regularM">Description</label>
            <Controller
              name="description"
              control={control}
              render={({ field }) => (
                <input
                  className="m-t-8 quick-add-task-container create-project-input w-full"
                  style={{ background: "var(--App-Background)" }}
                  placeholder="Project description"
                  {...register("description")}
                />
              )}
            />
          </div>
          <div className="flex gap-4 m-t-16">
            <div className="w-1/2 relative">
              <label className="create-label regularM">Start Date</label>
              <Controller
                name="startDate"
                control={control}
                render={({ field }) => (
                  <div className="relative">
                    <CalendarIcon
                      className="absolute z-10 left-4 top-1/2 transform -translate-y-1/2 text-gray-300"
                      style={{ pointerEvents: "none" }}
                    />
                    <ReactDatePicker
                      selected={field.value}
                      onChange={(date) => field.onChange(date)}
                      className="w-full p-3 quick-add-task-container create-project-input"
                      style={{ background: "var(--App-Background)" }}
                      placeholderText="Start Date"
                      dateFormat="MM/dd/yyyy"
                    />
                  </div>
                )}
              />
            </div>
            <div className="w-1/2 relative">
              <label className="create-label regularM">End Date</label>
              <Controller
                name="endDate"
                control={control}
                render={({ field }) => (
                  <div className="relative">
                    <CalendarIcon
                      className="absolute z-10 left-4 top-1/2 transform -translate-y-1/2 text-gray-300"
                      style={{ pointerEvents: "none" }}
                    />
                    <ReactDatePicker
                      selected={field.value}
                      onChange={(date) => field.onChange(date)}
                      className="w-full p-3 quick-add-task-container create-project-input"
                      style={{ background: "var(--App-Background)" }}
                      placeholderText="End Date"
                      dateFormat="MM/dd/yyyy"
                    />
                  </div>
                )}
              />
            </div>
          </div>
          <div className="m-t-16 relative z-[10]">
            <label className="create-label regularM">Tags</label>
            <Controller
              name="tags"
              control={control}
              render={({ field }) => (
                <TagSelector
                  value={field.value}
                  onChange={(newValue) => {
                    field.onChange(newValue);
                  }}
                  options={tagsData?.data?.map((tag) => ({
                    value: tag._id,
                    label: tag.name,
                    color: tag.color,
                  }))}
                />
              )}
            />
          </div>
          <div className="m-t-8 w-100 relative z-[1]">
            {fields?.map((task, index) => (
              <div
                key={index}
                className="justify-space-between pb-1 p-relative"
              >
                <div className="w-100">
                  <div className="flex-row align-center justify-between">
                    <div className="flex align-center">
                      {visibleTasks[index] ? (
                        <ChevronDownIcon
                          color="white"
                          onClick={() => toggleTaskVisibility(index)}
                          className="cursor-pointer"
                        />
                      ) : (
                        <ChevronRightIcon
                          color="white"
                          onClick={() => toggleTaskVisibility(index)}
                          className="cursor-pointer"
                        />
                      )}
                      <span className="boldM white pl-2">
                        {taskTitles[index] ||
                          (mode === "edit" ? task?.title : "Add task")}
                      </span>
                    </div>
                    <div
                      className="trash-background cursor-pointer"
                      onClick={() => remove(index)}
                    >
                      {/* <TrashIcon color="white" /> */}
                      {/* <DeleteAction /> */}
                      <TrashIcon />
                    </div>
                  </div>
                  {visibleTasks[index] && (
                    <div className="px-4">
                      <div className="w-100 flex-row">
                        <div className="w-45">
                          <span className="gray-300 mediumM">Task Title</span>
                          <Controller
                            name={`tasksList[${index}].title`}
                            control={control}
                            defaultValue={task?.title || ""}
                            render={({ field }) => (
                              <input
                                className="m-t-8 w-80 quick-add-task-container create-project-input"
                                style={{ background: "var(--App-Background)" }}
                                key={index}
                                // value={task?.title}
                                placeholder="Task Title"
                                {...register(`tasksList[${index}].title`)}
                                onBlur={(e) => {
                                  field.onBlur();
                                  handleTaskTitleBlur(index, e.target.value);
                                }}
                              />
                            )}
                          />
                        </div>
                        <div className="flex-column w-50 m-t-8">
                          <span className="gray-300 mediumM">Due Date</span>
                          <Controller
                            name={`tasksList[${index}].endDate`}
                            control={control}
                            render={({ field }) => (
                              <div className="relative">
                                <CalendarIcon
                                  className="absolute z-10 left-4 top-1/2 text-gray-300"
                                  style={{ pointerEvents: "none" }}
                                />
                                <ReactDatePicker
                                  selected={field.value}
                                  fixedHeight
                                  onChange={(date) =>
                                    handleTaskDateChange(index, date)
                                  }
                                  className="m-t-8 w-40 quick-add-task-container create-project-input"
                                  placeholderText="Due Date"
                                  dateFormat={"MM/dd/yyyy"}
                                />
                              </div>
                            )}
                          />
                        </div>
                      </div>
                      <div>
                        <div className="w-100">
                          <span className="gray-300 mediumM">
                            Task Description
                          </span>
                          <Controller
                            name={`tasksList[${index}].description`}
                            control={control}
                            defaultValue={task?.description || ""}
                            render={({ field }) => (
                              <textarea
                                className="m-t-8 w-100 quick-add-task-container create-project-input"
                                style={{
                                  background: "var(--App-Background)",
                                  minHeight: 30,
                                  maxHeight: 200,
                                  maxWidth: "95%",
                                  minWidth: "95%",
                                  padding: "8px 16px",
                                }}
                                placeholder="Task description"
                                {...field}
                              />
                            )}
                          />
                        </div>
                      </div>
                      <div className="m-t-16">
                        <label className="create-label regularM">
                          Estimated Time
                        </label>
                        <Controller
                          name={`tasksList[${index}].estimatedTime`}
                          control={control}
                          render={({ field }) => (
                            <Dropdown
                              options={estimatedTimeData}
                              onOptionChange={(e) => field.onChange(e)}
                              initialOption={field.value}
                              placeholder="Select Estimated Time"
                            />
                          )}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="flex justify-between items-center px-12 py-4 border-t border-[#9e9cae1f] bg-[rgb(30,26,42)]">
        <Button
          variety="outlined"
          disabled={!isLastTaskComplete}
          type="button"
          onClick={handleAddTask}
        >
          + Add Task
        </Button>
        <div className="flex gap-2">
          <Button variety="outlined" type="button" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variety="filled"
            type="submit"
            isLoading={createLoading || updateLoading}
          >
            Submit
          </Button>
        </div>
      </div>

      <Dialog
        show={showConfirmation}
        action1ButtonLabel="Cancel"
        action2ButtonLabel="Confirm"
        handelAction1={() => setShowConfirmation(false)}
        handelAction2={handleConfirmClose}
      >
        <div className="flex-col justify-center align-middle">
          <div
            style={{
              background: "rgba(37, 31, 52, 1)",
              width: 100,
              height: 100,
              borderRadius: 100,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              position: "absolute",
              top: 20,
              left: "40%",
            }}
          >
            <Cross1Icon width={40} height={40} color="white" />
          </div>
          <div className="pt-28">
            <h3 className="white text-align-center">Are you sure?</h3>
            <h5 className="white text-align-center">
              You will lose any unsaved changes.
            </h5>
          </div>
        </div>
      </Dialog>
    </form>
  );
};
