import moment from "moment";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { AddPreIcon } from "../../assets/svg/AddPreIcon";
import { SearchOutlined } from "../../assets/svg/SearchOutliined";
import { EventViewType } from "../../constants/constant";
import { useDataQuery } from "../../hooks/crud/data.query";
import { useMutationCreate } from "../../hooks/crud/mutation.create";
import { useMutationDelete } from "../../hooks/crud/mutation.delete";
import { API_END_POINTS } from "../../utils/api-endpoint";
import Button from "../Button";
import Calendar from "../Calendar/Calendar";
import Card from "../Card/Card";
import Dialog from "../Dialog/Dialog";
import { EventListViewComponent } from "../ListView/ListViewComponent";
import Modal, { CreateModal } from "../Modal/Modal";
import { EventModalViewDetails } from "./ModalView";

import { CreateEventForm } from "../CreateEvent/CreateEvent";
import GoogleCalendar from "../GoogleCalendar/GoogleCalendar";
import { notify } from "../Toast/ToastComponent";
import { DeleteOutlined } from "../../assets/svg/DeleteOutlined";
import { EmptyCalendarIcon } from "../../assets/svg/EmptyCalendarIcon";
import {
  useMutationPatch,
  useMutationPatchEvent,
} from "src/hooks/crud/mutation.patch";
import EventListSkeleton from "../Skeleton/EventListSkeleton";
import PaginationFooter from "../Pagination/PaginationFooter";

const ITEMS_PER_PAGE = 10; // Number of events per page

const CalendarComponent = ({
  className,
  isEventTab,
  timelineData,
  isLoadingTimeline,
  googleCalendarStatus,
  ...props
}) => {
  const [selectedViewType, setSelectedViewType] = useState("Calendar View");
  const [data, setData] = useState();
  const [selectedData, setSelectedData] = useState();
  const [isEventModalOpen, setIsEventModalOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [showSearch, setShowSearch] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [googleEvents, setGoogleEvents] = useState([]);
  const [confirmationDialog, setConfirmationDialog] = useState(false);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const {
    handleSubmit,
    control,
    formState: { errors },
    register,
    reset,
  } = useForm({});

  const {
    data: dashboardData,
    refetch: refetchDashboardData,
    isLoading: dashboardLoading,
    error: dashboardError,
  } = useDataQuery({
    url: API_END_POINTS.dashboard,
    params: {},
  });

  //create a event
  const { mutate: createEvent } = useMutationCreate(API_END_POINTS.events);

  // delete the event
  const { mutate: deleteEvent } = useMutationDelete(
    API_END_POINTS.events,
    selectedData?._id
  );

  // Add this mutation hook near the top with other mutations
  const { mutate: updateEvent } = useMutationPatchEvent(
    API_END_POINTS.events,
    selectedData?._id
  );

  const handleEventClick = (e) => {
    // Format the data for the form
    const startDateTime = moment(e.startDateTime);
    const endDateTime = moment(e.endDateTime);

    const formattedData = {
      _id: e._id,
      title: e.title || "",
      eventDetails: e.eventDetails || "",
      location: e.location || "",
      eventType: e.eventType || "Meeting",
      collaboration: e.collaboration || "Public",
      tags: e.tags || [],
      // Format dates
      startDate: startDateTime.toDate(),
      endDate: endDateTime.toDate(),
      // Format times for the TimePicker.RangePicker
      time: [
        moment(startDateTime).format("HH:mm"),
        moment(endDateTime).format("HH:mm"),
      ],
      // Keep original date strings for reference
      startDateTime: e.startDateTime,
      endDateTime: e.endDateTime,
      // Add these for the form processing
      startTime: startDateTime.format("HH:mm"),
      endTime: endDateTime.format("HH:mm"),
    };

    setIsEventModalOpen(true);
    setModalTitle("Event Details");
    setSelectedData(formattedData);
  };

  const handleCreate = () => {
    setIsCreateModalOpen(true);
    setModalTitle("Create Event");
    setSelectedData({});
  };

  const handleDeleteEvent = () => {
    setConfirmationDialog(true);
  };

  const handleDeleteConfirmation = () => {
    setIsButtonLoading(true);
    deleteEvent(selectedData?.id, {
      onSuccess(res) {
        setIsEventModalOpen(false);
        refetchDashboardData();
        setConfirmationDialog(false);
        setIsButtonLoading(false);
        notify("Event deleted successfully", "success");
        refetchDashboardData();
      },
      onError(error) {
        setIsButtonLoading(false);
        notify("Error deleting event", "error");
      },
    });
  };

  const handleEditSubmit = (data) => {
    const startTime = data?.time?.map((item) =>
      new Date(item)?.toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
      })
    )?.[0];
    const endTime = data?.time?.map((item) =>
      new Date(item)?.toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
      })
    )?.[1];

    const startDateTime = new Date(
      moment(data?.startDate).format("YYYY-MM-DD") + " " + startTime
    );
    const endDateTime = new Date(
      moment(data?.endDate).format("YYYY-MM-DD") + " " + endTime
    );
    setIsButtonLoading(true);
    updateEvent(
      {
        ...data,
        startDateTime: startDateTime.toISOString(),
        endDateTime: endDateTime.toISOString(),
        startTime: startTime,
        endTime: endTime,
      },
      selectedData?._id,
      {
        onSuccess: () => {
          setIsButtonLoading(false);
          setIsCreateModalOpen(false);
          setIsEdit(false);
          refetchDashboardData();
          notify("Event updated successfully", "success");
          reset();
        },
        onError: () => {
          setIsButtonLoading(false);
          notify("Error updating event", "error");
        },
      }
    );
  };

  const onSubmit = (data) => {
    if (isEdit) {
      handleEditSubmit(data);
      return;
    }

    const startTime = data?.time?.map((item) =>
      new Date(item)?.toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
      })
    )?.[0];
    const endTime = data?.time?.map((item) =>
      new Date(item)?.toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
      })
    )?.[1];
    const startDateTime = new Date(
      moment(data?.startDate).format("YYYY-MM-DD") + " " + startTime
    );
    const endDateTime = new Date(
      moment(data?.endDate).format("YYYY-MM-DD") + " " + endTime
    );
    setIsButtonLoading(true);
    createEvent(
      {
        ...data,
        startDateTime: startDateTime.toISOString(),
        endDateTime: endDateTime.toISOString(),
        startTime: startTime,
        endTime: endTime,
        tags: [],
        eventType: "Meeting",
        collaboration: "Public",
      },
      {
        onSuccess(res) {
          setIsButtonLoading(false);
          setIsCreateModalOpen(false);
          refetchDashboardData();
          notify("Event created successfully", "success");
          reset();
        },
        onError(error) {
          setIsButtonLoading(false);
          notify("Error creating event", "error");
        },
      }
    );
  };

  // Transform Google events to match your event format
  const transformGoogleEvent = (googleEvent) => {
    return {
      _id: googleEvent?.id,
      title: googleEvent?.summary,
      eventDetails: googleEvent?.description || "",
      collaboration: "Public",
      tags: [],
      location:
        googleEvent?.hangoutLink || googleEvent?.location || "Google Meet",
      eventType: googleEvent?.eventType || "Meeting",
      startDate: googleEvent?.start?.dateTime?.split("T")[0],
      startTime: googleEvent?.start?.dateTime?.split("T")[1].split("+")[0],
      endDate: googleEvent?.end?.dateTime?.split("T")[0],
      endTime: googleEvent?.end?.dateTime?.split("T")[1].split("+")[0],
      user: googleEvent?.creator?.email,
      createdAt: googleEvent?.created,
      updatedAt: googleEvent?.updated,
      __v: 0,
    };
  };

  useEffect(() => {
    let data = dashboardData?.data?.events || [];
    if (googleEvents) {
      const transformedGoogleEvents = googleEvents.map(transformGoogleEvent);
      data = [...data, ...transformedGoogleEvents];
    }

    // Sort events by startDateTime
    const sortedData = data.sort((a, b) => {
      const dateA = new Date(a.startDateTime || a.startDate);
      const dateB = new Date(b.startDateTime || b.startDate);
      return dateA - dateB;
    });

    setData(sortedData);
  }, [dashboardData, googleEvents]);

  // Calculate pagination
  const indexOfLastEvent = currentPage * ITEMS_PER_PAGE;
  const indexOfFirstEvent = indexOfLastEvent - ITEMS_PER_PAGE;
  const currentEvents = data?.slice(indexOfFirstEvent, indexOfLastEvent);
  const totalPages = Math.ceil((data?.length || 0) / ITEMS_PER_PAGE);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  return (
    <div className={className}>
      <div className="flex-column">
        <Card>
          <div className="flex flex-col md:flex-row justify-between border-b-1 items-center p-4">
            <div className="flex flex-wrap justify-center md:justify-start gap-2 w-full md:w-auto mb-4 md:mb-0">
              {EventViewType.map((item) => (
                <Button
                  key={item.id}
                  onClick={() => setSelectedViewType(item.name)}
                  variety={
                    item.name === selectedViewType ? "filled" : "outlined"
                  }
                  className="w-[45%] sm:w-auto"
                >
                  {item.name}
                </Button>
              ))}
            </div>

            <div className="flex flex-col sm:flex-row gap-4 w-full md:w-auto">
              <div className="w-full sm:w-auto">
                <GoogleCalendar
                  googleCalendarStatus={googleCalendarStatus}
                  refetchDashboardData={refetchDashboardData}
                  dashboardLoading={dashboardLoading}
                />
              </div>
              <Button
                className="flex-row mediumM w-full sm:w-auto"
                variety="filled"
                height={"48px"}
                onClick={handleCreate}
              >
                <AddPreIcon /> Create Event
              </Button>
            </div>
          </div>

          <div className="card-second-wrapper content-container">
            <div className="flex flex-col sm:flex-row justify-between items-center p-4">
              <div></div>
              {/* Search functionality */}
              <div className="relative flex items-center w-full sm:w-auto">
                {showSearch && (
                  <div className="relative w-[70%] sm:w-[250px] z-20">
                    <input
                      className="quick-add-task-container create-project-input search-input w-full"
                      style={{
                        background: "var(--App-Background)",
                      }}
                      placeholder="Search..."
                      value={searchText}
                      onChange={(e) => setSearchText(e.target.value)}
                    />
                  </div>
                )}
                <div
                  className={`quick-add-task-container align-center flex-row p-8 cursor-pointer z-10 flex items-center ${
                    showSearch ? "absolute right-2" : ""
                  }`}
                  onClick={() => setShowSearch(!showSearch)}
                >
                  <SearchOutlined />
                </div>
              </div>
            </div>

            {/* Main content */}
            <div className="m-8">
              {selectedViewType === "List View" ? (
                <div>
                  {dashboardLoading ? (
                    // Show skeleton loading
                    Array(3)
                      .fill(0)
                      .map((_, index) => <EventListSkeleton key={index} />)
                  ) : data?.length > 0 ? (
                    <>
                      {currentEvents.map((item, index) => (
                        <EventListViewComponent
                          key={item._id}
                          item={item}
                          title={item?.title}
                          date={moment(
                            item?.startDateTime || item?.startDate
                          ).format("MMM D YYYY")}
                          description={item?.eventDetails}
                          location={item?.location}
                          onClick={() => handleEventClick(item)}
                        />
                      ))}

                      <PaginationFooter
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPageChange={handlePageChange}
                      />
                    </>
                  ) : (
                    <div className="flex-col items-center justify-center py-16">
                      <EmptyCalendarIcon
                        size={96}
                        color="var(--Genesis-White)"
                      />
                      <h4 className="text-gray-400 mb-2">
                        No Events Scheduled
                      </h4>
                      <p className="text-gray-500 text-center mb-6 max-w-md">
                        Your calendar is clear. Start organizing your schedule
                        by creating your first event. You can add meetings,
                        reminders, or any important dates.
                      </p>
                      <Button
                        className="flex-row mediumM"
                        variety="filled"
                        height={"48px"}
                        onClick={handleCreate}
                      >
                        <AddPreIcon /> Create Your First Event
                      </Button>
                    </div>
                  )}
                </div>
              ) : (
                <Calendar
                  data={data}
                  deleteTask={handleDeleteEvent}
                  setIsModalOpen={setIsEventModalOpen}
                  isEdit={isEdit}
                  setIsEdit={setIsEdit}
                  setIsCreateModalOpen={setIsCreateModalOpen}
                  setModalTitle={setModalTitle}
                  selectedData={selectedData}
                  setSelectedData={setSelectedData}
                />
              )}
            </div>
          </div>
        </Card>
        {/* Modals */}
        {/* Event Details Modal */}
        <Modal
          isOpen={isEventModalOpen}
          onClose={() => {
            setIsEventModalOpen(false);
          }}
          title={modalTitle}
          handleDeleteTask={() => handleDeleteEvent()}
          isEditButton={true}
          onEditClick={() => {
            setIsEventModalOpen(false);
            setIsCreateModalOpen(true);
            setModalTitle("Edit Event");
            setIsEdit(true);
          }}
        >
          <EventModalViewDetails selectedEvent={selectedData} />
        </Modal>

        {/* Create Event Modal */}
        <CreateModal
          isOpen={isCreateModalOpen}
          onClose={() => {
            setIsCreateModalOpen(false);
            setIsEdit(false);
            reset();
          }}
          title={modalTitle}
        >
          <CreateEventForm
            onSubmit={onSubmit}
            control={control}
            errors={errors}
            register={register}
            setIsCreateModalOpen={setIsCreateModalOpen}
            defaultValues={selectedData}
            isEdit={isEdit}
            isLoading={isButtonLoading}
            setIsButtonLoading={setIsButtonLoading}
          />
        </CreateModal>

        {/* Delete Confirmation Dialog */}
        <Dialog
          show={confirmationDialog}
          action1ButtonLabel="Cancel"
          action2ButtonLabel="Delete"
          handelAction1={() => setConfirmationDialog(false)}
          handelAction2={handleDeleteConfirmation}
          isLoading={isButtonLoading}
        >
          <div className="flex-col justify-center align-middle">
            <div
              style={{
                background: "rgba(37, 31, 52, 1)",
                width: 100,
                height: 100,
                borderRadius: 100,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                position: "absolute",
                top: 20,
                left: "40%",
              }}
            >
              <DeleteOutlined size={72} />
            </div>
            <div className="pt-28">
              <h3 className="white text-align-center">
                You are about to delete an event
              </h3>
              <h5 className="white text-align-center">
                This will delete your event permanently.
              </h5>
            </div>
          </div>
        </Dialog>
      </div>
    </div>
  );
};

export default CalendarComponent;
