import "./App.css";

import React from "react";
import { RouterProvider } from "react-router-dom";
import router from "./Router";
import { USER_DETAILS } from "./utils/constants";
import { ToastContainer, toast } from "react-toastify";
import { SidebarProvider } from "./hooks/ui/IsSidebarOpen";

function App() {
  const isAuth = localStorage.getItem(USER_DETAILS);
  const userDetails = JSON.parse(isAuth);

  return (
    <SidebarProvider>
      <RouterProvider router={router} />

      <ToastContainer />
    </SidebarProvider>
  );
}

export default App;
