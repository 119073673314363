import { ChevronDownIcon, ChevronUpIcon } from "@radix-ui/react-icons";
import moment from "moment";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { AddPreIcon } from "../../assets/svg/AddPreIcon";
import { DeleteOutlined } from "../../assets/svg/DeleteOutlined";
import { MinusIcon, PlusIcon } from "../../assets/svg/PlusIcon";
import { PlusIconOutlined } from "../../assets/svg/PlusIconOutlined";
import { SearchOutlined } from "../../assets/svg/SearchOutliined";
import Button from "../../components/Button";
import Card from "../../components/Card/Card";
import { ListViewComponent } from "../../components/ListView/ListViewComponent";
import { DashboardTab, ViewType } from "../../constants/constant";
import { useDataQuery } from "../../hooks/crud/data.query";
import { useMutationCreate } from "../../hooks/crud/mutation.create";
import { useMutationDelete } from "../../hooks/crud/mutation.delete";
import { useMutationPatch } from "../../hooks/crud/mutation.patch";
import { addTaskToProject } from "../../services/taskService";
import { API_END_POINTS } from "../../utils/api-endpoint";
import Dialog from "../Dialog/Dialog";
import Modal, { CreateModal } from "../Modal/Modal";
import { ModalViewDetails } from "./ModalView";
import { QuickAddComponent } from "./QuickAddComponent";

import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { CreateProjectForm } from "../CreateProject/CreateProject";
import { priorityData } from "../CreateTask/constant";
import { CreateTaskForm } from "../CreateTask/CreateTask";
import { ProjectListViewComponent } from "../ListView/ProjectListViewComponent";
import FormSelect from "../Select/FormSelect";
import { notify } from "../Toast/ToastComponent";

import Dropdown from "../Dropdown/Dropdown";
import TimelineView, { viewOptions } from "../Timeline/TimelineView";
import { useLocation } from "react-router-dom";
import { reference } from "@popperjs/core";
import { BallTriangleLoader } from "../Loader/Loader";
import ListViewSkeleton from "../Skeleton/ListViewSkeleton";

const DashboardComponent = ({
  className,
  isEventTab,
  timelineData,
  isLoadingTimeline,
  googleCalendarStatus,
  ...props
}) => {
  const savedViewTypeRef = useRef(null);
  const location = useLocation();
  const [justUpdated, setJustUpdated] = useState(false);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [selectedTab, setSeletedTab] = useState("Tasks");
  const [showProjectTask, setShowProjectTask] = useState(false);
  const [selectedProjectIndex, setSelectedProjectIndex] = useState(-1);
  const isUpdatingRef = useRef(false);

  const [selectedViewType, setSelectedViewType] = useState(() => {
    if (!isUpdatingRef.current) {
      return localStorage.getItem("selectedViewType") || "List View";
    }
    return "Timeline View";
  });
  const [selectedProjectId, setSelectedProjectId] = useState("");
  const [data, setData] = useState();
  const [selectedData, setSelectedData] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [isEditButton, setIsEditButton] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedPriority, setSelectedPriority] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  const [confirmationDialog, setConfirmationDialog] = useState(false);

  const [timelineView, setTimelineView] = useState(viewOptions[0]);

  const [pendingTaskUpdate, setPendingTaskUpdate] = useState(null);

  const [isTimelineUpdate, setIsTimelineUpdate] = useState(false);

  const {
    handleSubmit,
    control,
    formState: { errors },
    register,
    reset,
  } = useForm({});

  const {
    data: dashboardData,
    refetch: refetchDashboardData,
    error: dashboardError,
    isLoading: isLoadingDashboard,
  } = useDataQuery(
    {
      url: API_END_POINTS.dashboard,
      params: {},
    },
    {
      enabled: selectedTab === "Tasks",
    }
  );

  const {
    data: tasksData,
    refetch: refetchTaskData,
    error: taskError,
  } = useDataQuery(
    {
      url: API_END_POINTS.tasks,
      params: {
        search: searchText,
      },
    },
    {
      enabled: !!searchText || selectedTab === "Tasks",
    }
  );

  const {
    data: projectData,
    refetch: refetchProjectData,
    error: projectError,
  } = useDataQuery(
    {
      url: API_END_POINTS.project,
      params: {},
    },
    {
      enabled: selectedTab === "Projects",
    }
  );

  const { data: userData } = useDataQuery(
    {
      url: API_END_POINTS.profile,
      params: {},
    },
    {
      enabled: false,
    }
  );

  const { data: projectCategoryData } = useDataQuery(
    {
      url: API_END_POINTS.projectCategory,
      params: {},
    },
    {
      enabled: selectedTab === "Projects",
    }
  );

  const { mutate: patchTask } = useMutationPatch(
    `${API_END_POINTS.tasks}/${selectedData?._id}`
  );

  const { mutate: patchTaskList } = useMutationPatch(`${API_END_POINTS.tasks}`);

  const { mutate: deleteTask } = useMutationDelete(
    API_END_POINTS.tasks,
    selectedData?._id
  );

  const { mutate: createTask } = useMutationCreate(API_END_POINTS.tasks);

  const { mutate: deleteProject } = useMutationDelete(
    API_END_POINTS.project,
    selectedData?._id
  );

  const { mutate: patchProjectList } = useMutationPatch(
    `${API_END_POINTS.project}`
  );

  const [modalContentType, setModalContentType] = useState(null);

  const handleTaskClick = (e) => {
    setIsModalOpen(true);
    setModalTitle("Task Details");
    setSelectedData(e);
    setModalContentType("Task");
  };

  const handleProjectClick = (e) => {
    // Ensure we have the complete project data with sorted tasks
    const projectWithSortedTasks = {
      ...e,
      tasksList: e.tasksList?.sort(
        (a, b) => (a.projectDisplayIndex || 0) - (b.projectDisplayIndex || 0)
      ),
    };

    setIsModalOpen(true);
    setModalTitle("Project Details");
    setSelectedData(projectWithSortedTasks);
    setIsEditButton(false);
    setModalContentType("Project");
  };

  const handleSelectedTab = (e) => {
    setSeletedTab(e.target.innerText);
  };

  const handleSelectedViewType = (e) => {
    setSelectedViewType(e.target.innerText);
  };

  const handleProjectTask = (index) => {
    setShowProjectTask(!showProjectTask);
    setSelectedProjectIndex((prevIndex) => {
      const newIndex = prevIndex === index ? -1 : index;
      // Sort tasks when expanding a project
      if (newIndex !== -1 && data[newIndex]?.tasksList) {
        const sortedData = [...data];
        sortedData[newIndex] = {
          ...sortedData[newIndex],
          tasksList: [...sortedData[newIndex].tasksList].sort(
            (a, b) =>
              (a.projectDisplayIndex || 0) - (b.projectDisplayIndex || 0)
          ),
        };
        setData(sortedData);
      }
      return newIndex;
    });
  };

  const completedTasks = (index) => {
    return data?.[index]?.tasksList?.filter((task) => task?.completed);
  };

  const completedFilteredTasks = (index) => {
    return filteredData?.[index]?.tasksList?.filter((task) => task?.completed);
  };

  const [isQuickAddOpen, setIsQuickAddOpen] = useState(false);

  const [tempTask, setTempTask] = useState(null);

  const handleCreateTask = (clickedIndex) => {
    if (isQuickAddOpen) return;

    setIsQuickAddOpen(true);
    if (selectedTab === "Tasks") {
      const newTask = {
        date: null,
        endDate: null,
        title: "",
        isEditing: true,
      };
      setTempTask(newTask);
    } else if (selectedTab === "Projects") {
      const currentProject = data?.[clickedIndex];
      const newTask = {
        date: null,
        endDate: null,
        title: "",
        isEditing: true,
        description: "",
        projectId: currentProject?._id,
      };
      setTempTask({ task: newTask, projectIndex: clickedIndex });
    }
  };

  const handleSaveTask = async () => {
    setIsQuickAddOpen(false);

    if (selectedTab === "Projects" && tempTask) {
      try {
        const response = await addTaskToProject({
          ...tempTask.task,
          projectId: data?.[tempTask.projectIndex]?._id,
        });
        notify("Task added successfully", "success");
        refetchProjectData();
        setTempTask(null);
        return response;
      } catch (err) {
        notify(err?.message || "Failed to add task", "error");
        console.log("err", err);
      }
    } else if (tempTask) {
      try {
        if (selectedProjectId) {
          await addTaskToProject({
            ...tempTask,
            projectId: selectedProjectId,
          });
          notify("Task added successfully", "success");
          refetchTaskData();
          setTempTask(null);
        } else {
          await createTask(tempTask, {
            onSuccess: () => {
              notify("Task added successfully", "success");
              refetchTaskData();
              setTempTask(null);
            },
            onError: (err) => {
              notify(err?.message || "Failed to create task", "error");
            },
          });
        }
      } catch (err) {
        notify(err?.message || "Failed to create task", "error");
        console.log("err", err);
      }
    }
  };

  const handleCancelTask = () => {
    setIsQuickAddOpen(false);
    setTempTask(null);
  };

  const handleEditTask = (index, title, description, date) => {
    if (selectedTab === "Projects") {
      const updatedData = data?.map((item, i) => {
        if (i === selectedProjectIndex && item?.tasksList) {
          const updatedTasks = [...item?.tasksList];
          updatedTasks[index].title = title;
          updatedTasks[index].description = description;
          updatedTasks[index].date = date;
          updatedTasks[index].startDate = date;
          updatedTasks[index].endDate = date;
        }
        return item;
      });

      setData(updatedData);
    } else {
      const updatedTasks = [...data];
      updatedTasks[index].title = title;
      updatedTasks[index].description = description;
      updatedTasks[index].date = date;
      updatedTasks[index].startDate = date;
      updatedTasks[index].endDate = date;
      setData(updatedTasks);
    }
  };

  const updateTask = (updatedData) => {
    const { _id, ...patchData } = updatedData;
    patchTask(patchData, {
      onSuccess(res) {
        if (selectedViewType === "List View") {
          setJustUpdated(true);
        }
        setIsModalOpen(false);
        refetchDashboardData();
        notify("Task updated successfully", "success");
      },
      onError: (err) => {
        notify(err?.error?.message, "error");
      },
    });
  };

  const handleMarkAsComplete = () => {
    const updatedData = {
      ...selectedData,
      completed: true,
      status: "COMPLETED",
    };

    patchTask(updatedData, {
      onSuccess(res) {
        setJustUpdated(true);
        setIsModalOpen(false);
        refetchDashboardData();
        notify(
          `${selectedTab === "Tasks" ? "Task" : "Project"} marked as complete`,
          "success"
        );
      },
      onError: (err) => {
        notify(err?.error?.message, "error");
      },
    });
  };

  const handleSelectProject = (value) => {
    setSelectedProjectId(value);
  };

  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);

  const overdueTasks = data?.filter(
    (item) =>
      item?.endDate &&
      new Date(item?.endDate) < currentDate &&
      !item?.completed &&
      item?.status !== "COMPLETED"
  );
  const upcomingTasks = data?.filter((item) => {
    if (!item?.endDate) return false;
    const taskDate = new Date(item?.endDate);
    taskDate.setHours(0, 0, 0, 0);
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return (
      taskDate.getTime() > today.getTime() &&
      !item?.completed &&
      item?.status !== "COMPLETED"
    );
  });

  const completedTasksData = data?.filter(
    (item) => !item?.isEditing && item?.completed
  );

  const handleCreate = () => {
    if (selectedTab === "Projects") {
      setIsCreateModalOpen(true);
      setModalTitle("Create Project");
      setModalContentType("Project");
      setIsEdit(false);
      setSelectedData({});
    } else if (selectedTab === "Tasks") {
      setIsCreateModalOpen(true);
      setModalTitle("Create Task");
      setModalContentType("Task");
      setSelectedData({});
      setIsEdit(false);
    }
  };

  const handleDeleteTask = () => {
    setConfirmationDialog(true);
  };
  const handleDeleteProject = () => {
    setConfirmationDialog(true);
  };

  const handleDeleteConfirmationTask = () => {
    setIsButtonLoading(true);
    if (modalContentType === "Task") {
      deleteTask(
        {},
        {
          onSuccess(res) {
            setIsModalOpen(false);
            setJustUpdated(true);
            refetchDashboardData();
            setConfirmationDialog(false);
            setIsButtonLoading(false);
            notify("Task deleted successfully", "success");
          },
        }
      );
    } else if (modalContentType === "Project") {
      deleteProject(
        {},
        {
          onSuccess(res) {
            setIsModalOpen(false);
            setJustUpdated(true);
            refetchProjectData();
            setConfirmationDialog(false);
            setIsButtonLoading(false);
            notify("Project deleted successfully", "success");
          },
        }
      );
    }
  };

  const handleTaskUpdate = (task, newDates) => {
    if (!task._id) {
      notify("Error: Task ID is missing", "error");
      return;
    }

    savedViewTypeRef.current = selectedViewType;
    setSelectedData(task);
    setPendingTaskUpdate({ task, newDates });
  };

  const TIMELINE_SUPPORTED_TABS = ["Projects", "Tasks"];

  useLayoutEffect(() => {
    if (savedViewTypeRef.current) {
      if (TIMELINE_SUPPORTED_TABS.includes(selectedTab)) {
        setSelectedViewType(savedViewTypeRef.current);
      } else {
        setSelectedViewType("List View");
        savedViewTypeRef.current = null;
      }
    }
  }, [projectData, selectedTab]);

  const refetchTimeoutRef = useRef(null);

  useEffect(() => {
    if (pendingTaskUpdate && selectedData?._id) {
      const updatedTask = {
        startDate: pendingTaskUpdate.newDates.startDate,
        endDate: pendingTaskUpdate.newDates.endDate,
      };

      patchTask(updatedTask, {
        onSuccess(res) {
          notify("Task dates updated successfully", "success");
          
          if (refetchTimeoutRef.current) {
            clearTimeout(refetchTimeoutRef.current);
          }

          if (pendingTaskUpdate.newDates.delayRefetch) {
            refetchTimeoutRef.current = setTimeout(() => {
              refetchProjectData();
              refetchTaskData();
            }, 2000);
          } else {
            refetchProjectData();
            refetchTaskData();
          }
          
          setPendingTaskUpdate(null);
        },
        onError: (err) => {
          notify(err?.error?.message || "Failed to update task dates", "error");
          savedViewTypeRef.current = null;
          setPendingTaskUpdate(null);
        },
      });
    }
  }, [selectedData, pendingTaskUpdate]);

  useEffect(() => {
    if (
      savedViewTypeRef.current &&
      TIMELINE_SUPPORTED_TABS.includes(selectedTab)
    ) {
      return;
    }

    if (justUpdated) {
      if (selectedTab === "Tasks") {
        setData(tasksData?.data);
      } else if (selectedTab === "Projects") {
        setData(projectData?.data);
      }
    } else {
      if (selectedTab === "Tasks") {
        setData(tasksData?.data);
      } else if (selectedTab === "Projects") {
        setData(projectData?.data);
      }
    }
  }, [
    dashboardData,
    selectedTab,
    projectData,
    justUpdated,
    tasksData,
    searchText,
  ]);

  useEffect(() => {
    if (projectError) {
      notify(projectError?.error?.message, "error");
    } else if (dashboardError) {
      notify(dashboardError?.error?.message, "error");
    }
  }, [projectError, dashboardError]);

  const isProjectCompleted = (project) => {
    return project.tasksList.every((task) => task.completed);
  };

  useEffect(() => {
    if (selectedTab === "Tasks") {
      let filteredTasks = dashboardData?.data?.tasks;

      if (selectedStatus) {
        filteredTasks = filteredTasks?.filter(
          (item) => item?.status === selectedStatus
        );
      }

      if (selectedPriority?.toUpperCase()) {
        filteredTasks = filteredTasks?.filter(
          (item) => item?.priority === selectedPriority?.toUpperCase()
        );
      }

      if (selectedStatus || selectedPriority) {
        setFilteredData(filteredTasks);
      } else {
        setFilteredData([]);
      }
    } else if (selectedTab === "Projects") {
      if (selectedStatus === "Completed") {
        setFilteredData(projectData?.data?.filter(isProjectCompleted));
      } else if (selectedStatus === "Incomplete") {
        setFilteredData(
          projectData?.data?.filter((project) => !isProjectCompleted(project))
        );
      } else {
        setFilteredData([]);
      }
    }
  }, [
    selectedStatus,
    selectedPriority,
    selectedTab,
    projectData,
    dashboardData,
  ]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        setIsModalOpen(false);
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const handleStatusChange = (value) => {
    const { _id, ...patchData } = selectedData;
    const updatedTask = { ...patchData, status: value };

    patchTask(updatedTask, {
      onSuccess(res) {
        notify("Task status updated successfully", "success");
      },
      onError: (err) => {
        notify(err?.error?.message, "error");
      },
    });
  };

  const handleUpdateTitle = (updatedTitle, taskId) => {
    setData((prevTasks) =>
      prevTasks.map((task) =>
        task._id === taskId ? { ...task, title: updatedTitle } : task
      )
    );
  };

  useEffect(() => {
    return () => console.log("Component unmounting");
  }, []);

  const setSelectedViewTypeWithDebug = (newValue) => {
    setSelectedViewType(newValue);
  };

  useEffect(() => {
    if (pendingTaskUpdate === null && isTimelineUpdate) {
      setIsTimelineUpdate(false);
    }
  }, [pendingTaskUpdate, isTimelineUpdate]);

  useEffect(() => {
    return () => {
      savedViewTypeRef.current = null;
    };
  }, []);

  useEffect(() => {
    if (
      !TIMELINE_SUPPORTED_TABS.includes(selectedTab) &&
      selectedViewType === "Timeline View"
    ) {
      setSelectedViewType("List View");
      savedViewTypeRef.current = null;
    }
  }, [selectedTab]);

  const todayTasks = data?.filter((item) => {
    if (!item?.endDate) return false;
    const taskDate = new Date(item?.endDate);
    taskDate.setHours(0, 0, 0, 0);
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return (
      taskDate.getTime() === today.getTime() &&
      !item?.completed &&
      item?.status !== "COMPLETED"
    );
  });

  const handleCreateTaskFromProject = (projectId) => {
    setSelectedProjectId(projectId);
    setIsCreateModalOpen(true);
    setModalTitle("Create Task");
    setModalContentType("Task");
  };

  const handleViewProject = (project) => {
    setSelectedData(project);
    setIsModalOpen(true);
    setModalTitle("Project Details");
    setModalContentType("Project");
  };

  const [showTasksType, setShowTasksType] = useState({
    today: true,
    overdue: false,
    upcoming: false,
    completed: false,
    toBeScheduled: false,
  });

  useEffect(() => {
    if (todayTasks?.length > 0) {
      setShowTasksType((prev) => ({
        ...prev,
        today: true,
      }));
    }
  }, []);

  const toBeScheduledTasks = data?.filter((task) => {
    const hasNoEndDate =
      !task?.endDate || isNaN(new Date(task?.endDate).getTime());
    const isNotCompleted = !task?.completed && task?.status !== "COMPLETED";
    return hasNoEndDate && isNotCompleted;
  });

  const onDragEnd = (result) => {
    if (!result.destination) return;

    // Add handling for filtered tasks
    if (result.source.droppableId === "filteredTasks") {
      const items = Array.from(filteredData);
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);

      // Update indices
      const updatedItems = items.map((task, index) => ({
        _id: task._id,
        taskDisplayIndex: index,
      }));

      setFilteredData(items);

      // Send update to backend
      patchTaskList(updatedItems, {
        onSuccess: () => {
          notify("Task order updated successfully", "success");
          refetchTaskData();
        },
        onError: (err) => {
          notify(err?.error?.message || "Failed to update task order", "error");
          setFilteredData(filteredData); // Revert on error
        },
      });
      return;
    }

    // Handle project tasks reordering from modal or main view
    if (result.source.droppableId === "projectTasks") {
      let projectToUpdate;
      let updatedProject;

      if (result.updatedProject) {
        // Coming from modal
        updatedProject = result.updatedProject;
        projectToUpdate = updatedProject;
      } else {
        // Coming from main view
        const projectIndex = selectedProjectIndex;
        const project = data[projectIndex];

        if (!project?.tasksList) return;

        // Sort and reorder tasks
        const sortedTasks = [...project.tasksList].sort(
          (a, b) => (a.projectDisplayIndex || 0) - (b.projectDisplayIndex || 0)
        );
        const [removed] = sortedTasks.splice(result.source.index, 1);
        sortedTasks.splice(result.destination.index, 0, removed);

        // Update indices
        const updatedTasks = sortedTasks.map((task, index) => ({
          ...task,
          projectDisplayIndex: index,
        }));

        updatedProject = {
          ...project,
          tasksList: updatedTasks,
        };
        projectToUpdate = updatedProject;
      }

      // Update local state
      const newData = data.map((project) =>
        project._id === projectToUpdate._id ? projectToUpdate : project
      );
      setData(newData);

      // Send update to backend
      patchProjectList([projectToUpdate], {
        onSuccess: () => {
          notify("Task order updated successfully", "success");
          refetchProjectData();
        },
        onError: (err) => {
          notify(err?.error?.message || "Failed to update task order", "error");
          setData(data); // Revert on error
        },
      });
      return;
    }

    // Handle task list reordering
    let tasksToReorder;
    switch (result.source.droppableId) {
      case "todayTasks":
        tasksToReorder = todayTasks?.sort(
          (a, b) => (a.taskDisplayIndex || 0) - (b.taskDisplayIndex || 0)
        );
        break;
      case "upcomingTasks":
        tasksToReorder = upcomingTasks?.sort(
          (a, b) => (a.taskDisplayIndex || 0) - (b.taskDisplayIndex || 0)
        );
        break;
      case "overdueTasks":
        tasksToReorder = overdueTasks?.sort(
          (a, b) => (a.taskDisplayIndex || 0) - (b.taskDisplayIndex || 0)
        );
        break;
      case "completedTasks":
        tasksToReorder = data
          ?.filter((task) => task.completed || task.status === "COMPLETED")
          .sort(
            (a, b) => (a.taskDisplayIndex || 0) - (b.taskDisplayIndex || 0)
          );
        break;
      case "toBeScheduledTasks":
        tasksToReorder = toBeScheduledTasks?.sort(
          (a, b) => (a.taskDisplayIndex || 0) - (b.taskDisplayIndex || 0)
        );
        break;
      default:
        return;
    }

    const newTasksArray = Array.from(tasksToReorder || []);
    const [removed] = newTasksArray.splice(result.source.index, 1);
    newTasksArray.splice(result.destination.index, 0, removed);

    // Create minimal update payload with only _id and taskDisplayIndex
    const tasksToUpdate = newTasksArray.map((task, index) => ({
      _id: task._id,
      taskDisplayIndex: index,
    }));

    // Update local state
    const newData = data.map((task) => {
      const updatedTask = tasksToUpdate.find((t) => t._id === task._id);
      return updatedTask
        ? { ...task, taskDisplayIndex: updatedTask.taskDisplayIndex }
        : task;
    });
    setData(newData);

    // Send minimal payload to backend
    patchTaskList(tasksToUpdate, {
      onSuccess: () => {
        notify("Task order updated successfully", "success");
        refetchTaskData();
      },
      onError: (err) => {
        notify(err?.error?.message || "Failed to update task order", "error");
        setData(data);
      },
    });
  };

  useEffect(() => {
    // Only set initial view type if it hasn't been set yet
    if (!selectedViewType) {
      switch (selectedTab) {
        case "Tasks":
        case "Projects":
          setSelectedViewType("List View");
          break;
      }
    }
  }, []); // Empty dependency array means it only runs once on mount

  const renderViewTypeButtons = () => {
    const viewTypes = ViewType;

    return viewTypes.map((item) => (
      <Button
        key={item.id}
        onClick={handleSelectedViewType}
        variety={item.name === selectedViewType ? "filled" : "outlined"}
        className="w-[45%] sm:w-auto"
      >
        {item.name}
      </Button>
    ));
  };

  useEffect(() => {
    if (location.state) {
      const { selectedTab, selectedProject, selectedTask } = location.state;

      // Set the selected tab
      if (selectedTab) {
        setSeletedTab(selectedTab);
      }

      // If a project was passed, select it
      if (selectedProject) {
        const projectIndex = data?.findIndex(
          (p) => p._id === selectedProject._id
        );
        if (projectIndex !== -1) {
          setSelectedProjectIndex(projectIndex);
          setShowProjectTask(true);
        }
      }

      // If a task was passed, you might want to scroll to it or highlight it
      if (selectedTask) {
        // Add your logic to handle the selected task
      }
    }
  }, [location.state]);

  useEffect(() => {
    if (selectedViewType === "List View") {
      if (selectedTab === "Projects") {
        setData(projectData?.data);
      } else if (selectedTab === "Tasks") {
        setData(tasksData?.data);
      }
    }
  }, [selectedTab, selectedViewType, projectData?.data, tasksData?.data]);

  return (
    <div className={className}>
      <div className="flex-column">
        <Card>
          <div className="flex flex-col md:flex-row justify-between border-b-1 items-center p-4">
            <div
              className={`flex flex-col sm:flex-row justify-between ${
                isEventTab ? "w-full md:w-1/3" : "w-full md:w-auto"
              } gap-2 sm:gap-4`}
            >
              {DashboardTab?.map((item, index) => (
                <div
                  key={index}
                  className={`cursor-pointer card-tab w-full md:w-[150px] text-center ${
                    item?.tabName === selectedTab && "card-tab-selected"
                  }`}
                  onClick={handleSelectedTab}
                >
                  <span className="white regularS whitespace-nowrap">
                    {item?.tabName}
                  </span>
                </div>
              ))}
            </div>

            <div className="flex-row justify-end items-center w-full md:w-auto mt-4 md:mt-0">
              <Button
                className="flex-row mediumM w-full md:w-auto"
                variety="filled"
                height={"48px"}
                onClick={handleCreate}
              >
                <AddPreIcon /> Create{" "}
                {selectedTab === "Projects" ? "Project" : "Task"}
              </Button>
            </div>
          </div>
          <div className="card-second-wrapper content-container">
            <div className="flex flex-col lg:flex-row justify-between items-center gap-4 p-4">
              <div className="flex flex-wrap justify-center lg:justify-start gap-2 w-full lg:w-auto">
                {renderViewTypeButtons()}
              </div>

              <div className="flex flex-col sm:flex-row items-center gap-4 w-full lg:w-auto">
                {selectedViewType !== "Timeline View" && (
                  <div className="relative flex items-center w-full sm:w-auto">
                    {showSearch && (
                      <div className="relative w-[70%] sm:w-[70%] z-20">
                        <Controller
                          name={"search"}
                          control={control}
                          render={({ field }) => (
                            <input
                              className="quick-add-task-container create-project-input search-input w-full"
                              style={{
                                background: "var(--App-Background)",
                              }}
                              placeholder="Search..."
                              value={searchText}
                              onChange={(e) => setSearchText(e.target.value)}
                            />
                          )}
                        />
                      </div>
                    )}
                    <div
                      className={`quick-add-task-container align-center flex-row p-8 cursor-pointer z-10 flex items-center ${
                        showSearch ? "absolute right-2" : ""
                      }`}
                      onClick={() => setShowSearch(!showSearch)}
                    >
                      <SearchOutlined />
                    </div>
                  </div>
                )}

                {selectedTab === "Tasks" &&
                  selectedViewType === "List View" && (
                    <div className="flex flex-col sm:flex-row gap-4 w-full sm:w-auto">
                      <div className="w-full sm:w-[200px]">
                        <FormSelect
                          control={control}
                          label="Priority"
                          id="priority"
                          options={priorityData}
                          onChange={(e) => {
                            if (e) {
                              setSelectedPriority(e.value);
                            } else {
                              setSelectedPriority("");
                            }
                          }}
                          name="priority"
                          placeholder="Priority"
                          isClearable
                          className="!mt-0"
                        />
                      </div>
                      <div className="w-full sm:w-[200px]">
                        <FormSelect
                          control={control}
                          label="Status"
                          id="status"
                          options={[
                            { label: "To Do", value: "TODO" },
                            { label: "In Progress", value: "IN_PROGRESS" },
                            { label: "Blocked", value: "BLOCKED" },
                            { label: "Completed", value: "COMPLETED" },
                          ]}
                          onChange={(e) => {
                            if (e) {
                              setSelectedStatus(e.value);
                            } else {
                              setSelectedStatus("");
                            }
                          }}
                          name="status"
                          placeholder="Status"
                          isClearable
                          className="!mt-0"
                        />
                      </div>
                    </div>
                  )}

                {selectedViewType === "Timeline View" && (
                  <div className="w-full sm:w-32">
                    <Dropdown
                      options={viewOptions}
                      onOptionChange={setTimelineView}
                      initialOption={viewOptions[0]}
                      className="w-full"
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="m-8">
              {selectedViewType === "List View" && selectedTab === "Tasks" && (
                <>
                  {!isQuickAddOpen && (
                    <div
                      className="cursor-pointer p-12 flex-row align-center mb-4 hover:bg-gray-800 rounded-md"
                      onClick={() => handleCreateTask()}
                    >
                      <PlusIconOutlined />
                      <span className="white">Create Task</span>
                    </div>
                  )}

                  {isQuickAddOpen && (
                    <QuickAddComponent
                      item={tempTask}
                      onSaveProject={handleSaveTask}
                      handleEditTask={(index, title, description, date) => {
                        setTempTask((prev) => ({
                          ...prev,
                          title,
                          description,
                          endDate: date,
                        }));
                      }}
                      projectData={projectData}
                      handleSelectProject={handleSelectProject}
                      handleCancelTask={handleCancelTask}
                    />
                  )}

                  <div className="task-list-container">
                    {isLoadingDashboard ? (
                      // Show multiple skeleton items while loading
                      Array(5)
                        .fill(0)
                        .map((_, index) => <ListViewSkeleton key={index} />)
                    ) : selectedStatus || selectedPriority ? (
                      <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="filteredTasks">
                          {(provided) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.droppableProps}
                            >
                              {filteredData?.map((item, index) => (
                                <ListViewComponent
                                  key={item._id}
                                  task={item}
                                  index={index}
                                  isDraggable={true}
                                  onClick={(e) => handleTaskClick(item)}
                                  date={moment(item?.endDate).format(
                                    "MMM D YYYY"
                                  )}
                                  title={item?.title}
                                  completed={item?.completed}
                                  status={item?.status}
                                  duration={item}
                                  onClickComplete={async () => {
                                    await setSelectedData(item);
                                    handleMarkAsComplete();
                                  }}
                                  setData={setData}
                                  data={data}
                                  setSelectedData={setSelectedData}
                                  selectedData={selectedData}
                                  onUpdateTitle={(newTitle) =>
                                    handleUpdateTitle(newTitle, item._id)
                                  }
                                  setFilteredData={setData}
                                  projectData={projectData?.data}
                                />
                              ))}
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                      </DragDropContext>
                    ) : (
                      <>
                        {todayTasks?.length > 0 && (
                          <>
                            <span
                              onClick={() =>
                                setShowTasksType({
                                  ...showTasksType,
                                  today: !showTasksType?.today,
                                })
                              }
                              className="boldL white cursor-pointer flex align-center"
                            >
                              {showTasksType?.today ? (
                                <ChevronDownIcon />
                              ) : (
                                <ChevronUpIcon />
                              )}
                              <span className="p-4">
                                Today - {moment().format("MMM D")} (
                                {todayTasks?.length})
                              </span>
                            </span>
                            {showTasksType?.today && (
                              <DragDropContext onDragEnd={onDragEnd}>
                                <Droppable droppableId="todayTasks">
                                  {(provided) => (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.droppableProps}
                                    >
                                      {isLoadingDashboard
                                        ? Array(3)
                                            .fill(0)
                                            .map((_, index) => (
                                              <ListViewSkeleton
                                                key={`today-skeleton-${index}`}
                                              />
                                            ))
                                        : todayTasks?.map((item, index) => (
                                            <ListViewComponent
                                              key={item._id}
                                              task={item}
                                              index={index}
                                              isDraggable={true}
                                              onClick={(e) =>
                                                handleTaskClick(item)
                                              }
                                              date={moment(
                                                item?.endDate
                                              ).format("MMM D YYYY")}
                                              title={item?.title}
                                              completed={item?.completed}
                                              status={item?.status}
                                              duration={item}
                                              onClickComplete={async () => {
                                                await setSelectedData(item);
                                                handleMarkAsComplete();
                                              }}
                                              setData={setData}
                                              data={data}
                                              setSelectedData={setSelectedData}
                                              selectedData={selectedData}
                                              onUpdateTitle={(newTitle) =>
                                                handleUpdateTitle(
                                                  newTitle,
                                                  item._id
                                                )
                                              }
                                              setFilteredData={setData}
                                              projectData={projectData?.data}
                                            />
                                          ))}
                                      {provided.placeholder}
                                    </div>
                                  )}
                                </Droppable>
                              </DragDropContext>
                            )}
                          </>
                        )}

                        {overdueTasks?.length > 0 && (
                          <>
                            <span
                              onClick={() =>
                                setShowTasksType({
                                  ...showTasksType,
                                  overdue: !showTasksType?.overdue,
                                })
                              }
                              className="boldL white cursor-pointer flex align-center"
                            >
                              {showTasksType?.overdue ? (
                                <ChevronDownIcon />
                              ) : (
                                <ChevronUpIcon />
                              )}
                              <span className="p-4">
                                Overdue Tasks ({overdueTasks?.length})
                              </span>
                            </span>
                            {showTasksType?.overdue && (
                              <DragDropContext onDragEnd={onDragEnd}>
                                <Droppable droppableId="overdueTasks">
                                  {(provided) => (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.droppableProps}
                                    >
                                      {isLoadingDashboard
                                        ? Array(2)
                                            .fill(0)
                                            .map((_, index) => (
                                              <ListViewSkeleton
                                                key={`overdue-skeleton-${index}`}
                                              />
                                            ))
                                        : overdueTasks
                                            ?.filter(
                                              (filterData) =>
                                                !filterData?.completed
                                            )
                                            ?.map((item, index) => (
                                              <ListViewComponent
                                                key={item._id}
                                                task={item}
                                                index={index}
                                                isDraggable={true}
                                                onClick={(e) =>
                                                  handleTaskClick(item)
                                                }
                                                date={moment(
                                                  item?.endDate
                                                ).format("MMM D YYYY")}
                                                title={item?.title}
                                                completed={item?.completed}
                                                status={item?.status}
                                                duration={item}
                                                onClickComplete={async () => {
                                                  await setSelectedData(item);
                                                  handleMarkAsComplete();
                                                }}
                                                setData={setData}
                                                data={data}
                                                setSelectedData={
                                                  setSelectedData
                                                }
                                                selectedData={selectedData}
                                                onUpdateTitle={(newTitle) =>
                                                  handleUpdateTitle(
                                                    newTitle,
                                                    item._id
                                                  )
                                                }
                                                setFilteredData={setData}
                                                projectData={projectData?.data}
                                              />
                                            ))}
                                      {provided.placeholder}
                                    </div>
                                  )}
                                </Droppable>
                              </DragDropContext>
                            )}
                          </>
                        )}

                        {upcomingTasks?.length > 0 && (
                          <>
                            <span
                              onClick={() =>
                                setShowTasksType({
                                  ...showTasksType,
                                  upcoming: !showTasksType?.upcoming,
                                })
                              }
                              className="boldL white cursor-pointer flex align-center"
                            >
                              {showTasksType?.upcoming ? (
                                <ChevronDownIcon />
                              ) : (
                                <ChevronUpIcon />
                              )}
                              <span className="p-4">
                                Upcoming Tasks (
                                {
                                  upcomingTasks?.filter(
                                    (filterData) => !filterData?.completed
                                  )?.length
                                }
                                )
                              </span>
                            </span>
                            {showTasksType?.upcoming && (
                              <DragDropContext onDragEnd={onDragEnd}>
                                <Droppable droppableId="upcomingTasks">
                                  {(provided) => (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.droppableProps}
                                    >
                                      {isLoadingDashboard
                                        ? Array(3)
                                            .fill(0)
                                            .map((_, index) => (
                                              <ListViewSkeleton
                                                key={`upcoming-skeleton-${index}`}
                                              />
                                            ))
                                        : upcomingTasks
                                            ?.filter(
                                              (filterData) =>
                                                !filterData?.completed
                                            )
                                            .map((item, index) => (
                                              <ListViewComponent
                                                key={item._id}
                                                task={item}
                                                index={index}
                                                isDraggable={true}
                                                onClick={(e) =>
                                                  handleTaskClick(item)
                                                }
                                                date={moment(
                                                  item?.endDate
                                                ).format("MMM D YYYY")}
                                                title={item?.title}
                                                completed={item?.completed}
                                                status={item?.status}
                                                duration={item}
                                                onClickComplete={async () => {
                                                  await setSelectedData(item);
                                                  handleMarkAsComplete();
                                                }}
                                                setData={setData}
                                                data={data}
                                                setSelectedData={
                                                  setSelectedData
                                                }
                                                selectedData={selectedData}
                                                onUpdateTitle={(newTitle) =>
                                                  handleUpdateTitle(
                                                    newTitle,
                                                    item._id
                                                  )
                                                }
                                                setFilteredData={setData}
                                                projectData={projectData?.data}
                                              />
                                            ))}
                                      {provided.placeholder}
                                    </div>
                                  )}
                                </Droppable>
                              </DragDropContext>
                            )}
                          </>
                        )}

                        {toBeScheduledTasks?.length > 0 && (
                          <>
                            <span
                              onClick={() =>
                                setShowTasksType({
                                  ...showTasksType,
                                  toBeScheduled: !showTasksType?.toBeScheduled,
                                })
                              }
                              className="boldL white cursor-pointer flex align-center"
                            >
                              {showTasksType?.toBeScheduled ? (
                                <ChevronDownIcon />
                              ) : (
                                <ChevronUpIcon />
                              )}
                              <span className="p-4">
                                To Be Scheduled (
                                {toBeScheduledTasks?.length || 0})
                              </span>
                            </span>
                            {showTasksType?.toBeScheduled && (
                              <DragDropContext onDragEnd={onDragEnd}>
                                <Droppable droppableId="toBeScheduledTasks">
                                  {(provided) => (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.droppableProps}
                                    >
                                      {isLoadingDashboard
                                        ? Array(3)
                                            .fill(0)
                                            .map((_, index) => (
                                              <ListViewSkeleton
                                                key={`toBeScheduled-skeleton-${index}`}
                                              />
                                            ))
                                        : toBeScheduledTasks?.map(
                                            (item, index) => (
                                              <ListViewComponent
                                                key={item._id}
                                                task={item}
                                                index={index}
                                                isDraggable={true}
                                                onClick={(e) =>
                                                  handleTaskClick(item)
                                                }
                                                date={
                                                  item?.endDate
                                                    ? moment(
                                                        item?.endDate
                                                      ).format("MMM D YYYY")
                                                    : "No due date"
                                                }
                                                title={item?.title}
                                                completed={item?.completed}
                                                status={item?.status}
                                                duration={item}
                                                onClickComplete={async () => {
                                                  await setSelectedData(item);
                                                  handleMarkAsComplete();
                                                }}
                                                setData={setData}
                                                data={data}
                                                setSelectedData={
                                                  setSelectedData
                                                }
                                                selectedData={selectedData}
                                                onUpdateTitle={(newTitle) =>
                                                  handleUpdateTitle(
                                                    newTitle,
                                                    item._id
                                                  )
                                                }
                                                setFilteredData={setData}
                                                projectData={projectData?.data}
                                              />
                                            )
                                          )}
                                      {provided.placeholder}
                                    </div>
                                  )}
                                </Droppable>
                              </DragDropContext>
                            )}
                          </>
                        )}

                        {data?.filter(
                          (task) =>
                            task.completed || task.status === "COMPLETED"
                        )?.length > 0 && (
                          <>
                            <span
                              onClick={() =>
                                setShowTasksType({
                                  ...showTasksType,
                                  completed: !showTasksType?.completed,
                                })
                              }
                              className="boldL white cursor-pointer flex align-center"
                            >
                              {showTasksType?.completed ? (
                                <ChevronDownIcon />
                              ) : (
                                <ChevronUpIcon />
                              )}
                              <span className="p-4">
                                Completed Tasks (
                                {data?.filter(
                                  (task) =>
                                    task.completed ||
                                    task.status === "COMPLETED"
                                )?.length || 0}
                                )
                              </span>
                            </span>
                            {showTasksType?.completed && (
                              <DragDropContext onDragEnd={onDragEnd}>
                                <Droppable droppableId="completedTasks">
                                  {(provided) => (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.droppableProps}
                                    >
                                      {isLoadingDashboard
                                        ? Array(3)
                                            .fill(0)
                                            .map((_, index) => (
                                              <ListViewSkeleton
                                                key={`completed-skeleton-${index}`}
                                              />
                                            ))
                                        : data
                                            ?.filter(
                                              (task) =>
                                                task.completed ||
                                                task.status === "COMPLETED"
                                            )
                                            ?.map((item, index) => (
                                              <ListViewComponent
                                                key={item._id}
                                                task={item}
                                                index={index}
                                                isDraggable={true}
                                                onClick={(e) =>
                                                  handleTaskClick(item)
                                                }
                                                date={moment(
                                                  item?.endDate
                                                ).format("MMM D YYYY")}
                                                title={item?.title}
                                                completed={item?.completed}
                                                status={item?.status}
                                                duration={item}
                                                onClickComplete={async () => {
                                                  await setSelectedData(item);
                                                  handleMarkAsComplete();
                                                }}
                                                setData={setData}
                                                data={data}
                                                setSelectedData={
                                                  setSelectedData
                                                }
                                                selectedData={selectedData}
                                                onUpdateTitle={(newTitle) =>
                                                  handleUpdateTitle(
                                                    newTitle,
                                                    item._id
                                                  )
                                                }
                                                setFilteredData={setData}
                                                projectData={projectData?.data}
                                              />
                                            ))}
                                      {provided.placeholder}
                                    </div>
                                  )}
                                </Droppable>
                              </DragDropContext>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </div>
                </>
              )}
              {selectedViewType === "List View" &&
              selectedTab === "Tasks" ? null : selectedViewType ===
                  "List View" && selectedTab === "Projects" ? (
                selectedStatus ? (
                  <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="projectTasks">
                      {/* Projects > List View */}
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                        >
                          {filteredData?.map((item, index) => {
                            return (
                              <div
                                className={`content-container ${
                                  selectedTab === "Projects" ? "active" : ""
                                }`}
                                key={item._id}
                              >
                                <div className="flex-row align-center cursor-pointer justify-space-between">
                                  <div
                                    className="flex-row align-center cursor-pointer"
                                    // onClick={() => handleProjectTask(index)}
                                  >
                                    {" "}
                                    <div
                                      className="align-center"
                                      onClick={() => handleProjectTask(index)}
                                    >
                                      {index === selectedProjectIndex ? (
                                        <MinusIcon />
                                      ) : (
                                        <PlusIcon />
                                      )}
                                    </div>
                                    <span
                                      className="white p-8 boldM"
                                      onClick={(e) => handleProjectClick(item)}
                                    >
                                      {item?.title}
                                    </span>
                                  </div>
                                  <span className="green-400 regularS">
                                    Status:
                                    {completedFilteredTasks(index)?.length}/
                                    {filteredData?.[index].tasksList?.length}
                                  </span>
                                </div>
                                {index === selectedProjectIndex && (
                                  <>
                                    {item?.tasksList?.map((task, taskIndex) => {
                                      if (task?.isEditing) {
                                        return (
                                          <QuickAddComponent
                                            key={task._id}
                                            onSaveProject={() =>
                                              handleSaveTask(index)
                                            }
                                            handleEditTask={handleEditTask}
                                            item={task}
                                            index={taskIndex}
                                            projectData={projectData}
                                            handleSelectProject={
                                              handleSelectProject
                                            }
                                            handleCancelTask={() =>
                                              handleCancelTask(index)
                                            }
                                          />
                                        );
                                      } else {
                                        return (
                                          <ListViewComponent
                                            key={task._id}
                                            task={task}
                                            index={taskIndex}
                                            isDraggable={true}
                                            onClick={(e) =>
                                              handleTaskClick(task)
                                            }
                                            date={moment(task?.endDate).format(
                                              "MMM D YYYY"
                                            )}
                                            title={task?.title}
                                            completed={task?.completed}
                                            status={task?.status}
                                            duration={task}
                                            onClickComplete={async () => {
                                              await setSelectedData(task);
                                              handleMarkAsComplete();
                                            }}
                                            setData={setData}
                                            data={data}
                                            setSelectedData={setSelectedData}
                                            selectedData={selectedData}
                                            onUpdateTitle={(newTitle) =>
                                              handleUpdateTitle(
                                                newTitle,
                                                task._id
                                              )
                                            }
                                            setFilteredData={setData}
                                            projectData={projectData?.data}
                                          />
                                        );
                                      }
                                    })}
                                    {provided.placeholder}
                                    {/* Quick add button */}
                                    {!isQuickAddOpen && (
                                      <div
                                        className="cursor-pointer p-12 flex-row align-center mb-4 hover:bg-gray-800 rounded-md"
                                        onClick={() =>
                                          handleCreateTask(selectedProjectIndex)
                                        }
                                      >
                                        <PlusIconOutlined />
                                        <span className="white">
                                          Create Task
                                        </span>
                                      </div>
                                    )}

                                    {isQuickAddOpen && (
                                      <QuickAddComponent
                                        item={tempTask?.task || tempTask}
                                        onSaveProject={handleSaveTask}
                                        handleEditTask={(
                                          index,
                                          title,
                                          description,
                                          date
                                        ) => {
                                          if (selectedTab === "Projects") {
                                            setTempTask((prev) => ({
                                              ...prev,
                                              task: {
                                                ...prev.task,
                                                title,
                                                description,
                                                endDate: date,
                                              },
                                            }));
                                          } else {
                                            setTempTask((prev) => ({
                                              ...prev,
                                              title,
                                              description,
                                              endDate: date,
                                            }));
                                          }
                                        }}
                                        projectData={projectData}
                                        handleSelectProject={
                                          handleSelectProject
                                        }
                                        handleCancelTask={handleCancelTask}
                                        disableProjectSelect={
                                          selectedTab === "Projects"
                                        }
                                        selectedProjectId={
                                          selectedTab === "Projects"
                                            ? data?.[selectedProjectIndex]?._id
                                            : selectedProjectId
                                        }
                                      />
                                    )}
                                  </>
                                )}
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                ) : (
                  <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="projectTasks">
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                        >
                          {data?.map((item, index) => (
                            <div
                              className={`content-container ${
                                selectedTab === "Projects" ? "active" : ""
                              }`}
                              key={item._id}
                            >
                              <div className="flex-row align-center cursor-pointer justify-space-between">
                                <div
                                  className="flex-row align-center cursor-pointer"
                                  // onClick={() => handleProjectTask(index)}
                                >
                                  {" "}
                                  <div
                                    className="align-center"
                                    onClick={() => handleProjectTask(index)}
                                  >
                                    {index === selectedProjectIndex ? (
                                      <MinusIcon />
                                    ) : (
                                      <PlusIcon />
                                    )}
                                  </div>
                                  <span
                                    className="white p-8 boldM"
                                    onClick={(e) => handleProjectClick(item)}
                                  >
                                    {item?.title}
                                  </span>
                                </div>
                                <span className="green-400 regularS">
                                  Status:{completedTasks(index)?.length}/
                                  {data?.[index].tasksList?.length}
                                </span>
                              </div>
                              {index === selectedProjectIndex && (
                                <>
                                  {item?.tasksList?.map((task, taskIndex) => {
                                    if (task?.isEditing) {
                                      return (
                                        <QuickAddComponent
                                          key={task._id}
                                          onSaveProject={() =>
                                            handleSaveTask(index)
                                          }
                                          handleEditTask={handleEditTask}
                                          item={task}
                                          index={taskIndex}
                                          projectData={projectData}
                                          handleSelectProject={
                                            handleSelectProject
                                          }
                                          handleCancelTask={() =>
                                            handleCancelTask(index)
                                          }
                                        />
                                      );
                                    } else {
                                      return (
                                        <ListViewComponent
                                          key={task._id}
                                          task={task}
                                          index={taskIndex}
                                          isDraggable={true}
                                          onClick={(e) => handleTaskClick(task)}
                                          date={moment(task?.endDate).format(
                                            "MMM D YYYY"
                                          )}
                                          title={task?.title}
                                          completed={task?.completed}
                                          status={task?.status}
                                          duration={task}
                                          onClickComplete={async () => {
                                            await setSelectedData(task);
                                            handleMarkAsComplete();
                                          }}
                                          setData={setData}
                                          data={data}
                                          setSelectedData={setSelectedData}
                                          selectedData={selectedData}
                                          onUpdateTitle={(newTitle) =>
                                            handleUpdateTitle(
                                              newTitle,
                                              task._id
                                            )
                                          }
                                          setFilteredData={setData}
                                          projectData={projectData?.data}
                                        />
                                      );
                                    }
                                  })}
                                  {provided.placeholder}
                                  {/* Quick add button */}
                                  {!isQuickAddOpen && (
                                    <div
                                      className="cursor-pointer p-12 flex-row align-center mb-4 hover:bg-gray-800 rounded-md"
                                      onClick={() =>
                                        handleCreateTask(selectedProjectIndex)
                                      }
                                    >
                                      <PlusIconOutlined />
                                      <span className="white">Create Task</span>
                                    </div>
                                  )}

                                  {isQuickAddOpen && (
                                    <QuickAddComponent
                                      item={tempTask?.task || tempTask}
                                      onSaveProject={handleSaveTask}
                                      handleEditTask={(
                                        index,
                                        title,
                                        description,
                                        date
                                      ) => {
                                        if (selectedTab === "Projects") {
                                          setTempTask((prev) => ({
                                            ...prev,
                                            task: {
                                              ...prev.task,
                                              title,
                                              description,
                                              endDate: date,
                                            },
                                          }));
                                        } else {
                                          setTempTask((prev) => ({
                                            ...prev,
                                            title,
                                            description,
                                            endDate: date,
                                          }));
                                        }
                                      }}
                                      projectData={projectData}
                                      handleSelectProject={handleSelectProject}
                                      handleCancelTask={handleCancelTask}
                                      disableProjectSelect={
                                        selectedTab === "Projects"
                                      }
                                      selectedProjectId={
                                        selectedTab === "Projects"
                                          ? data?.[selectedProjectIndex]?._id
                                          : selectedProjectId
                                      }
                                    />
                                  )}
                                </>
                              )}
                            </div>
                          ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                )
              ) : selectedViewType === "Timeline View" &&
                selectedTab === "Projects" ? (
                <TimelineView
                  projects={projectData?.data}
                  orphanTasks={dashboardData?.data?.tasks?.filter(
                    (task) => !task.project
                  )}
                  selectedView={timelineView}
                  selectedTab={selectedTab}
                  onTaskUpdate={handleTaskUpdate}
                  onProjectComplete={handleMarkAsComplete}
                  onProjectEdit={() => {
                    setIsModalOpen(false);
                    setIsCreateModalOpen(true);
                    setModalTitle("Edit Projects");
                    setIsEdit(true);
                  }}
                  onProjectDelete={handleDeleteProject}
                  handleDeleteConfirmation={handleDeleteConfirmationTask}
                  deleteProject={deleteProject}
                  patchTask={patchTask}
                  notify={notify}
                  selectedData={selectedData}
                  setSelectedData={setSelectedData}
                  setModalTitle={setModalTitle}
                  isModalOpen={isModalOpen}
                  setIsModalOpen={setIsModalOpen}
                  handleMarkAsComplete={handleMarkAsComplete}
                  handleDeleteProject={handleDeleteProject}
                  handleDeleteTask={handleDeleteTask}
                  handleEdit={() => {
                    setIsModalOpen(false);
                    setIsCreateModalOpen(true);
                    setModalTitle("Edit Projects");
                    setIsEdit(true);
                  }}
                  refetchProjectData={refetchProjectData}
                  modalContentType={modalContentType}
                  setModalContentType={setModalContentType}
                  setJustUpdated={setJustUpdated}
                  data={data}
                  setData={setData}
                  refetchTaskData={refetchTaskData}
                />
              ) : selectedViewType === "Timeline View" &&
                selectedTab === "Tasks" ? (
                <TimelineView
                  projects={[]} // Empty since we're in Tasks view
                  orphanTasks={[]} // Empty since we're in Tasks view
                  tasksData={tasksData?.data || []} // Add .data to get the actual tasks array
                  selectedView={timelineView}
                  selectedTab={selectedTab}
                  onTaskUpdate={handleTaskUpdate}
                  patchTask={patchTask}
                  notify={notify}
                  deleteProject={deleteProject}
                  handleDeleteConfirmation={handleDeleteConfirmationTask}
                  handleMarkAsComplete={handleMarkAsComplete}
                  handleDeleteProject={handleDeleteProject}
                  handleDeleteTask={handleDeleteTask}
                  selectedData={selectedData}
                  setSelectedData={setSelectedData}
                  setModalTitle={setModalTitle}
                  isModalOpen={isModalOpen}
                  setIsModalOpen={setIsModalOpen}
                  refetchProjectData={refetchProjectData}
                  modalContentType={modalContentType}
                  setModalContentType={setModalContentType}
                  setJustUpdated={setJustUpdated}
                  data={data}
                  setData={setData}
                  refetchTaskData={refetchTaskData}
                />
              ) : null}
            </div>
          </div>
        </Card>
        <Modal
          isOpen={isModalOpen}
          // className={"card-app-background align-center"}
          onClose={() => setIsModalOpen(false)}
          title={modalTitle}
          handleMark={handleMarkAsComplete}
          completed={selectedData?.completed}
          status={selectedData?.status}
          handleDeleteTask={
            modalContentType === "Project"
              ? handleDeleteProject
              : handleDeleteTask
          }
          isEditButton={
            modalContentType === "Task" || modalContentType === "Project"
              ? true
              : isEditButton
          }
          onEditClick={() => {
            setIsModalOpen(false);
            setIsCreateModalOpen(true);
            setModalTitle(`Edit ${modalContentType}`);
            setIsEdit(true);
          }}
          completionStatus={true}
        >
          <ModalViewDetails
            selectedEvent={selectedData}
            setSelectedEvent={setSelectedData}
            contentType={modalContentType}
            completed={projectData?.data?.filter(isProjectCompleted)}
            patchTask={patchTask}
            handleTaskClick={handleTaskClick}
            data={data}
            setData={setData}
            onDragEnd={onDragEnd}
          />
        </Modal>

        <CreateModal
          isOpen={isCreateModalOpen}
          // className={"card-app-background align-center"}
          onClose={() => setIsCreateModalOpen(false)}
          title={modalTitle}
          className={"w-2/5"}
        >
          {modalContentType === "Project" ? (
            <CreateProjectForm
              setModal={setIsCreateModalOpen}
              refetch={refetchProjectData}
              isEdit={isEdit}
              selectedData={selectedData}
            />
          ) : modalContentType === "Task" ? (
            <CreateTaskForm
              setModal={setIsCreateModalOpen}
              selectedData={selectedData}
              isEdit={isEdit}
              refetch={refetchProjectData}
              refetchDashboard={refetchTaskData}
              setJustUpdated={setJustUpdated}
              projectData={projectData?.data}
              setData={setData}
              dataList={data}
            />
          ) : null}
        </CreateModal>
        <Dialog
          show={confirmationDialog}
          action1ButtonLabel={"Cancel"}
          action2ButtonLabel={"Delete"}
          handelAction1={() => setConfirmationDialog(false)}
          handelAction2={handleDeleteConfirmationTask}
          isLoading={isButtonLoading}
        >
          <div className="flex-col justify-center align-middle">
            <div
              style={{
                background: "rgba(37, 31, 52, 1)",
                width: 100,
                height: 100,
                borderRadius: 100,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                position: "absolute",
                top: 20,
                left: "40%",
              }}
            >
              <DeleteOutlined size={72} />
            </div>
            <div className="pt-28">
              <h3 className="white text-align-center ">
                You are about to delete a{" "}
                {modalContentType === "Task"
                  ? "task"
                  : modalContentType === "Project"
                  ? "project"
                  : "event"}
              </h3>
              <h5 className="white text-align-center">
                This will delete your{" "}
                {modalContentType === "Task"
                  ? "task"
                  : modalContentType === "Project"
                  ? "project"
                  : "event"}{" "}
                permanently.
              </h5>
            </div>
          </div>
        </Dialog>
      </div>
    </div>
  );
};

export default DashboardComponent;
