export const NoProjects = ({ className }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 200 200"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M160 40H40c-5.523 0-10 4.477-10 10v100c0 5.523 4.477 10 10 10h120c5.523 0 10-4.477 10-10V50c0-5.523-4.477-10-10-10z"
        stroke="currentColor"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="text-gray-600"
      />
      <path
        d="M130 40V30c0-5.523-4.477-10-10-10H80c-5.523 0-10 4.477-10 10v10M60 90h80M60 110h40"
        stroke="currentColor"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="text-gray-400"
      />
      <circle
        cx="140"
        cy="140"
        r="30"
        fill="currentColor"
        className="text-indigo-500 opacity-20"
      />
      <path
        d="M140 130v20M130 140h20"
        stroke="currentColor"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="text-indigo-500"
      />
    </svg>
  );
};
