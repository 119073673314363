import React, { useEffect } from "react";
import "./Dialog.css";
import Button from "../Button";

const Dialog = ({
  title,
  children,
  handelAction1,
  handelAction2,
  show,
  footer,
  action1ButtonLabel,
  action2ButtonLabel,
  modelStyle,
  className,
  isLoading,
  style,
  variant = 'default',
}) => {
  useEffect(() => {
    if (show) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
  }, [show]);

  return (
    show && (
      <div>
        <div className={`${className} model-overlay`} />
        <div
          className="dialog"
          style={{
            height: "fit-content",
            paddingBottom: "16px",
            ...modelStyle,
          }}
        >
          <div className="dialog-header">
            <h4 style={{ lineHeight: "1.5rem" }}>{title}</h4>
          </div>

          {children}

          {footer ?? (
            <div 
              className="modal-footer"
              style={{
                marginTop: "auto",
                paddingTop: "16px",
                paddingBottom: "8px"
              }}
            >
              <Button
                type="submit"
                variety="filled"
                onClick={handelAction1}
                style={{
                  color: variant === 'input' ? "var(--Genesis-White)" : "var(--App-Black)",
                  backgroundColor: variant === 'input' ? "transparent" : undefined,
                }}
              >
                {action1ButtonLabel}
              </Button>
              <Button
                variety="filled"
                onClick={handelAction2}
                isLoading={isLoading}
                style={
                  variant === 'input' ? {
                    backgroundColor: "var(--Genesis-Green-400)",
                    color: "var(--App-Black)"
                  } : {
                    backgroundColor: "var(--App-Red)",
                    color: "var(--App-Black)"
                  }
                }
              >
                {action2ButtonLabel}
              </Button>
            </div>
          )}
        </div>
      </div>
    )
  );
};

export default Dialog;
