import React from "react";
import "./FullPageLoader.css";

const FullPageLoader = ({ message = "Loading..." }) => {
  return (
    <div className="full-page-loader">
      <div className="loader-container">
        <div className="loader-spinner"></div>
        <p className="loader-message">{message}</p>
      </div>
    </div>
  );
};

export default FullPageLoader;
