import React from "react";
import moment from "moment";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import "./chart.css";

const data = [
  { date: "Jul 1", instagram: 60, spotify: 20 },
  { date: "Jul 2", instagram: 30, spotify: 40 },
  { date: "Jul 3", instagram: 58, spotify: 50 },
  { date: "Jul 4", instagram: 70, spotify: 45 },
  { date: "Jul 5", instagram: 60, spotify: 60 },
  { date: "Jul 6", instagram: 65, spotify: 80 },
];

const CustomTooltip = ({ active, payload, dataFormat }) => {
  if (active && payload && payload.length) {
    const unit = dataFormat === 'pct' ? '%' : '';
    
    return (
      <div
        className="custom-tooltip"
        style={{
          backgroundColor: "#333",
          color: "#fff",
          padding: "10px",
          borderRadius: "5px",
        }}
      >
        {payload?.map((entry, index) => {
          if (entry.name === "instagram") {
            return (
              <p key={index} className="label">{`Instagram: ${entry.value}${unit}`}</p>
            );
          }
          if (entry.name === "spotify") {
            return (
              <p key={index} className="label">{`Spotify: ${entry.value}${unit}`}</p>
            );
          }
          return null;
        })}
      </div>
    );
  }

  return null;
};

const Chart = ({ 
  chartData, 
  height, 
  view, 
  size = "large", 
  hideZeroYAxis = false,
  dataFormat = 'val'
}) => {
  const processedData = chartData?.reduce((acc, item) => {
    // If we haven't found first non-null value yet, check if this item has one
    if (acc.length === 0) {
      if (item.spotify !== null || item.instagram !== null) {
        acc.push({
          ...item,
          spotify: item.spotify === null ? 0 : item.spotify,
        });
      }
    } else {
      // After finding first non-null, include all subsequent items
      acc.push({
        ...item,
        spotify: item.spotify === null ? 0 : item.spotify,
      });
    }
    return acc;
  }, []);

  const formatXAxis = (tickItem) => {
    if(view === "Yearly") {
      return size === "large" ? moment(tickItem).format('MMM YYYY') : moment(tickItem).format('MMM');
    }
    return moment(tickItem).format('MMM D');
  };

  // Calculate ticks for even spacing
  const getEvenlySpacedTicks = () => {
    if (!processedData?.length) return [];
    const dates = processedData.map(item => item.date);
    
    // Show all days for Weekly view in large size
    if (view === "Weekly" && size === "large") {
      // Generate array of last 7 days
      return Array.from({ length: 7 }, (_, i) => 
        moment().subtract(6 - i, 'days').format('YYYY-MM-DD')
      );
    }
    
    // Default behavior for other views/sizes
    const numIntervals = 4;
    const step = (dates.length - 1) / numIntervals;
    
    return Array.from({ length: numIntervals + 1 }, (_, i) => {
      const index = Math.round(i * step);
      return dates[index];
    });
  };

  return (
    <ResponsiveContainer width="100%" height={height ? height : 300}>
      <LineChart
        data={processedData}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid 
          horizontal={true}
          vertical={false}
          stroke="#444" 
        />
        <XAxis 
          dataKey="date" 
          stroke="#888" 
          tickFormatter={formatXAxis}
          ticks={getEvenlySpacedTicks()}
          tickLine={false}
          axisLine={false}
          dy={10}
        />
        <YAxis 
          stroke="#888"
          tickLine={false}
          axisLine={false}
          allowDecimals={false}
          tickFormatter={(value) => value === 0 && hideZeroYAxis ? '' : value}
          unit={dataFormat === 'pct' ? '%' : ''}
          domain={['auto', 'auto']}
        />
        <Tooltip content={<CustomTooltip dataFormat={dataFormat} />} />
        <Legend 
          wrapperStyle={{
            paddingTop: "4px",
            width: "100%",
            display: "flex",
            justifyContent: "center"
          }}
        />
        <Line
          type="monotone"
          dataKey="instagram"
          stroke="#FF4500"
          dot={{ r: 0 }}
          activeDot={{ r: 4 }}
          yAxisId={0}
          connectNulls={true}
        />
        <Line
          type="monotone"
          dataKey="spotify"
          stroke="#32CD32"
          dot={{ r: 0 }}
          activeDot={{ r: 4 }}
          connectNulls={true}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default Chart;
