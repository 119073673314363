export const NoTasks = () => {
  return (
    <svg
      width="400"
      height="400"
      viewBox="0 0 400 400"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="200" cy="200" r="180" fill="#1F2937" opacity="0.1" />

      <rect x="130" y="80" width="140" height="180" rx="12" fill="#374151" />
      <rect x="140" y="90" width="120" height="160" rx="8" fill="#1F2937" />

      <rect x="160" y="70" width="80" height="20" rx="6" fill="#4B5563" />

      <rect
        x="160"
        y="120"
        width="80"
        height="8"
        rx="4"
        fill="#4B5563"
        opacity="0.5"
      />
      <rect
        x="160"
        y="145"
        width="80"
        height="8"
        rx="4"
        fill="#4B5563"
        opacity="0.5"
      />
      <rect
        x="160"
        y="170"
        width="60"
        height="8"
        rx="4"
        fill="#4B5563"
        opacity="0.5"
      />

      <circle cx="200" cy="240" r="30" fill="#6B7280" />
      <path
        d="M185 240L195 250L215 230"
        stroke="#1F2937"
        stroke-width="6"
        stroke-linecap="round"
        stroke-linejoin="round"
      />

      <circle cx="320" cy="140" r="8" fill="#4B5563" opacity="0.3" />
      <circle cx="80" cy="260" r="12" fill="#4B5563" opacity="0.3" />
      <circle cx="290" cy="280" r="10" fill="#4B5563" opacity="0.3" />
    </svg>
  );
};
