import React, { useState, useCallback, Suspense } from 'react';
import ProgressChart from '../ProgressChart/ProgressChart';
import { useChartData } from '../../hooks/useChartData';
import { BallTriangleLoader } from '../../components/Loader/Loader';

const ChartContent = ({ platform, selectedChartValue, dataFormatType, onChartValueChange, onDataFormatChange }) => {
  const { data: chartData } = useChartData(platform, selectedChartValue, dataFormatType);

  return (
    <ProgressChart
      chartData={chartData?.data}
      selectedChartValue={selectedChartValue}
      setSelectedChartValue={onChartValueChange}
      className="mt-20"
      size="large"
      onDataFormatChange={onDataFormatChange}
      defaultDataFormat={dataFormatType}
      showFormatToggle={true}
    />
  );
};

const SocialChart = ({ platform }) => {
  const [selectedChartValue, setSelectedChartValue] = useState("Weekly");
  const [dataFormatType, setDataFormatType] = useState('val');

  const handleDataFormatChange = useCallback((newFormat) => {
    setDataFormatType(newFormat);
  }, []);

  const handleChartValueChange = useCallback((newValue) => {
    setSelectedChartValue(newValue);
  }, []);

  return (
    <Suspense fallback={<BallTriangleLoader />}>
      <ChartContent
        platform={platform}
        selectedChartValue={selectedChartValue}
        dataFormatType={dataFormatType}
        onChartValueChange={handleChartValueChange}
        onDataFormatChange={handleDataFormatChange}
      />
    </Suspense>
  );
};

export default React.memo(SocialChart); 