import React, { useState } from "react";
import { useNavigate } from "react-router";
import { Outlet } from "react-router-dom";
import Card from "../../components/Card/Card";
import { MetricsCardComponent } from "../../components/Metrics/MetricsCard";
import { useDataQuery } from "../../hooks/crud/data.query";
import { API_END_POINTS } from "../../utils/api-endpoint";
import moment from "moment";
import { useForm } from "react-hook-form";
import ProgressChart from "../../components/ProgressChart/ProgressChart";
import { useIsSidebarOpen } from "src/hooks/ui/IsSidebarOpen";

const Social = () => {
  const { isSidebarOpen } = useIsSidebarOpen();
  const navigate = useNavigate();
  const [selectedChartValue, setSelectedChartValue] = useState("Weekly");
  const {
    handleSubmit,
    control,
    formState: { errors },
    register,
    reset,
  } = useForm({});

  const { data: socialOverviewData } = useDataQuery({
    url: API_END_POINTS.socialOverview,
    params: {},
  });

  const [dataFormatType, setDataFormatType] = useState("pct");
  const { data: chartData } = useDataQuery({
    url: API_END_POINTS.charts,
    params: {
      startDate:
        selectedChartValue === "Monthly"
          ? moment().subtract(1, "month").format("YYYY-MM-DD")
          : selectedChartValue === "Yearly"
          ? moment().subtract(1, "year").format("YYYY-MM-DD")
          : moment().subtract(6, "days").format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
      view: dataFormatType,
    },
  });

  return (
    <div
      className={`${isSidebarOpen ? "w-full sm:w-[92%] md:w-full" : "w-full"}`}
    >
      <Outlet />
      <span className="gray-400 regularL">
        Task lists, event vibes, and social beats
      </span>
      <div>
        <Card className={"card-second-wrapper mt-2 py-2"}>
          <span className="white regularL p-24">Overview</span>
          <div className="flex flex-col md:flex-row justify-space-between">
            {socialOverviewData?.data
              ?.filter((filter) => filter?.isActive)
              ?.map((item, index) => (
                <MetricsCardComponent
                  key={index}
                  platformName={item.platformName}
                  audienceCount={item?.audienceCount}
                  navigate={navigate}
                  isLinked={item?.isLinked}
                />
              ))}
          </div>
        </Card>

        {chartData?.data && (
          <ProgressChart
            chartData={chartData?.data}
            selectedChartValue={selectedChartValue}
            setSelectedChartValue={setSelectedChartValue}
            height={500}
            withAnimation={true}
            size="large"
            onDataFormatChange={setDataFormatType}
            defaultDataFormat={dataFormatType}
            showFormatToggle={true}
          />
        )}
      </div>
    </div>
  );
};

export default Social;
