import { Navigate, Outlet } from "react-router-dom";
import { useState, useEffect } from "react";
import { isAuthenticated } from "../../Router";

export default function PublicLayout() {
  const [isAuth, setIsAuth] = useState(null);

  useEffect(() => {
    const checkAuth = async () => {
      const authenticated = await isAuthenticated();
      setIsAuth(authenticated);
    };
    checkAuth();
  }, []);

  if (isAuth === null) {
    return (
      <div className="flex items-center justify-center h-screen w-screen bg-background-primary">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-white"></div>
      </div>
    );
  }

  return isAuth ? <Navigate to="/home" replace={true} /> : <Outlet />;
}
