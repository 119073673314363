import { useDataQuery } from "./crud/data.query";
import moment from "moment";
import { API_END_POINTS } from "../utils/api-endpoint";

export const useChartData = (platform, selectedChartValue, dataFormatType) => {
  const chartQueryParams = {
    social: platform,
    startDate:
      selectedChartValue === "Monthly"
        ? moment().subtract(1, "month").format("YYYY-MM-DD")
        : selectedChartValue === "Yearly"
        ? moment().subtract(1, "year").format("YYYY-MM-DD")
        : moment().subtract(6, "days").format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
    view: dataFormatType,
  };

  return useDataQuery({
    url: API_END_POINTS.charts,
    params: chartQueryParams,
  });
}; 