import queryString from "query-string";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { SpotifyIcon } from "../../assets/svg/SpotifyIcon";
import Card from "../Card/Card";
import { InstagramIcon } from "../../assets/svg/InstagramIcon";
import { APP_ROUTES } from "../../utils/app-routes";
import NumberTicker from "../magicui/number-ticker";
import { BorderBeam } from "../magicui/border-beam";
import Dialog from "../Dialog/Dialog";
import Button from "../Button/Button";
import { useMutationCreate } from "../../hooks/crud/mutation.create";
import { API_END_POINTS } from "../../utils/api-endpoint";

const LinkSpan = styled.span`
  padding-left: 4px;
`;

export const MetricsCardComponent = ({
  platformName,
  audienceCount,
  isView,
  navigate,
  isLinked,
}) => {
  const CLIENT_ID = process.env.REACT_APP_SPOTIFY_CLIENT_ID;
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const AUTH_ENDPOINT = process.env.REACT_APP_SPOTIFY_AUTH_ENDPOINT;
  const RESPONSE_TYPE = "code";
  // const RESPONSE_TYPE = process.env.REACT_APP_SPOTIFY_RESPONSE_TYPE;
  const REDIRECT_URI = process.env.REACT_APP_SPOTIFY_REDIRECT_URI;
  const [type, setType] = useState("");
  const [platformNameColor, setPlatformNameColor] = useState("");
  const [iconName, setIconName] = useState(undefined);
  const [showSpotifyModal, setShowSpotifyModal] = useState(false);

  const typeAsPerPlatform = () => {
    if (platformName === "Spotify"?.toLowerCase()) {
      setType("Followers");
      setPlatformNameColor("#1DB954");
      setIconName(<SpotifyIcon />);
    } else if (platformName === "Instagram"?.toLowerCase()) {
      setType("Followers");
      setPlatformNameColor("#D62976");
      setIconName(<InstagramIcon />);
    }
  };
  console.log("node env", process.env.NODE_ENV);
  const onClickLogin = async (type) => {
    switch (type) {
      case "Spotify".toLowerCase():
        setShowSpotifyModal(true);
        break;
      case "Instagram".toLowerCase():
        window.location.href = `https://www.facebook.com/v19.0/dialog/oauth?client_id=3712894485649552&display=popup&redirect_uri=${
          process.env.NODE_ENV === "development"
            ? "http://localhost:3000/callback/instacallback"
            : process.env.REACT_APP_INSTAGRAM_REDIRECT_URI
        }&response_type=token&scope=email,instagram_manage_insights,pages_show_list,business_management,instagram_basic`;

        break;
      default:
        break;
    }
  };
  const authHandler = (err, data) => {};
  const handleNavigation = (e) => {
    e.preventDefault();
    // history.push(`/social/${platformName?.toLowerCase()}/view`);
  };

  const handleSpotifySubmit = (artistId) => {
    // Handle the artistId here - you'll want to send this to your backend
    console.log('Artist ID:', artistId);
    setShowSpotifyModal(false);
    
  };

  useEffect(() => {
    typeAsPerPlatform();
  }, [platformName]);

  return (
    <>
      <Card className="m-8 p-12  w-95 md:w-[95%] align-center flex-row p-relative hover:bg-slate-800">
        {iconName}
        <div
          className={`p-12 ${!isView && "cursor-pointer"}`}
          onClick={() =>
            !isView &&
            // isLinked &&
            navigate(APP_ROUTES.socialOverViewPage + "/" + platformName, {
              state: { isLinked: isLinked },
            })
          }
        >
          {/* <BorderBeam size={250} duration={12} delay={5} /> */}
          <span className="white text-capitalize">{platformName}</span>
          <div className="flex-row align-center">
            <span className="white">
              {!isLinked ? 0 : <NumberTicker value={audienceCount} />}
            </span>
            <span className="gray-300"> &nbsp;{type}</span>
          </div>
        </div>
        {!isView && (
          <div
            style={{ position: "absolute", top: 20, right: 20 }}
            className={`${isLinked ? "cursor-disabled" : "cursor-pointer"}`}
            onClick={() => !isLinked && onClickLogin(platformName)}
          >
            <span className="green-400">{isLinked ? "Linked" : "Link"}</span>
          </div>
        )}
      </Card>
      
      <SpotifyLinkModal 
        isOpen={showSpotifyModal}
        onClose={() => setShowSpotifyModal(false)}
        onSubmit={handleSpotifySubmit}
      />
    </>
  );
};

const SpotifyLinkModal = ({ isOpen, onClose, onSubmit }) => {
  const [url, setUrl] = useState('');
  const [error, setError] = useState('');

  const linkArtistMutation = useMutationCreate(API_END_POINTS.spotifyLinkArtist);

  const validateAndExtractId = (url) => {
    // Spotify artist URLs follow the pattern: https://open.spotify.com/artist/[id]
    const regex = /^https:\/\/open\.spotify\.com\/artist\/([a-zA-Z0-9]+)(?:\?|$)/;
    const match = url.match(regex);
    
    if (!match) {
      throw new Error('Invalid Spotify artist URL. Please enter a valid artist profile URL.');
    }
    
    return match[1]; // Return the extracted ID
  };

  const handleSubmit = () => {
    try {
      const artistId = validateAndExtractId(url);
      
      linkArtistMutation.mutate(
        { artistId },
        {
          onSuccess: (data) => {
            onSubmit(artistId);
            onClose();
          },
          onError: (error) => {
            setError(error.message || 'Failed to link Spotify artist profile');
          }
        }
      );
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <Dialog 
      show={isOpen}
      title="Connect Spotify Artist Profile"
      action1ButtonLabel="Cancel"
      action2ButtonLabel="Connect"
      handelAction1={onClose}
      handelAction2={handleSubmit}
      modelStyle={{ maxWidth: '500px' }}
      variant="input"
    >
      <div className="p-24">
        <p className="gray-400 regularL">
          Please enter your Spotify artist profile URL
        </p>
        <input
          type="text"
          value={url}
          onChange={(e) => setUrl(e.target.value)}
          placeholder="https://open.spotify.com/artist/..."
          className="w-full mt-12"
        />
        {error && <p className="text-red-500 mt-2">{error}</p>}
      </div>
    </Dialog>
  );
};
