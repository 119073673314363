import React, { useEffect, useState } from "react";
import CalendarComponent from "src/components/Dashboard/CalendarComponent";
import { useIsSidebarOpen } from "src/hooks/ui/IsSidebarOpen";
import { useDataQuery } from "../../hooks/crud/data.query";
import { API_END_POINTS } from "../../utils/api-endpoint";

const CalendarPage = () => {
  const { isSidebarOpen } = useIsSidebarOpen();
  const [data, setData] = useState();
  const {
    data: timelineData,
    isFetching: isFetchingTimeline,
    isLoading: isLoadingTimeline,
  } = useDataQuery({
    url: API_END_POINTS.timeline,
    params: {},
  });
  const { data: googleCalendarLinkedStatus } = useDataQuery({
    url: API_END_POINTS.googleCalendarLinkStatus,
    params: {},
  });

  const { data: dashboardData } = useDataQuery({
    url: API_END_POINTS.dashboard,
    params: {},
  });

  useEffect(() => {
    if (dashboardData?.data?.events) {
      setData(dashboardData?.data?.events);
    }
  }, [dashboardData]);

  return (
    <div
      className={`${isSidebarOpen ? "w-full sm:w-[92%] md:w-full" : "w-full"}`}
    >
      <span className="gray-400 regularL">
        Event lists, event vibes, and social beats
      </span>

      <div className="flex-row justify-space-between ">
        <CalendarComponent
          className="w-100"
          googleCalendarStatus={googleCalendarLinkedStatus?.data}
        />
      </div>
    </div>
  );
};

export default CalendarPage;
