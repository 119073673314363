import React from "react";
import Button from "../Button";

const PaginationFooter = ({
  currentPage,
  totalPages,
  onPageChange,
  className = "",
}) => {
  if (totalPages <= 1) return null;

  return (
    <div
      className={`flex justify-center items-center mt-4 mb-4 gap-2 ${className}`}
    >
      <Button
        variety="outlined"
        onClick={() => onPageChange(currentPage - 1)}
        disabled={currentPage === 1}
        className="px-3 py-1 h-8"
      >
        Previous
      </Button>

      {Array.from({ length: totalPages }, (_, i) => i + 1)
        .filter((pageNumber) => {
          return (
            pageNumber === 1 ||
            pageNumber === totalPages ||
            Math.abs(pageNumber - currentPage) <= 1
          );
        })
        .map((pageNumber, index, array) => (
          <React.Fragment key={pageNumber}>
            {index > 0 && array[index - 1] !== pageNumber - 1 && (
              <span className="text-gray-500">...</span>
            )}
            <Button
              variety={pageNumber === currentPage ? "filled" : "outlined"}
              onClick={() => onPageChange(pageNumber)}
              className="px-3 py-1 h-8 min-w-[32px]"
            >
              {pageNumber}
            </Button>
          </React.Fragment>
        ))}

      <Button
        variety="outlined"
        onClick={() => onPageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
        className="px-3 py-1 h-8"
      >
        Next
      </Button>
    </div>
  );
};

export default PaginationFooter;
