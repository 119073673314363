import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { APP_ROUTES } from "../../utils/app-routes";
import { notify } from "../../components/Toast/ToastComponent";
import { INSTAGRAM_TOKEN } from "../../utils/constants";

const InstagramCallback = () => {
  const navigate = useNavigate();

  const storeToken = async (access_token) => {
    try {
      // Store the token in localStorage
      localStorage.removeItem(INSTAGRAM_TOKEN);
      localStorage.setItem(INSTAGRAM_TOKEN, access_token);

      // Verify token is valid by making a test API call
      const response = await fetch(
        `https://graph.facebook.com/v19.0/me?access_token=${access_token}`
      );
      const data = await response.json();

      if (data.error) {
        throw new Error(data.error.message);
      }

      // If successful, navigate to social page
      notify("Successfully connected to Instagram!", "success");
      navigate(APP_ROUTES.social);
    } catch (error) {
      notify(error.message || "Failed to connect Instagram", "error");
      navigate(APP_ROUTES.social); // Navigate even on error, but with error message
    }
  };

  useEffect(() => {
    try {
      const url = new URL(window.location);
      const hashParams = new URLSearchParams(url.hash.substring(1)); // Remove the # and parse
      const access_token = hashParams.get("access_token");

      if (!access_token) {
        throw new Error("No access token received");
      }

      storeToken(access_token);
    } catch (error) {
      notify("Failed to process Instagram authentication", "error");
      navigate(APP_ROUTES.social);
    }
  }, []);

  return (
    <div className="flex items-center justify-center h-screen">
      <div className="text-center">
        <h3 className="white mb-4">Connecting to Instagram...</h3>
        {/* Add a loading spinner here if you have one */}
      </div>
    </div>
  );
};

export default InstagramCallback;
