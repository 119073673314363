import { yupResolver } from "@hookform/resolvers/yup";
import { useGoogleLogin } from "@react-oauth/google";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useLocation } from "react-router";
import * as yup from "yup";
import loginImage from "../../../assets/png/login.png";
import {
  EyeCloseOutlined,
  EyeOpenOutlined,
} from "../../../assets/svg/EyeOutlined";
import { GoogleIcon } from "../../../assets/svg/GoogleIcon";
import { Logo } from "../../../assets/svg/Logo";
import Button from "../../../components/Button";
import { useMutationCreate } from "../../../hooks/crud/mutation.create";
import { API_END_POINTS } from "../../../utils/api-endpoint";
import { APP_ROUTES } from "../../../utils/app-routes";
import { USER_DETAILS } from "../../../utils/constants";
import "./LoginPage.css";
import { notify } from "../../../components/Toast/ToastComponent";
import { routeToCorrectStep } from "./loginHelpers.js";

const Login = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const location = useLocation();
  const navigate = useNavigate();
  const [headers, setHeaders] = useState({}); //[1
  const { mutate: googleAuth } = useMutationCreate(
    API_END_POINTS.googleSignIn,
    headers
  );

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const login = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      setHeaders({ Authorization: `Bearer ${codeResponse.access_token}` });
      setTimeout(() => {
        googleAuth(
          {},
          {
            onSuccess(res) {
              routeToCorrectStep(res, navigate);
            },
          },
          {
            onError(err) {
              notify(err?.message, "error");
              console.log("err", err);
            },
          }
        );
      }, [400]);
    },
    onError: (error) => console.log("Login Failed:", error),
  });

  return (
    <>
      <div className="w-full flex flex-col md:flex-row">
        <div
          className="hidden md:block md:w-1/2 login-banner"
          style={{ overflow: "hidden" }}
        >
          <img
            className="w-full h-screen"
            style={{
              objectFit: "cover",
              objectPosition: "center",
              width: "100%",
              height: "100%",
            }}
            src={loginImage}
            alt="Login"
          />
        </div>

        <div className="w-full md:w-1/2 box p-4 md:p-8">
          <div className="mx-auto w-[90%] sm:w-[80%] md:w-[450px] px-4 sm:px-6 md:px-8">
            <div className="mb-8 md:mb-12 h-[2.5rem] md:h-[3.125rem] w-[9.2rem] md:w-[11.53rem]">
              <Logo width={windowWidth < 768 ? "184" : "275"} />
            </div>
            <div className="w-full">
              <span className="h4 text-2xl md:text-3xl block">
                Welcome back
              </span>
              <span className="regularS text-sm md:text-base gray-300 block mt-2">
                Continue with Google or enter your details
              </span>
              <Button
                type="submit"
                variety="secondary"
                className="flex-row mt-4 md:mt-6 w-full text-sm md:text-base"
                height={"50px"}
                onClick={login}
              >
                <GoogleIcon />
                Continue with Google
              </Button>
            </div>
            <div className="flex items-center justify-between mt-4 md:mt-6">
              <hr className="w-1/4 gray-500" />
              <span className="gray-300 text-xs md:text-sm px-2 md:px-4">
                or log in with your email
              </span>
              <hr className="w-1/4" />
            </div>
            <div className="w-full">
              <LoginForm navigate={navigate} location={location} />
            </div>
            <div
              className="text-center cursor-pointer mt-3 text-sm md:text-base"
              onClick={() => navigate(APP_ROUTES.signup)}
            >
              <span className="gray-300">Don't have an account?</span>
              <span className="green-400 semiboldM"> Sign Up</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;

const schema = yup.object().shape({
  email: yup.string().required("Email is required"),
  password: yup.string().required("Password is required"),
});

const LoginForm = ({ navigate, location }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const {
    mutate: loginUserDetails,
    isLoading,
    error,
  } = useMutationCreate(API_END_POINTS.login);
  const {
    handleSubmit,
    control,
    formState: { errors },
    register,
    setValue,
    getValues,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    loginUserDetails(data, {
      onSuccess(res) {
        if (res?.statusCode === 401) {
          // user still has onboarding steps to complete
          routeToCorrectStep(res, navigate);
        } else {
          const from = location.state?.from || "/home";
          navigate(from, { replace: true });
          localStorage.setItem(USER_DETAILS, JSON.stringify(res));
          notify(res?.message, "success");
          if (data.rememberMe) {
            localStorage.setItem("rememberedEmail", data.email);
            localStorage.setItem("rememberedPassword", data.password);
          } else {
            localStorage.removeItem("rememberedEmail");
            localStorage.removeItem("rememberedPassword");
          }
        }
      },
      onError: (err) => {
        console.log("err in login", err);
        notify(err?.message, "error");
      },
    });
  };

  useEffect(() => {
    const rememberedEmail = localStorage.getItem("rememberedEmail");
    const rememberedPassword = localStorage.getItem("rememberedPassword");

    if (rememberedEmail && rememberedPassword) {
      setValue("email", rememberedEmail);
      setValue("password", rememberedPassword);
      setValue("rememberMe", true);
    }
  }, [setValue]);

  return (
    <form className="m-t-24 w-full" onSubmit={handleSubmit(onSubmit)}>
      <div>
        <Controller
          name="email"
          control={control}
          render={({ field }) => (
            <input
              className="w-full"
              placeholder="Email"
              type="email"
              {...register("email")}
            />
          )}
        />
        <p
          style={{
            color: "var(--App-Red)",
          }}
        >
          {errors.email?.message}
        </p>
      </div>

      <div>
        <Controller
          name="password"
          control={control}
          render={({ field }) => (
            <div style={{ position: "relative", marginTop: 24, width: "100%" }}>
              <input
                className="w-full"
                placeholder="Password"
                type={showPassword ? "text" : "password"}
                {...field}
                {...register("password")}
              />
              <span
                style={{
                  position: "absolute",
                  right: "15px",
                  top: "50%",
                  transform: "translateY(-50%)",
                  cursor: "pointer",
                }}
                onClick={() => setShowPassword(!showPassword)}
              >
                {!showPassword ? <EyeOpenOutlined /> : <EyeCloseOutlined />}{" "}
                {/* Change the icon based on the state variable */}
              </span>
            </div>
          )}
        />
        <p
          style={{
            color: "var(--App-Red)",
          }}
        >
          {errors.password?.message}
        </p>
      </div>

      <div className="flex-row justify-space-between align-center mt-4">
        <label className="left white align-center">
          <input type="checkbox" {...register("rememberMe")} />
          <span className="regularS">Remember me</span>
        </label>

        <a className="right green-400 mediumS" href={APP_ROUTES.forgotPassword}>
          Forgot Password?
        </a>
      </div>

      <Button
        type="submit"
        variety="filled"
        className="primary-button w-100 black m-t-24"
        height={"56px"}
        isLoading={isLoading}
      >
        Continue
      </Button>
    </form>
  );
};
