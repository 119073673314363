import React, { memo } from "react";
import { useForm } from "react-hook-form";
import FormSelect from "../Select/FormSelect";
import Chart from "../Chart/LineChart";
import BoxReveal from "../magicui/box-reveal";
import { ChartDropDown } from "../../constants/constant";
import Card from "../Card/Card";

const ProgressChart = memo(
  ({
    chartData,
    selectedChartValue,
    setSelectedChartValue,
    className = "",
    height,
    withAnimation = false,
    size = "large",
    onDataFormatChange,
    defaultDataFormat = "pct",
    showFormatToggle = false,
  }) => {
    const { control } = useForm({});

    const hasChartData = chartData && Object.keys(chartData).length > 0;

    const handleFormatToggle = (newFormat) => {
      if (onDataFormatChange) {
        onDataFormatChange(newFormat);
      }
    };

    // If we don't have data, show the loading state
    if (!hasChartData) {
      return (
        <Card className={`card-second-wrapper p-16 ${className}`}>
          <div className="items-center justify-center flex flex-column min-h-[300px]">
            <div className="text-center">
              <h3 className="white mb-4">Progress Chart</h3>
              <p className="text-gray-400">
                Chart data is being collected. Please check back in a few
                minutes.
              </p>
            </div>
          </div>
        </Card>
      );
    }

    const chartContent = (
      <div
        className={`card-second-wrapper rounded-lg ${
          size === "small" ? "mt-2" : "mt-4"
        } ${className}`}
      >
        <div className="flex justify-between items-center">
          <span className="white text-2xl ml-8 mt-4">Your Progress</span>

          <div className="flex items-center gap-4 mr-12 my-4">
            {/* Toggle Button Group - Only show if showFormatToggle is true */}
            {showFormatToggle && onDataFormatChange && (
              <div className="flex bg-[#251f34] rounded-lg p-1 h-[38px] self-end">
                <button
                  className={`px-3 rounded-md text-sm transition-all duration-200 ${
                    defaultDataFormat === "pct"
                      ? "bg-[#464453] text-white"
                      : "text-[#6f6c84] hover:text-white"
                  }`}
                  onClick={() => handleFormatToggle("pct")}
                >
                  %
                </button>
                <button
                  className={`px-3 rounded-md text-sm transition-all duration-200 ${
                    defaultDataFormat === "val"
                      ? "bg-[#464453] text-white"
                      : "text-[#6f6c84] hover:text-white"
                  }`}
                  onClick={() => handleFormatToggle("val")}
                >
                  #
                </button>
              </div>
            )}

            {/* FormSelect wrapper to maintain original width */}
            <div className="w-[125px]">
              <FormSelect
                control={control}
                label="Status"
                id="status"
                options={ChartDropDown}
                onChange={(e) => {
                  if (e) {
                    setSelectedChartValue(e.value);
                  } else {
                    setSelectedChartValue("");
                  }
                }}
                name="interval"
                placeholder={
                  ChartDropDown.find(
                    (option) => option.value === selectedChartValue
                  )?.label || selectedChartValue
                }
              />
            </div>
          </div>
        </div>

        <div className="mt-4 mr-4">
          <Chart
            chartData={chartData}
            height={height}
            view={selectedChartValue}
            size={size}
            hideZeroYAxis={false}
            dataFormat={defaultDataFormat}
          />
        </div>
      </div>
    );

    return withAnimation ? (
      <BoxReveal width="100%" boxColor={"transparent"} duration={0.5}>
        {chartContent}
      </BoxReveal>
    ) : (
      chartContent
    );
  }
);

export default ProgressChart;
