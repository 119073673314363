const EventListSkeleton = () => {
  return (
    <div className="animate-pulse mb-4">
      <div className="flex items-start p-4 bg-gray-800 rounded-lg">
        <div className="w-full">
          {/* Title skeleton */}
          <div className="h-6 bg-gray-700 rounded w-1/4 mb-2"></div>
          {/* Date skeleton */}
          <div className="h-4 bg-gray-700 rounded w-1/6 mb-2"></div>
          {/* Description skeleton */}
          <div className="h-4 bg-gray-700 rounded w-3/4 mb-2"></div>
          {/* Location skeleton */}
          <div className="h-4 bg-gray-700 rounded w-1/3"></div>
        </div>
      </div>
    </div>
  );
};

export default EventListSkeleton;
