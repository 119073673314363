import moment from "moment";
import React, { useEffect, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { ModalViewDetails } from "../Dashboard/ModalView";
import Modal from "../Modal/Modal";
import CustomToolbar from "../Toolbar/CustomToolbar";
import "./MyCalendar.css";
import { ListViewComponent } from "../ListView/ListViewComponent";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import {
  useMutationPatch,
  useMutationPatchEvent,
} from "src/hooks/crud/mutation.patch";
import { API_END_POINTS } from "../../utils/api-endpoint";
import { notify } from "../Toast/ToastComponent";
import Dialog from "../Dialog/Dialog";
import { CalendarIcon } from "@radix-ui/react-icons";

const localizer = momentLocalizer(moment);

const DnDCalendar = withDragAndDrop(Calendar);

const MyCalendar = ({
  deleteTask,
  setIsModalOpen,
  isEdit,
  setIsEdit,
  setIsCreateModalOpen,
  setModalTitle,
  data,
  isTask,
  selectedTab,
  selectedData,
  setSelectedData,
}) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [view, setView] = useState(() => {
    return localStorage.getItem("calendarView") || "month";
  });
  const [isTodaySelected, setIsTodaySelected] = useState(false); // New state variable

  const [localEvents, setLocalEvents] = useState([]);
  const [confirmationDialog, setConfirmationDialog] = useState(false);
  const [pendingEventUpdate, setPendingEventUpdate] = useState(null);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  // update the event
  const { mutate: updateEvent } = useMutationPatchEvent(API_END_POINTS.events);

  // Initialize localEvents from props
  useEffect(() => {
    const formattedEvents = data
      ?.filter((event) => event.title)
      ?.map((task) => ({
        id: task._id,
        title: task.title,
        start: moment(task?.startDateTime).local().toDate(),
        end: moment(task?.endDateTime).local().toDate(),
        allDay: task.allDay || false,
        resource: task,
      }));
    setLocalEvents(formattedEvents || []);
  }, [data]);

  const moveEvent = (eventDetails) => {
    setPendingEventUpdate({
      type: "move",
      details: eventDetails,
    });
    setConfirmationDialog(true);
  };

  const resizeEvent = (eventDetails) => {
    setPendingEventUpdate({
      type: "resize",
      details: eventDetails,
    });
    setConfirmationDialog(true);
  };

  const handleConfirmation = () => {
    setIsButtonLoading(true);
    const { type, details } = pendingEventUpdate;

    if (type === "move") {
      const { event, start, end, isAllDay } = details;
      const updatedStart = isAllDay
        ? moment(start).startOf("day").toDate()
        : start;
      const updatedEnd = isAllDay ? moment(end).endOf("day").toDate() : end;

      setLocalEvents((prevEvents) => {
        const idx = prevEvents.findIndex((e) => e.id === event.id);
        const updatedEvent = {
          ...event,
          start: updatedStart,
          end: updatedEnd,
          allDay: isAllDay,
        };

        const nextEvents = [...prevEvents];
        nextEvents.splice(idx, 1, updatedEvent);

        updateEvent(
          {
            ...updatedEvent,
            startDateTime: updatedStart,
            endDateTime: updatedEnd,
          },
          event.id,
          {
            onSuccess: () => {
              notify("Event updated successfully", "success");
              setConfirmationDialog(false);
              setIsButtonLoading(false);
            },
            onError: () => {
              notify("Error updating event", "error");
              setConfirmationDialog(false);
              setIsButtonLoading(false);
            },
          }
        );
        return nextEvents;
      });
    } else if (type === "resize") {
      const { event, start, end } = details;
      setLocalEvents((prevEvents) => {
        const idx = prevEvents.findIndex((e) => e.id === event.id);
        const updatedEvent = { ...event, start, end };

        updateEvent(
          {
            ...updatedEvent,
            startDateTime: start,
            endDateTime: end,
          },
          event.id,
          {
            onSuccess: () => {
              notify("Event updated successfully", "success");
              setConfirmationDialog(false);
              setIsButtonLoading(false);
            },
            onError: () => {
              notify("Error updating event", "error");
              setConfirmationDialog(false);
              setIsButtonLoading(false);
            },
          }
        );

        const nextEvents = [...prevEvents];
        nextEvents.splice(idx, 1, updatedEvent);
        return nextEvents;
      });
    }
  };

  // Function to handle date selection
  const handleSelect = ({ start, end }) => {
    const today = moment().startOf("day");
    if (moment(start).isSame(today, "d")) {
      setIsTodaySelected(true);
    } else {
      setIsTodaySelected(false);
    }
  };

  // Update localStorage when view changes
  const handleViewChange = (newView) => {
    setView(newView);
    localStorage.setItem("calendarView", newView);
  };

  const handleEventClick = (event) => {
    setSelectedData(event.resource); // Store the full event data
    setSelectedEvent(event);
    setIsPopupOpen(true);
  };

  return (
    <div>
      {isTodaySelected ? (
        <ListViewComponent />
      ) : (
        <DnDCalendar
          onSelectSlot={handleSelect}
          className={
            isTask && (view === "day" || view === "week")
              ? "hide-time-gutter"
              : ""
          }
          localizer={localizer}
          defaultDate={new Date()}
          defaultView={view}
          events={localEvents}
          style={{ height: "100vh", background: "transparent" }}
          onSelectEvent={handleEventClick}
          onView={handleViewChange}
          components={{
            toolbar: CustomToolbar,
          }}
          selectable
          resizable
          step={30}
          timeslots={2}
          // Drag and drop specific props
          onEventDrop={moveEvent}
          onEventResize={resizeEvent}
          resizableAccessor={() => true}
          draggableAccessor={() => true}
          // Additional props for better UX
          popup
          tooltipAccessor={null}
          showMultiDayTimes
          longPressThreshold={250}
        />
      )}
      <Modal
        isOpen={isPopupOpen}
        // className={"card-app-background align-center"}
        onClose={() => setIsPopupOpen(false)}
        title={"Task Details"}
        handleDeleteTask={deleteTask}
        isEditButton={true}
        completionStatus={false}
        onEditClick={() => {
          setIsPopupOpen(false);
          setIsCreateModalOpen(true);
          setModalTitle("Edit Event");
          setIsEdit(true);
        }}
      >
        <ModalViewDetails selectedEvent={selectedEvent} />
      </Modal>
      <Dialog
        show={confirmationDialog}
        action1ButtonLabel={"Cancel"}
        action2ButtonLabel={"Update"}
        handelAction1={() => setConfirmationDialog(false)}
        handelAction2={handleConfirmation}
        isLoading={isButtonLoading}
      >
        <div className="flex-col justify-center align-middle">
          <div
            style={{
              background: "rgba(37, 31, 52, 1)",
              width: 100,
              height: 100,
              borderRadius: 100,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              position: "absolute",
              top: 20,
              left: "40%",
            }}
          >
            <CalendarIcon color="white" style={{ width: 72, height: 72 }} />
          </div>
          <div className="pt-28">
            <h3 className="white text-align-center">Update Event</h3>
            <h5 className="white text-align-center">
              Are you sure you want to update this event's schedule?
            </h5>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default MyCalendar;
