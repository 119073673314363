export const questionData = [
  {
    id: 1,
    label: "What's your artist name?",
    name: "artistName",
    placeholder: "Please enter your artist name",
    validationMessages: {
      blank: "Please enter your artist name",
      startsWithSpace: "Cannot start with space",
    },
    tooltipText: "Who are you?",
  }
];
