import React from "react";

const Skeleton = ({
  variant = "rectangular",
  className = "",
  height,
  width,
}) => {
  const baseClasses = "animate-pulse bg-gray-700/50 rounded";

  const variants = {
    rectangular: "rounded-md",
    circular: "rounded-full",
    text: "rounded h-4 w-3/4",
  };

  return (
    <div
      className={`${baseClasses} ${variants[variant]} ${className}`}
      style={{ height, width }}
    />
  );
};

// Predefined skeleton layouts
export const ProjectCardSkeleton = () => (
  <div className="project-card animate-pulse">
    <div className="project-header">
      <div className="project-title">
        <div className="w-12 h-12 bg-gray-700/50 rounded-full" /> {/* Circle */}
        <div className="space-y-2">
          <Skeleton variant="text" className="w-32 h-6" /> {/* Title */}
          <Skeleton variant="text" className="w-24 h-4" /> {/* Subtitle */}
        </div>
      </div>
    </div>
  </div>
);

export const TaskCardSkeleton = () => (
  <div className="project-card animate-pulse p-4">
    <div className="flex justify-between items-center">
      <div className="space-y-2">
        <Skeleton variant="text" className="w-48 h-5" /> {/* Task title */}
        <Skeleton variant="text" className="w-32 h-4" /> {/* Project name */}
      </div>
      <Skeleton variant="rectangular" className="w-20 h-6" />{" "}
      {/* Priority badge */}
    </div>
  </div>
);

export const CalendarSkeleton = () => (
  <div className="project-card p-4 h-[600px]">
    <div className="space-y-4">
      <div className="flex justify-between items-center mb-4">
        <Skeleton variant="text" className="w-32 h-6" /> {/* Date */}
        <Skeleton variant="rectangular" className="w-24 h-8" /> {/* Button */}
      </div>
      {/* Calendar grid */}
      <div className="grid grid-cols-1 gap-2">
        {[...Array(8)].map((_, i) => (
          <div key={i} className="flex items-center gap-4">
            <Skeleton variant="text" className="w-16 h-4" /> {/* Time */}
            <Skeleton variant="rectangular" className="flex-1 h-12" />{" "}
            {/* Event block */}
          </div>
        ))}
      </div>
    </div>
  </div>
);

export const StatsSkeleton = () => (
  <div className="stats-grid">
    {[...Array(3)].map((_, i) => (
      <div key={i} className="stat-card">
        <Skeleton variant="text" className="w-20 h-4 mb-2" /> {/* Label */}
        <Skeleton variant="text" className="w-16 h-6" /> {/* Value */}
      </div>
    ))}
  </div>
);

export default Skeleton;
