import "../Signup/Signup.css";
import React, { useCallback, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import loginImage from "../../../assets/png/login.png";
import { Logo } from "../../../assets/svg/Logo";
import "react-image-crop/dist/ReactCrop.css";
import ReactCrop from "react-image-crop";
import Button from "../../../components/Button";
import { APP_ROUTES } from "../../../utils/app-routes";
import { API_END_POINTS } from "../../../utils/api-endpoint";
import { useMutationCreate } from "../../../hooks/crud/mutation.create";
import Dialog from "../../../../src/components/Dialog/Dialog";
import Cropper from "react-easy-crop";
import { Box, Modal } from "@mui/material";
import getCroppedImg from "./getCroppedImage";

const UploadProfileImage = () => {
  const navigate = useNavigate();
  const imgRef = useRef(null);
  const fileInputRef = useRef(); // Create a ref to the file input
  const [dragging, setDragging] = useState(false);
  const [preview, setPreview] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 }); // Crop state
  const [completedCrop, setCompletedCrop] = useState(null); // Completed crop state
  const [selectedFile, setSelectedFile] = useState(null);
  const [croppedArea, setCroppedArea] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const { mutate: uploadProfileImage } = useMutationCreate(
    API_END_POINTS.uploadProfileImage,
    { "Content-Type": "multipart/form-data" }
  );

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragIn = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragOut = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);

    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      const file = e.dataTransfer.files[0];
      if (!validateFile(file)) {
        return;
      }
      const url = URL.createObjectURL(file);
      setPreview(url);
      setSelectedFile(file);
      setIsModalOpen(true);
      e.dataTransfer.clearData();
    }
  };

  //   const onImageLoaded = (image) => {
  //     return image;
  //   };

  const onImageLoaded = useCallback((img) => {
    imgRef.current = img;
  }, []);

  const onCropChange = (crop) => {
    setCrop(crop);
  };

  const handleFileSelect = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      const url = URL.createObjectURL(file);
      setPreview(url);
      setSelectedFile(file);
    }
  };

  const handleContainerClick = () => {
    // Programmatically click the file input when the container is clicked
    fileInputRef.current.click();
  };

  const handleSubmit = () => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append("profile-image", selectedFile);

    uploadProfileImage(formData, {
      onSuccess(res) {
        setIsLoading(false);
        navigate(APP_ROUTES.onboardingQuestion);
      },
      onError: (err) => {
        setIsLoading(false);
      },
    });
  };

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedArea(croppedAreaPixels);
  }, []);

  const validateFile = (file) => {
    // Check file size
    const maxSize = 25 * 1024 * 1024; // 25MB in bytes
    if (file.size > maxSize) {
      setError("File size must be less than 25MB");
      return false;
    }

    // Check file type
    if (!file.type.startsWith("image/")) {
      setError("Please select an image file");
      return false;
    }

    setError("");
    return true;
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (!validateFile(file)) {
        return;
      }
      const reader = new FileReader();
      reader.onload = () => {
        setPreview(reader.result);
        setIsModalOpen(true);
      };
      reader.readAsDataURL(file);
      setSelectedFile(file);
    }
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setPreview(null);
  };

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(preview, croppedArea, rotation);
      setCroppedImage(croppedImage);
      handleModalClose();
    } catch (e) {
      console.error(e);
    }
  }, [preview, croppedArea, rotation]);

  return (
    <>
      <div className="w-full flex flex-col md:flex-row">
        {/* Image section - hide on mobile */}
        <div className="hidden md:block md:w-1/2 login-banner">
          <img
            className="w-full h-screen object-cover"
            src={loginImage}
            alt="Login"
          />
        </div>

        {/* Form section */}
        <div className="w-full md:w-1/2 box p-4 md:p-8">
          <div className="form-side max-w-md mx-auto">
            <div className="mb-8 md:mb-12 h-[3.125rem] w-[11.53rem]">
              <Logo />
            </div>
            <div>
              <span className="h4 text-2xl md:text-3xl block">
                Upload Profile Picture
              </span>
            </div>
            <div
              className="dotted-image-container mt-6 white"
              onClick={handleContainerClick}
              onDrop={handleDrop}
              onDragOver={handleDragIn}
              onDragEnter={handleDrag}
              onDragLeave={handleDragOut}
            >
              <p className="text-base md:text-lg">Drag files here to upload</p>
              <p className="text-sm md:text-base mt-2">
                Alternatively you can select file clicking here
              </p>
              <p className="text-xs md:text-sm mt-2 opacity-80">
                Maximum file size: 25MB
              </p>
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: "none" }}
                accept="image/*"
                onChange={handleFileChange}
              />
              {croppedImage && (
                <img
                  src={croppedImage}
                  alt="Preview"
                  style={{
                    position: "absolute", // Position the image absolutely
                    top: 0, // Align the image to the top of the container
                    left: 0, // Align the image to the left of the container
                    width: "100%", // Make the image fill the container
                    height: "100%", // Make the image fill the container
                    objectFit: "cover",
                  }}
                />
              )}
              {error && <p style={{ color: "red" }}>{error}</p>}
            </div>
            <div className="button-container mt-6 space-y-4">
              <Button
                variety="primary"
                onClick={() => handleSubmit()}
                disabled={isLoading}
                isLoading={isLoading}
                className="w-full text-sm md:text-base"
              >
                {isLoading ? "Uploading..." : "Submit"}
              </Button>
              <p
                className="text-center text-sm md:text-base green-400 cursor-pointer"
                onClick={() => navigate(APP_ROUTES.onboardingQuestion)}
              >
                Skip
              </p>
            </div>
          </div>
        </div>
      </div>
      <Modal
        open={isModalOpen}
        onClose={handleModalClose}
        // aria-labelledby="crop-image-modal"
        // aria-describedby="crop-image-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 500,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            height: 500,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <div className="modal-header">
            <h2>Crop Profile Picture</h2>
            <div className="crop-instructions">
              <p>
                Drag to reposition • Pinch or use slider to zoom • Click and
                drag corners to crop
              </p>
            </div>
          </div>
          <div style={{ position: "relative", height: "80%" }}>
            <Cropper
              image={preview}
              crop={crop}
              zoom={zoom}
              rotation={rotation}
              aspect={1}
              onCropChange={setCrop}
              onZoomChange={setZoom}
              onRotationChange={setRotation}
              onCropComplete={onCropComplete}
              minZoom={1}
              onMediaLoaded={(mediaSize) => {
                const initialZoom = Math.max(1, 300 / mediaSize.naturalHeight);
                setZoom(initialZoom);
              }}
            />
          </div>
          <div className="controls">
            <div className="zoom-control">
              <label>Zoom: </label>
              <input
                type="range"
                min={0.5}
                max={3}
                step={0.1}
                value={zoom}
                onChange={(e) => setZoom(parseFloat(e.target.value))}
              />
            </div>
            {/* ... rest of controls ... */}
          </div>
          <Button onClick={showCroppedImage}>Done</Button>
        </Box>
      </Modal>
    </>
  );
};
export default UploadProfileImage;
