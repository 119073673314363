import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Level1Icon } from "src/assets/svg/BadgeLevelIcon";
import useWindowSize from "src/hooks/ui/useWindowSize";
import { queryClient } from "../..";
import AvatarImage from "../../assets/png/avatar.png";
import { DownArrow } from "../../assets/svg/DownArrow";
import { useDataQuery } from "../../hooks/crud/data.query";
import usePageTitle from "../../hooks/pageTitleHook";
import { API_END_POINTS } from "../../utils/api-endpoint";
import { APP_ROUTES } from "../../utils/app-routes";
import {
  GENESIS_USER_EMAIL,
  GENESIS_USER_SIGNUP,
  GENESIS_USER_TOKEN,
  USER_DETAILS,
} from "../../utils/constants";
import Button from "../Button";
import "./Header.css";

const Header = (props) => {
  const title = usePageTitle();
  const { width } = useWindowSize();
  const { data: userData } = useDataQuery({
    url: API_END_POINTS.profile,
    params: {},
  });

  return (
    <div
      className={`header ${
        props.isSidebarOpen
          ? width > 1024
            ? "w-full"
            : width > 640
            ? "w-[90%]"
            : "calc-width"
          : "w-full"
      }`}
      {...props}
    >
      <div className="header-title white">
        <span className="hidden sm:inline">{title}</span>
      </div>
      <div className="header-actions-container">
        <div className="hidden sm:flex box-border flex-row items-center p-[6px_12px] gap-2 w-[116px] h-[44px] bg-[#201B2C] border border-[rgba(26,255,216,0.4)] shadow-[-24px_20px_80px_rgba(0,0,0,0.12)] rounded-lg flex-none order-0 flex-grow-0 text-base">
          <Level1Icon />
          <span className="w-[51px] h-[21px] font-ibm-plex-sans font-normal text-[16px] leading-[21px] flex items-center text-white flex-none order-1 flex-grow-0">
            Level 1
          </span>
        </div>
        <UserView userData={userData} />
      </div>
    </div>
  );
};

const UserView = ({ userData }) => {
  const [isProfileOpen, setIsProfileOpen] = useState(false);
  const { width } = useWindowSize();
  const userViewRef = useRef(null);

  useEffect(() => {
    // Add class to body when popup is open to prevent scrolling
    if (isProfileOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }

    return () => {
      document.body.style.overflow = "unset";
    };
  }, [isProfileOpen]);

  return (
    <div
      ref={userViewRef}
      className={`user-view-container ${isProfileOpen ? "active" : ""}`}
      onClick={() => {
        setIsProfileOpen(!isProfileOpen);
      }}
    >
      <div className="user-avatar">
        {userData?.data?.profileImageURL ? (
          <img
            src={userData?.data?.profileImageURL}
            alt="User Avatar"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              borderRadius: "50%",
              border: "2px solid var(--Rove-White, #FFF)",
            }}
          />
        ) : (
          <img
            src={AvatarImage}
            style={{
              width: width <= 640 ? "24px" : "30px",
              height: width <= 640 ? "24px" : "30px",
              objectFit: "cover",
              borderRadius: "50%",
              border: "2px solid var(--Rove-White, #FFF)",
            }}
          />
        )}
      </div>
      <span className="hidden sm:inline">{userData?.data?.fullName}</span>
      <DownArrow />
      {isProfileOpen && (
        <>
          <div className="fixed inset-0 bg-black/50 z-40" /> {/* Overlay */}
          <ProfilePopup
            width={width}
            userData={userData}
            onClose={() => {
              setIsProfileOpen(false);
            }}
          />
        </>
      )}
    </div>
  );
};

const ProfilePopup = ({ onClose, userData, width }) => {
  const popupRef = useRef(null);
  const navigate = useNavigate();
  let clickCount = 0;

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        if (clickCount !== 0) {
          onClose();
        }
        clickCount++;
      }
    };

    // Add click event listener to the document
    document.addEventListener("click", handleClickOutside);

    return () => {
      // Remove the event listener when the component unmounts
      document.removeEventListener("click", handleClickOutside);
    };
  });

  return (
    <div className="user-view-pop-up card-app-background" ref={popupRef}>
      <div className="user-avatar-2">
        {userData?.data?.profileImageURL ? (
          <img
            src={userData?.data?.profileImageURL}
            alt="User Avatar"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              borderRadius: "50%",
              border: "4px solid var(--Rove-Blue, #92D7E7)",
            }}
          />
        ) : (
          <img
            src={AvatarImage}
            style={{
              width: width >= 480 ? "80px" : "54px",
              height: width >= 480 ? "80px" : "54px",
              objectFit: "cover",
              borderRadius: width >= 480 ? "80px" : "54px",
              border: "2px solid var(--Rove-White, #FFF)",
            }}
          />
        )}
      </div>

      <div className="user-info">
        <h2 className="white text-center" style={{ lineHeight: "1.5rem" }}>
          {userData?.data?.fullName}
        </h2>

        <span className="regularL white mt-2 block text-center">
          {userData?.data?.emailAddress}
        </span>

        <span className="regularM white my-2 block text-center">
          Member since {moment(userData?.data?.createdAt).format("D MMM YYYY")}
        </span>

        <div className="divider"></div>

        <Button
          variety="filled"
          className="w-full mt-4"
          onClick={() => {
            localStorage.removeItem(USER_DETAILS);
            localStorage.removeItem(GENESIS_USER_EMAIL);
            localStorage.removeItem(GENESIS_USER_SIGNUP);
            localStorage.removeItem(GENESIS_USER_TOKEN);
            queryClient.removeQueries();
            navigate(APP_ROUTES.login);
          }}
        >
          <div className="flex items-center justify-center gap-2">
            <svg
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.4093 16.2855C13.7828 16.2855 13.2896 16.792 13.2896 17.4052V20.1511C13.2896 21.0308 12.5832 21.7373 11.7034 21.7373H3.83893C2.95918 21.7373 2.25271 21.0308 2.25271 20.1511V3.84893C2.25271 2.96917 2.95918 2.2627 3.83893 2.2627H11.7034C12.5832 2.2627 13.2896 2.96917 13.2896 3.84893V6.2216C13.2896 6.8481 13.7962 7.34129 14.4093 7.34129C15.0225 7.34129 15.529 6.83477 15.529 6.2216V3.84893C15.529 1.72952 13.8095 0.00999451 11.6901 0.00999451H3.83893C1.71952 0.00999451 0 1.72952 0 3.84893V20.1511C0 22.2705 1.71952 23.99 3.83893 23.99H11.7034C13.8228 23.99 15.5423 22.2705 15.5423 20.1511V17.4052C15.5423 16.7787 15.0358 16.2855 14.4227 16.2855H14.4093Z"
                fill="#2B3138"
              />
              <path
                d="M23.6601 11.1802L19.6612 7.18134C19.2213 6.7548 18.5282 6.74147 18.0883 7.16801C17.6351 7.60789 17.6484 8.34102 18.0883 8.79423L20.1677 10.8737H10.4238C9.79727 10.8737 9.30408 11.3802 9.30408 11.9933C9.30408 12.6065 9.8106 13.113 10.4238 13.113H20.1677L18.0616 15.2191C17.6218 15.659 17.6218 16.3655 18.0616 16.8053C18.5015 17.2452 19.208 17.2452 19.6479 16.8053L23.6601 12.8064C24.1133 12.3532 24.1133 11.6201 23.6601 11.1669V11.1802Z"
                fill="#2B3138"
              />
            </svg>
            <span className="regularL">Log out</span>
          </div>
        </Button>
      </div>
    </div>
  );
};

export default Header;
